import i18n from 'i18n/i18n';
let calendarMessages = {
  allDay: window.gettext('Todo o dia'),
  previous: window.gettext('Anterior'),
  next: window.gettext('Próximo'),
  today: window.gettext('Hoje'),
  month: window.gettext('Mês'),
  week: window.gettext('Semana'),
  day: window.gettext('Dia'),
  agenda: window.gettext('Agenda'),
  date: window.gettext('Data'),
  time: window.gettext('Hora'),
  event: window.gettext('Evento'),
  noEventsInRange: window.gettext('Não há compromissos neste intervalo'),
};

export {calendarMessages};


let momentFormat = {
  DATE_PTBR: 'DD/MM/YYYY',
  HOUR: 'HH:mm',
  DATE_EN: 'MM/DD/YYYY'
}
export default momentFormat;
