import React from "react";

// reactstrap components
import {Button, Modal, ModalBody, ModalFooter, ModalHeader,} from "reactstrap";
import withNotify from "../../../lib/NotificationWrapper";
import {getUserData} from "../../../lib/Auth";
import apiRequest from "../../../lib/Api";
import moment from "moment";
import momentFormat from "../../../lib/Locale";
import ExtendedDataFields from "../../../components/ExtendedData/ExtendedDataFields";
import {NotificationService} from '../../../services/NotificatioService'

class TasksAnswer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: getUserData(),
      task: null,
      modal: false,
      extended_data: null,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.task === null && this.props.task !== null) {
      this.setState({modal: true})
    }
  }

  get answers() {

    if (!this.props.disabledForm) {
      return null;
    }
    let answers = {values: {}};
    this.props.task.data.sections[0].fields.map(field => {

      answers.values[field.property] = {
        field: field.uuid,
        label: field.name,
        type: field.type,
        values: field.answer.value
      };
    });
    return answers;
  }

  toggle() {
    this.setState({modal: !this.modal});
  }

  async save() {
    if (this.state.extended_data.values === null) {
      this.props.notify({type: 'danger', message: window.gettext('Informe as respostas para as perguntas.')});
      return;
    }
    let form = this.props.task.extended_data;
    let fields = [];
    form.extended_data.sections[0].fields.map(field => {
      fields.push({...field, 'answer': this.state.extended_data.values[field.property]});
    });
    form.extended_data.sections[0].fields = fields;

    await apiRequest(
        `/tasks/${this.props.task.uuid}`,
        {
          method: 'PUT',
          data: {
            form: form.extended_data
          }
        }
    ).then(success => {
      this.props.notify({type: 'success', message: window.gettext('Respostas salvas com sucesso.')});
      this.setState({task: null, modal: false});
      this.props.onSave();
      NotificationService.updateNotifications();
    }).catch(error => {
      this.props.notify({type: 'danger', message: window.gettext('Não foi possível salvar as suas respostas. Verifique se todas as perguntas foram respondidas.')});
    });
  }

  setExtendedData = (data) => {
    this.setState({extended_data: data});
  }

  cancel() {
    this.setState({task: null, modal: false});
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  renderSaveButton() {
    if (this.props.disabledForm) {
      return <></>;
    }

    return (
        <>
          <Button
              color='primary'
              onClick={() => {
                return this.save();
              }}>
            {window.gettext('Salvar')}
          </Button>{'  '}
        </>
    );
  }

  render() {

    if (!this.props.task) {
      return <></>;
    }

    return (
        <>
          <Modal
              size='lg'
              isOpen={this.state.modal}
              toggle={this.toggle}
          >
            <ModalHeader >
              {this.props.task.extended_data.name}<br />
              {moment(this.props.task.created_at).format(`${momentFormat.DATE_PTBR} ${momentFormat.HOUR}`)}
            </ModalHeader>
            <ModalBody>
              <ExtendedDataFields oneColumn={true}
                                  extended_data_id={this.props.task.extended_data.id}
                                  fillFields={this.setExtendedData}
                                  data={this.answers}
                                  isDisabled={this.props.disabledForm || false}
              />
            </ModalBody>
            <ModalFooter>
              {this.renderSaveButton()}
              <Button onClick={() => {
                this.cancel();
              }}>
                {window.gettext('Cancelar')}
              </Button>
            </ModalFooter>
          </Modal>
        </>
    );
  }
}

export default withNotify(TasksAnswer);
