import React from "react";
import AsyncSelect from "react-select/lib/Async";
import {withCookies} from "react-cookie";

// reactstrap components
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Row} from "reactstrap";

import {getUserData} from "lib/Auth";
import apiRequest from "lib/Api";
import withNotify from "lib/NotificationWrapper";
import FileIndex from "../Files/FileIndex";

class FileManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            userData: getUserData(),
            selectedUser: null
        };
    }

    componentDidMount() {
        this.resetUser();

        // makes a request to make sure we have a valid auth token to request iframe
        apiRequest("/auth/me").then(() => this.setState({loggedIn: true}));
    }

    loadUsers = searchTerm => {
        let params = {
            searchRole: 'student',
            search: searchTerm,
            onlyAppointments: 1
        };
        return apiRequest("/users", {data: params, method: 'GET'})
            .then(response => {
                return response.data.data.map(user => ({value: user.id, label: user.name}));
            });
    }

    changeUser = value => {
        this.setState({selectedUser: value}, () => {
        });
    }

    resetUser = () => {
        this.setState({selectedUser: null});
    }

    get role() {
        return this.state.userData.role;
    }

    get default_role() {
        if (!this.state.userData.default_role) {
            return null;
        }
        return this.state.userData.default_role;
    }

    renderFile() {
        console.log('user id ')
        if (this.role === 'student') {
            let userId = this.state.userData.id;
            return <FileIndex key={userId} user_id={userId}/>
        }
        if (!this.state.selectedUser) {
            return (<><b>{window.gettext('Selecione um Usuário!')}</b></>)
        }
        let userId = this.state.selectedUser.value;
        return (
                <FileIndex key={userId} user_id={userId}/>
        );
    }
    renderSharedFile() {
        if (this.role !== 'super_admin') {
            return <></>
        }

        return (
            <>
            <FileIndex shared="true" label={window.gettext("Arquivos compartilhados")}/>
            </>
        );
    }

    render() {
        if (!this.state.loggedIn) {
            return <div className="content"></div>;
        }
        let userId =  this.state.selectedUser ? this.state.selectedUser.value : this.state.userData.id;
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{window.gettext('Biblioteca')}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {(this.role === 'super_admin' || this.role === 'specialist' || this.default_role === 'admin')
                                    && this.renderSelect()
                                    }
                                    <FileIndex key={userId} user_id={userId} shared={true}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    renderSelect() {
        return (
            <Row>
                <Col md="8">
                    <Row>
                        <Form className="form-calendar-header">
                            <FormGroup>
                                <AsyncSelect
                                    className="react-select primary m-3"
                                    classNamePrefix="react-select"
                                    name="specialist_id"
                                    value={this.state.selectedUser}
                                    onChange={this.changeUser}
                                    defaultOptions
                                    loadOptions={this.loadUsers}
                                    placeholder={window.gettext("Selecione um usuário se quiser gerenciar seus arquivos")}
                                />
                            </FormGroup>
                        </Form>
                    </Row>
                </Col>
                <Col md="4">
                    <Button color="primary" onClick={this.resetUser}
                    >{window.gettext('Meus documentos')}
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default withNotify(withCookies(FileManager));
