 import React from "react";
import {Redirect} from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";

import apiRequest from "lib/Api";
import withNotify from "lib/NotificationWrapper";
import InputError from "views/components/InputError";
import enviroment from "../../services/enviroment";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      working: false,
      errors: {},
      background: {
        backgroundImage: `url(${require("assets/img/bg/pattern5.png")})`
      }
    }
  }

  componentDidMount() {
    document.body.classList.toggle("login-page");
    if (enviroment.isNewUserInterface()) {
      this.setState({
        background: {backgroundColor: "#1E2B4E"}
      });
    }
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  handleChange = (e) => {
    let {name, value} = e.target;
    this.setState({[name]: value});
  }

  clearForm() {
    this.setState({
      email: '',
      errors: {},
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let {email} = this.state;
    let data = {email};

    this.setState({working: true});

    apiRequest("/auth/forgot-password", {data})
      .then(response => {
        this.props.notify({
          type: 'success',
          message: window.gettext('As instruções para troca de senha foram enviados para seu e-mail. Verifique sua caixa postal.')
        });
        this.clearForm();
      })
      .catch(err => {
        if (err && err.data) {
          this.setState({errors: err.data.errors || {email: window.gettext('E-mail inválido')}});
        }
      })
      .finally(
        response => this.setState({working: false})
      );
  }

  render() {
    if (this.state.loggedIn) {
      return <Redirect to="/admin/dashboard" />;
    }

    let hasError = InputError.convertToHasClass(this.state.errors);

    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">
                        {window.gettext('Esqueci a senha')}</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <FormGroup className={hasError.email}>
                      <InputGroup style={{marginBottom:0}}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email..." type="text"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputError name="email" errors={this.state.errors} />
                    </FormGroup>
                    <br />
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="warning"
                      disabled={this.state.working}
                    >
                      {window.gettext('Solicitar nova senha')}
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={ this.state.background }
        />
      </div>
    );
  }
}

export default withNotify(ForgotPassword);
