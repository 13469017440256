import React from "react";

// reactstrap components
import {Button} from "reactstrap";

import apiRequest from "../../lib/Api";
import withNotify from "../../lib/NotificationWrapper";
import FileUpload from "../../components/CustomUpload/FileUpload";
import Sidebar from "./Sidebar";

class TranslationPanel extends React.Component {
    constructor(props) {
        super(props);
        let label = '';
        switch (props.type) {
            case 'product':
                label = window.gettext('produto');
                break;
            case 'email':
                label = window.gettext('emails');
                break;
            case 'user':
                label = window.gettext('usuários');
                break;

        }
        this.state = {
            isSiderbarTranslation: false,
            translateFile: null,
            type: props.type || '',
            code: props.code || '',
            label: label
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.code !== this.props.code ) {
            this.setState({code: this.props.code})
        }
    }

    handleChangeTranslateFile = file => {
        this.setState({translateFile: file});
    }
    openTranslations = () => {
        this.setState({isSiderbarTranslation: true})
    }
    closeTranslations = () => {
        this.setState({isSiderbarTranslation: false})
    }
    downloadTranslateFile = () => {

        const fileName = `translation_${this.props.type}_${this.state.code}.csv`;
        let url = `/translation/${this.state.type}/${this.state.code}`;

        apiRequest(url, {method: "GET"}, {blob: true})
            .then(response => {
                var file = window.URL.createObjectURL(response);
                var a = document.createElement('a');
                a.href = file;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    saveTranslation = () => {
        if (!this.state.translateFile) {
            this.props.notify({type: "error", message: window.gettext("falha ao fazer upload do arquivo")});
            return;
        }
        let data = {file: this.state.translateFile};

        let formData = new FormData()
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
        }
        let url = `/translation/${this.state.type}/${this.state.code}`;
        apiRequest(url, {data: formData, method: 'POST'}).then(response => {
            this.setState({translateFile: null});
            this.props.notify({type: 'success', message: window.gettext('Arquivo enviado com sucesso.')});
        }).catch(error => {
            console.error(error)
            this.props.notify({type: 'error', message: window.gettext('Não foi possível enviar o arquivo.')});
        })
    }

    render() {
        return (
            <>
                <Button color="" type="button"
                        disabled={this.state.working}
                        onClick={this.openTranslations}>
                    <i className="fa fa-language"/>
                    {window.gettext('Traduções')}
                </Button>
                <Sidebar active={this.state.isSiderbarTranslation} title={"Traduções"}
                         onClose={this.closeTranslations}>
                    <h4>{window.gettext('Baixar arquivo de tradução do %1:', this.state.label)}</h4>
                    <Button color={'primary'} onClick={this.downloadTranslateFile}>
                        <i className="fa fa-download"/> {' '}
                        {window.gettext('Baixar arquivo')}
                    </Button>
                    <hr/>
                    <h4>{window.gettext('Atualizar tradução do %1:', this.state.label)}</h4>
                    <FileUpload onChangeImage={this.handleChangeTranslateFile}
                                originalUrl=''
                    />
                    <div style={{
                        position: 'fixed',
                        bottom: '1px', borderTop: '1px solid gray',
                        width: '99%',
                    }}>
                        <Button color="primary" type="button"
                                disabled={this.state.working}
                                onClick={this.saveTranslation}
                        >
                            <i className="fa fa-save"/>
                            {window.gettext('Salvar')}
                        </Button>
                    </div>
                </Sidebar>
            </>)
    }
}

export default withNotify(TranslationPanel);
