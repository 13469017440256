import React from "react";

// reactstrap components
import {Card, CardBody, CardHeader, CardTitle, Col, Row,} from "reactstrap";

import Dropzone from "components/Dropzone/Dropzone";

import apiRequest from "lib/Api";
import withNotify from "lib/NotificationWrapper";

class UploadCv extends React.Component {
    constructor(props) {
        super(props);
    }

    saveFile = files => {
        let formData = new FormData();
        formData.append('curriculum', files[0]);

        apiRequest("/utils/upload-cv", {data: formData, method: 'PUT'})
            .then(res => {
                this.props.notify({type: 'success', message: window.gettext('Arquivo armazenado!')});
            })
            .catch(err => {
                console.error(err);
                this.props.notify(
                    {
                        type: 'error',
                        message: window.gettext('Não foi possível armazenar o arquivo. Tente mais tarde.')
                    }
                );
            });
    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        {window.gettext('Upload do Currículo')}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col className="dropzone-container">
                                            <Dropzone onFilesAdded={this.saveFile}/>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(UploadCv);
