import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table
} from "reactstrap";
import withNotify from "../../../lib/NotificationWrapper";
import {getUserData} from "../../../lib/Auth";
import apiRequest from "../../../lib/Api";
import moment from "moment";
import momentFormat from "../../../lib/Locale";
import TasksAnswer from "./TasksAnswer";
import Select from "react-select";

class UsersTasks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: getUserData(),
            tasks: [],
            modal: false,
            taskSelected: null,
            forms: [],
            selectedForm: {value: ''},
            selectedUsers: [],
            userName: '',
            exportInterval: 30,
        }
    }

    componentDidMount() {
        this.getForms();
        this.fetchTasks();
    }

    fetchTasks() {

        let data = {
            method: 'GET',
            data: {
                user_name: this.state.userName,
                form: this.state.selectedForm.value || '',
                days: this.state.exportInterval,
            }
        }
        apiRequest('/tasks', data).then(
            success => {
                this.setState({tasks: success.data.tasks});
            }
        ).catch(error => {

        });
    }

    handleFormChange = (field, data) => {
        this.setState({selectedForm: data});
    }

    async getForms() {
        let response = await apiRequest("/forms", {method: "GET"})

        let {data} = response.data;
        let forms = [];
        let formsList = [];

        for (let form of data) {
            forms.push(
                {
                    value: form.id,
                    label: form.name
                }
            )
            formsList.push(form);
        }

        this.setState({'forms': forms, formsList: formsList});

    }

    openModal(task) {
        this.setState({taskSelected: task});
    }

    renderSearchForm() {
        return (
            <>
                <Card>
                    <CardBody>
                        <Form className="form-horizontal">
                            <Row>
                                <Label md="1">
                                    {window.gettext('Usuário')}</Label>
                                <Col md="3">
                                    <FormGroup>
                                        <Input placeholder="Nome ou e-mail" type="text"
                                               onChange={this.filterChange} value={this.state.userName}
                                               onKeyPress={event => event.which === 13 && event.preventDefault()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Label sm="1">
                                    {window.gettext('Formulário')}
                                </Label>
                                <Col sm="2">
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="currentService"
                                            value={this.state.currentForm}
                                            onChange={value =>
                                                this.handleFormChange("currentForm", value)
                                            }
                                            options={this.state.forms}
                                            placeholder={window.gettext("Selecione um formulário")}
                                        />
                                    </FormGroup>
                                </Col>
                                <Label md="1">
                                    {window.gettext('Dias')}
                                </Label>
                                <Col md="1 ">
                                    <FormGroup>
                                        <Input type="number" value={this.state.exportInterval}
                                               onChange={this.changeInterval}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="1">
                                    <Button color="info" type="button" style={{margin: '1px -15px'}}
                                            onClick={this.executeFilter}
                                    >
                                        <i className="fa fa-search"/>
                                    </Button>
                                </Col>

                                <Col md="1">
                                    <Button color="default" type="button" style={{margin: 0}}
                                            onClick={this.export}
                                    >
                                        {window.gettext('Exportar')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </>
        )
    }

    filterChange = (event) => {
        let value = event.target.value;
        this.setState({userName: value});
    }

    executeFilter = (event) => {
        event.preventDefault();
        this.fetchTasks();
    }

    renderTaskRow(task, index) {
        if (task.done === false) {
            return (<></>);
        }

        const date = moment(task.updated_at).format(`${momentFormat.DATE_PTBR} ${momentFormat.HOUR}`);

        // console.log(task);
        let meeting = task.purchase_service.productService.product.name + " - " + task.purchase_service.full_name;

        return (
            <tr key={index}>
                <td>{task.user.name}</td>
                <td>{date}</td>
                <td>{meeting}</td>
                <td className='text-center'>
                    <Button
                        className="btn-icon"
                        color="info"
                        size="sm"
                        type="button"
                        onClick={() => this.openModal(task)}
                    >
                        <i className="nc-icon nc-zoom-split"/>
                    </Button>
                </td>
            </tr>
        );
    }

    export = () => {
        let data = {
            user_name: this.state.userName,
            form: this.state.selectedForm.value || '',
            days: this.state.exportInterval,
        };

        apiRequest("/tasks/export/cvs", {method: "GET", data}, {blob: true})
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'tasks.csv');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
    }

    render() {

        return (
            <>
                <TasksAnswer
                    task={this.state.taskSelected}
                    disabledForm={true}
                    onClose={() => {
                        this.setState({taskSelected: null})
                    }}
                />
                <div className="content">
                    {this.renderSearchForm()}
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{window.gettext('Tarefas Executadas')}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Table responsive>
                                        <thead className="text-primary">
                                        <tr>
                                            <th>{window.gettext('Nome')}</th>
                                            <th>{window.gettext('Respondido em')}</th>
                                            <th>{window.gettext('Encontro')}</th>
                                            <th>{window.gettext('Ações')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.tasks.map((task, index) => {
                                            return this.renderTaskRow(task, index)
                                        })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(UsersTasks);
