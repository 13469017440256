import React from "react";
import {Redirect} from "react-router-dom";

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { LinkedIn } from 'react-linkedin-login-oauth2';

import {Button} from "reactstrap";

import apiRequest from "../../lib/Api";
import {saveAuth} from "../../lib/Auth";

const FACEBOOK_APP_ID = '2393705020910291';
const LINKEDIN_CLIENT_ID = '78r2mzl78voa46';
const LINKEDIN_REDIRECT_URI = process.env.REACT_APP_HOST + "/linkedin";

class SocialLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false
    };
  }

  responseFacebook = response => {
    if (!response.accessToken) {
      return;
    }

    let data = {
      access_token: response.accessToken,
      name: response.name,
      email: response.email,
      picture_url: response.picture.data.url,
      fb_id: response.userID,
      expires_in: response.expiresIn
    };

    apiRequest("/auth/facebook", {data})
      .then(response => {
        saveAuth(response.data);
        this.setState({loggedIn: true});
      })
      .catch(err => {
        console.error(err);
      });
  }

  handleSuccessLinkedin = response => {
    if (!response.code) {
      return;
    }

    let data = {
      code: response.code,
      redirect_uri: LINKEDIN_REDIRECT_URI
    };

    apiRequest("/auth/linkedin", {data})
      .then(response => {
        saveAuth(response.data);
        this.setState({loggedIn: true});
      })
      .catch(err => {
        console.error(err);
      });
  }

  handleFailureLinkedin = error => {
    alert(window.gettext("Não foi possível se conectar s sua conta LinkedIn"));
    console.error('error', error);
  }

  render() {
    if (this.state.loggedIn) {
      return <Redirect to="/admin/dashboard" />;
    }

    return (
      <React.Fragment>
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          callback={this.responseFacebook}
          fields="name,email,picture.type(large)"
          scope="public_profile,email"
          render={renderProps => (
            <Button className="btn-icon btn-round" color="facebook"
              onClick={renderProps.onClick}
            >
              <i className="fa fa-facebook-f" />
            </Button>
          )}
        />

        <LinkedIn
          clientId={LINKEDIN_CLIENT_ID}
          scope="r_liteprofile r_emailaddress"
          onFailure={this.handleFailureLinkedin}
          onSuccess={this.handleSuccessLinkedin}
          redirectUri={LINKEDIN_REDIRECT_URI}
          className="btn-icon btn-round btn btn-linkedin"
        >
          <i className="fa fa-linkedin" />
        </LinkedIn>
      </React.Fragment>
    )
  }
}

export default SocialLogin;
