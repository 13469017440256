import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col, Modal,
  ModalBody, ModalFooter,
  ModalHeader,
  Row,
  Table
} from "reactstrap";
import withNotify from "../../../lib/NotificationWrapper";
import {getUserData} from "../../../lib/Auth";
import apiRequest from "../../../lib/Api";
import moment from "moment";
import momentFormat from "../../../lib/Locale";
import TasksAnswer from "./TasksAnswer";

class MyTasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: getUserData(),
      tasks: [],
      modal: false,
      taskSelected: null
    }
  }

  componentDidMount() {
    this.loadTasks();
  }

  loadTasks() {
    this.setState({taskSelected: null});
    const req = apiRequest('/tasks/my-tasks', {method: 'GET'}).then(
        response => {
          this.setState({'tasks': response.data.tasks});
        }
    );
  }

  openModal(task) {
    this.setState({taskSelected: task});
  }

  renderButton(task) {
    if (task.done === true) {
      return <></>;
    }

    return (
        <Button
            className="btn-icon"
            color="info"
            id={task.uuid}
            size="sm"
            type="button"
            onClick={() => this.openModal(task)}
        >
          <i className="nc-icon nc-single-copy-04" />
        </Button>
    );
  }

  renderTaskRow(task, done) {
    if (task.done !== done) {
      return (<></>);
    }

    const data = moment(task.created_at).format(`${momentFormat.DATE_PTBR} ${momentFormat.HOUR}`);

    return (
        <tr key={task.uuid}>
          <td>{task.done === false ? window.gettext('Em aberto') : window.gettext('Fechado') }</td>
          <td>{data}</td>
          <td>{task.extended_data.name}</td>
          <td className='text-center'>
            {this.renderButton(task)}
          </td>
        </tr>
    );
  }

  renderHeaderRow() {
    return (
        <tr>
          <th>{window.gettext('Situação')}</th>
          <th>{window.gettext('Data de Inclusão')}</th>
          <th>{window.gettext('Título')}</th>
          <th className="text-center">{window.gettext('Ações')}</th>
        </tr>
    );
  }

  toggle() {
    this.setState({modal: !this.modal});
  }

  render() {

    return (
        <>
          <TasksAnswer
              task={this.state.taskSelected}
              disabledForm={false}
              onClose={() => {this.setState({taskSelected: null}) }}
              onSave={() => {this.loadTasks()} }
          />

          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{window.gettext('Minhas tarefas em aberto')}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table responsive>
                      <thead className="text-primary">
                      {this.renderHeaderRow()}
                      </thead>
                      <tbody>
                      {this.state.tasks.map(task => {
                        return this.renderTaskRow(task, false)
                      })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{window.gettext('Minhas tarefas concluídas')}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table responsive>
                      <thead className="text-primary">
                      {this.renderHeaderRow()}
                      </thead>
                      <tbody>
                      {this.state.tasks.map(task => {
                        return this.renderTaskRow(task, true)
                      })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    );
  }
}

export default withNotify(MyTasks);
