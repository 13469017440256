import React from 'react'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Input,
  FormGroup,
  Form,
  Row,
  Col
} from 'reactstrap'

import apiRequest from '../../../lib/Api'
import withNotify from '../../../lib/NotificationWrapper'
import InputError from '../../components/InputError'
import { updateUserData } from '../../../lib/Auth'
import { Redirect } from 'react-router-dom'

class ChangePasswordForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      old_password: '',
      new_password: '',
      new_password_confirmation: '',
      working: false,
      redirect: false,
      errors: {}
    }
  }

  clearForm () {
    this.setState({
      old_password: '',
      new_password: '',
      new_password_confirmation: '',
      errors: {}
    })
  }

  handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value

    this.setState({
      [name]: value
    })

    let newErrors = { ...this.state.errors, [name]: '' }
    this.setState({ errors: newErrors })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    let { old_password, new_password, new_password_confirmation } = this.state
    let data = {
      old_password,
      new_password,
      new_password_confirmation
    }

    this.setState({ working: true })

    let isOnboarding = false
    if (this.props.location.pathname.indexOf('setup') > 0) {
      isOnboarding = true
    }
    let request = apiRequest(`/auth/change-password?onboarding=${isOnboarding}`, { data, method: 'POST' })

    request.then(response => {
      this.clearForm()
      this.props.notify({ type: 'success', message: window.gettext('Senha alterada com sucesso') })
      updateUserData('has_onboarding', true)
      setTimeout(() => {
        if (isOnboarding) {
          this.setState({ redirect: true })
        }
      }, 400)
    })
      .catch(err => {
        this.setState({ errors: err.data.errors })
      })
      .finally(
        response => this.setState({ working: false })
      )
  }

  render () {

    let hasError = InputError.convertToHasClass(this.state.errors)
    if (this.state.redirect) {
      return <Redirect to={'/admin/dashboard'}/>
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Form action="" className="form" method="POST"
                    onSubmit={this.handleSubmit}
              >
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{window.gettext('Alterar senha')}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Label sm="2">{window.gettext('Senha atual')}</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.old_password}>
                          <Input
                            value={this.state.old_password}
                            name="old_password"
                            onChange={this.handleChange}
                            type="password"
                          />
                          <InputError name="old_password" errors={this.state.errors}/>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">{window.gettext('Senha nova')}</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.new_password}>
                          <Input
                            value={this.state.new_password}
                            name="new_password"
                            onChange={this.handleChange}
                            type="password"
                          />
                          <InputError name="new_password" errors={this.state.errors}/>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">{window.gettext('Confirme senha nova')}</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.new_password_confirmation}>
                          <Input
                            value={this.state.new_password_confirmation}
                            name="new_password_confirmation"
                            onChange={this.handleChange}
                            type="password"
                          />
                          <InputError name="new-new_password_confirmation" errors={this.state.errors}/>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md="2"/>
                      <Col md="10">
                        <Button className="btn-round" color="info" type="submit"
                                disabled={this.state.working}
                        >
                          {window.gettext('Salvar')}
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default withNotify(ChangePasswordForm)
