import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import AsyncSelect from "react-select/lib/Async";

// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup, Modal, ModalBody, ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";

import {getUserData} from "lib/Auth";
import apiRequest from "lib/Api";
import withNotify from "lib/NotificationWrapper";
import PerformanceGrouResult from "./PerformanceGrouResult";
import NoAccess from "../NoAccess";
import {Redirect} from "react-router-dom";
import ConfirmModal from "../../../components/Modals/ConfirmModal";

class PerformanceGrou extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            answers: [],
            // answers: [...Array(41).keys()].map(n => Math.ceil(Math.random() * 6)),  // RANDOM
            step: 1,
            nextStepModal: '',
            instructionsModal: '',
            changed: false,
            userData: getUserData(),
            selectedUser: '',
            ancoraId: 0,
            is_answered: false,
            hasService: false,
            openConfirmationModal: false,
            showConfirmation: false,
        }
    }

    componentDidMount() {

        const userData = getUserData();
        if (userData.actual_role !== 'member') {
            return true
        }

        if (this.state.userData.actual_role !== 'admin') {
            this.loadData();
        }
        this.verifyService();

        if ( ! this.state.ancoraId || this.state.ancoraId === 0 || this.state.ancoraId === '0' ) {
             this.renderInstructions()
        }
    }

    async verifyService() {

        await apiRequest('/purchase-services', {method: 'GET'}).then(
            response => {
                response.data.data.map(infoService => {
                    if (infoService.service.id === 9) {
                        this.setState({hasService: true})
                    }
                })
            }
        )
    }

    get role() {
        return this.state.userData.role;
    }

    async loadData() {
        let params = {};
        if (this.state.selectedUser) {
            params.user_id = this.state.selectedUser.value;
        }

        return await apiRequest("/performance-grou", {method: 'GET', data: params})
            .then(response => {
                let [ancora] = response.data.data;
                if (ancora) {
                    let {answers} = ancora;
                    // add some stuff at the beginning, so that real questions start at index 1
                    answers.unshift(null);
                    let step = 1;
                    if (ancora.id) {
                        step = 10;
                    }

                    if (ancora.is_answered) {
                        this.setState({instructionsModal: ''})
                    }

                    this.setState({
                        answers: ancora.answers,
                        step: step,
                        ancoraId: ancora.id,
                        is_answered: ancora.is_answered
                    }, this.renderResultPerformanceGrou);

                } else {
                    this.reset();
                }
            });
    }

    loadUsers = searchTerm => {
        let params = {
            searchRole: 'student',
            search: searchTerm,
            onlyAppointments: 1
        };
        return apiRequest("/users", {data: params, method: 'GET'})
            .then(response => {
                return response.data.data.map(user => ({value: user.id, label: user.name}));
            });
    }

    changeUser = value => {
        this.setState({selectedUser: value}, () => this.loadData());
    }

    saveData() {
        let {answers, ancoraId} = this.state;
        answers.shift();

        this.setState({openConfirmationModal: false});

        let data = {answers};
        let request;
        if (ancoraId) {
            request = apiRequest("/performance-grou/" + ancoraId, {method: 'PUT', data});
        } else {
            request = apiRequest("/performance-grou", {method: 'POST', data});
        }

        return request.then(response => {
            this.props.notify(
                {
                    type: 'success',
                    message: window.gettext('Os dados desta avaliação foram salvos com sucesso. Aguarde.')
                }
            );
            this.setState({changed: false});
            window.location.href='/admin/performancegrou';
        });
    }

    reset = () => {
        this.setState({
            step: 1,
            answers: [],
            is_answered: false,
            ancoraId: 0,
        }, () => this.afterChange(1));
    }

    nextStep = () => {
        let {step} = this.state;

        if (this.beforeChange(step) === false) {
            return;
        }

        if (step === 11) {
            this.setState({openConfirmationModal: true});
            return;
        }

        step++;
        this.renderModal(step)
            .then(() => {
                this.setState({step}, () => this.afterChange(step));
            });
    }

    backStep = () => {
        let {step} = this.state;
        if (step === 1) {
            return;
        }
        step--;
        this.setState({step: step});
    }

    answerQuestion = (event, questionId, option) => {
        let answers = [...this.state.answers];
        answers[questionId] = option;
        event.target.blur();
        this.setState({answers, changed: true});
    }


    showMessage = (questionError) => {
        if (questionError) {
            let message = `A pergunta ${questionError} não foi respondida.`;
            this.props.notify({type: 'warning', message});
            return false;
        }
    }

    beforeChange(currentStep) {
        let {answers} = this.state;

        if (currentStep === 1) {
            for (let question = 1; question <= 5; question++) {
                if (!answers[question]) {
                    return this.showMessage(question);
                }
            }
        }

        if (currentStep === 2) {
            for (let question = 6; question <= 12; question++) {
                if (!answers[question]) {
                    return this.showMessage(question);
                }
            }
        }

        if (currentStep === 3) {
            for (let question = 13; question <= 20; question++) {
                if (!answers[question]) {
                    return this.showMessage(question);
                }
            }
        }
        if (currentStep === 4) {
            for (let question = 21; question <= 27; question++) {
                if (!answers[question]) {
                    return this.showMessage(question);
                }
            }
        }

        if (currentStep === 5) {
            for (let question = 28; question <= 32; question++) {
                if (!answers[question]) {
                    return this.showMessage(question);
                }
            }
        }

        if (currentStep === 6) {
            for (let question = 33; question <= 38; question++) {
                if (!answers[question]) {
                    return this.showMessage(question);
                }
            }
        }

        if (currentStep === 7) {
            for (let question = 39; question <= 44; question++) {
                if (!answers[question]) {
                    return this.showMessage(question);
                }
            }
        }

        if (currentStep === 8) {
            for (let question = 45; question <= 53; question++) {
                if (!answers[question]) {
                    return this.showMessage(question);
                }
            }
        }

        if (currentStep === 9) {
            for (let question = 54; question <= 59; question++) {
                if (!answers[question]) {
                    return this.showMessage(question);
                }
            }
        }

        if (currentStep === 10) {
            for (let question = 60; question <= 63; question++) {
                if (!answers[question]) {
                    return this.showMessage(question);
                }
            }
        }

        if (currentStep === 11) {
            for (let question = 64; question <= 69; question++) {
                if (!answers[question]) {
                    return this.showMessage(question);
                }
            }
        }
        return true;
    }

    afterChange(currentStep) {
        window.scrollTo(0, 0);
    }

    renderForm() {

        if (this.state.is_answered === true) {
            return <></>;
        }

        return (
            <>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h3">
                                    {window.gettext('Lumi')}
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {(this.role === 'super_admin' || this.role === 'specialist' || this.state.userData.actual_role === 'admin')
                                    && this.renderSelect()
                                }
                                {this.state.selectedUser && !!this.state.ancoraId && this.renderExercise()}
                                {this.state.selectedUser && !this.state.ancoraId &&
                                    <p>
                                        {window.gettext('Nenhum resultado foi encontrado para este usuário')}
                                    </p>
                                }
                                {this.renderExercise()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }


    render() {

        if (this.state.hasService === false
            && this.state.userData.role !== 'super_admin'
            && this.state.userData.role !== 'specialist'
            && this.state.userData.actual_role !== 'admin') {

            let title = window.gettext("Serviço não liberado");
            let description = window.gettext("Este serviço não está liberado para o seu usuário. Contate o administrador.");
            return (
                <>
                    <NoAccess title={title} description={description} />
                </>
            );
        }

        return (
            <>
                {this.state.nextStepModal}
                {this.state.instructionsModal}
                <div className="content ancora-container">
                    {this.renderForm()}
                    {this.renderResultPerformanceGrou()}


                <ConfirmModal message={window.gettext("Ao gerar o documento com o resultado do Lumi, o mesmo ficará disponível na biblioteca do usuário. Deseja continuar? ")}
                              onConfirm={() => this.generateDocument()}
                              onToggle={() => this.toggleConfirmation(false)}
                              open={this.state.showConfirmation}
                />
                </div>
            </>
        )
    }

    toggleConfirmation = (show) =>  {
        this.setState({showConfirmation: show});
    }

    generateDocument = () => {

        if (!this.state.ancoraId && this.state.is_answered === false) {
            return false;
        }

        if ( ! this.state.showConfirmation ) {
            this.setState({showConfirmation: true});
            return false;
        }

        apiRequest(`/performance-grou/${this.state.ancoraId}/document`, {method: 'GET'})
            .then(
                () => {
                    this.props.notify(
                        {
                            type: 'success',
                            message: window.gettext('O documento foi gerado e está disponível na sua biblioteca.')
                        }
                    );
                }
            );
    }

    checkIfCanSeeResults() {

        if (this.state.userData.role === 'super_admin') {
            return true;
        }

        if (this.state.userData.actual_role === 'admin') {
            return true;
        }

        if (this.state.userData.role === 'specialist') {
            return true;
        }
        return false;
    }

    renderButtonReturn() {
        if (this.checkIfCanSeeResults() === false) {
            return <></>
        }

        return <>
            <Button className="btn" color="secondary" type="button"
                    onClick={this.reset}
            >
                {window.gettext('Retornar')}
            </Button>
        </>
    }

    renderResultPerformanceGrou() {

        if (!this.state.ancoraId && this.state.is_answered === false) {
            return <></>;
        }

        let stop = false;
        if (this.checkIfCanSeeResults() === false) {
            let title = window.gettext("Aviso");
            let description = window.gettext("No momento não é possível ver o resultado.");
            stop = true;
            return (
                <>
                    <NoAccess title={title} description={description} />
                </>
            );
        }

        if (stop) {
            return (<></>);
        }

        return (
            <>
                <Row>
                    <Col className='text-right'>
                        {this.renderButtonReturn()}
                        <Button className="btn" color="primary" type="button"
                                onClick={this.generateDocument}
                        >
                            {window.gettext('Gerar Documento')}
                        </Button>
                    </Col>
                </Row>
                <PerformanceGrouResult performanceId={this.state.ancoraId}/>
            </>
        );
    }

    renderDescription(step) {
        return (
            <>
                <p><strong>{window.gettext('Descrição')}:</strong>&nbsp;
                    {STEPS[step - 1].description}</p>
                <ul style={{listStyleType: 'none'}}>
                    <li>1. {window.gettext('discordo totalmente')}</li>
                    <li>2. {window.gettext('discordo em parte')}</li>
                    <li>3. {window.gettext('concordo em parte')}</li>
                    <li>4. {window.gettext('concordo totalmente')}</li>
                </ul>
            </>
        );
    }

    renderExercise() {

        if (!this.state.hasService || this.state.userData.actual_role === 'admin') {
            return <></>
        }

        let {step} = this.state;
        let stepConfig = STEPS[step - 1];
        // this.renderInstructions(step)
        return (
            <React.Fragment>
                <Modal isOpen={this.state.openConfirmationModal}>
                    <ModalHeader style={{position: 'sticky'}}>{window.gettext('Obrigada por finalizar o Lumi!')}</ModalHeader>
                    <ModalBody style={{textAlign: 'center'}}>
                        {window.gettext('Caso queira enviar as respostas, clique em Concluir. Se deseja revisar, clique em Cancelar.')}
                    </ModalBody>
                    <ModalFooter>
                        <Button color='default' onClick={() => this.setState({openConfirmationModal: false})}>{window.gettext('Cancelar')}</Button>
                        <Button color='info' onClick={() => {return this.saveData();}}>{window.gettext('Concluir')}</Button>
                    </ModalFooter>
                </Modal>
                <Row>
                    <Col md="12">
                        {this.renderStepIntro(step)}
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        {this.renderDescription(step)}
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        {this.renderQuestions()}
                    </Col>
                </Row>

                {this.role === 'student' &&
                    <Row>
                        <Col md="12" className="text-center">

                            {stepConfig.button &&
                                <Button className="btn" color="default" type="button"
                                        onClick={this.backStep}
                                        disabled={step === 11 && this.state.is_answered || step === 1}
                                >
                                    {window.gettext('Retornar')}
                                </Button>
                            }
                            {stepConfig.button &&
                                <Button className="btn" color="info" type="button"
                                        onClick={this.nextStep}
                                        disabled={step === 11 && this.state.is_answered}
                                >
                                    {stepConfig.button}
                                </Button>
                            }
                        </Col>
                    </Row>
                }
            </React.Fragment>
        );
    }

    renderQuestions() {
        return (
            <Table>
                <tbody>
                {QUESTIONS.map((question, qId) => {

                    if (qId === 0) {
                        return '';
                    }

                    if (this.state.step != question.step) {
                        return '';
                    }

                    return (
                        <tr key={"question-" + qId}>
                            <td>
                                {qId}.
                            </td>
                            <td>
                                {question.question}
                                <br/>
                                {this.renderButtonGroup(qId)}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>
        );
    }

    renderButtonGroup(questionId) {
        let options = [];
        for (let i = 1; i <= 4; i++) {
            options.push(i);
        }
        // if (scoreEnd == 10) {
        //     options.push('+4');
        // }

        return (
            <ButtonGroup data-toggle="buttons" className="btn-group-choose-score">
                {options.map(option => {
                    let className = 'btn-round';
                    let score = this.state.answers[questionId];
                    let visibleScore = score <= 6 ? score : (score - 4);
                    let value;

                    if (option === '+4') {
                        if (score > 6) {
                            className += ' active';
                        }
                        // allow to unselect
                        value = score > 6 ? visibleScore : score + 4;
                    } else {
                        if (option == visibleScore) {
                            className += ' active';
                        }
                        value = option;
                    }

                    return (
                        <Button key={`option-${questionId}-${option}`}
                                className={className}
                                color="info"
                                outline
                                type="button"
                                onClick={(e) => this.answerQuestion(e, questionId, value)}
                            // disabled={this.state.step == 3 && (option !== '+4' || score < 3)}
                        >
                            {option}
                        </Button>
                    );
                })}
            </ButtonGroup>
        );
    }


    renderInstructions() {

        return new Promise((resolve, reject) => {
            let closeInstructions = () => {
                this.setState({instructionsModal: ''});
                resolve();
            };
            this.setState({
                instructionsModal: (
                    <ReactBSAlert
                        warning
                        style={{display: "block", marginTop: "-450px", width: "800px", marginLeft: "-400px"}}
                        title={window.gettext("Instruções")}
                        onConfirm={closeInstructions}
                        confirmBtnBsStyle="info"
                        confirmBtnText={window.gettext("OK")}
                    >
                        <div style={{textAlign: "justify", fontSize: "16px", lineHeight: "1.7"}}>
                        Prezado(a) participante, <br/> <br/>
                        Você está sendo convidado(a) a responder um instrumento que contém questões referentes a pensamentos, emoções e comportamentos que as pessoas têm em seu ambiente de trabalho. Por favor, leia cada questão e indique o quanto você concorda ou discorda que elas representam a sua forma de pensar, sentir e se comportar no trabalho. <br/><br/>
                        <ul>
                            <li>Não existem respostas certas ou erradas, responda da forma que você percebe e de forma sincera. </li>
                            <li>Não gaste muito tempo pensando em sua resposta, a sua primeira impressão é a mais importante. </li>
                            <li>Caso você não esteja empregada(o) no momento, considere as suas experiências em sua ocupação mais recentes. </li>
                            <li>Você levará cerca de 15 minutos para conclusão desta tarefa. </li>
                        </ul>
                        </div>
                    </ReactBSAlert>
                )
            });
        });
    }

    renderStepIntro(step) {
        return (
            <h5>
                <strong>{step}º {window.gettext('passo')}:</strong>&nbsp;
                {STEPS[step - 1].intro}
            </h5>
        );
    }

    renderModal(nextStep) {
        return new Promise((resolve, reject) => {
            let confirm = () => {
                this.setState({nextStepModal: ''});
                resolve();
            };
            this.setState({
                nextStepModal: (
                    <ReactBSAlert
                        warning
                        style={{display: "block", marginTop: "-300px", width: "800px", marginLeft: "-400px"}}
                        title={window.gettext("Próximo passo")}
                        onConfirm={confirm}
                        confirmBtnBsStyle="info"
                        confirmBtnText={window.gettext("OK")}
                    >
                        {this.renderStepIntro(nextStep)}
                    </ReactBSAlert>
                )
            });
        });
    }

    renderSelect() {
        return (
            <Row>
                <Col className="ml-auto mr-auto mb-3" md="8">
                    <Row>
                        <Form className="form-calendar-header">
                            <FormGroup>
                                <AsyncSelect
                                    className="react-select primary m-3"
                                    classNamePrefix="react-select"
                                    name="specialist_id"
                                    value={this.state.selectedUser}
                                    onChange={this.changeUser}
                                    defaultOptions
                                    loadOptions={this.loadUsers}
                                    placeholder={window.gettext("Selecione um usuário para ver seus resultados")}
                                />
                            </FormGroup>
                        </Form>
                    </Row>
                </Col>
            </Row>
        );
    }
}


const QUESTIONS = [

    {
        step: "",
        question: ""
    },
    {
        step: 1,
        question: window.gettext("Costumo participar de cursos e capacitações para me atualizar no trabalho"),
    },
    {
        step: 1,
        question: window.gettext("Costumo completar as tarefas dentro dos prazos estabelecidos"),
    },
    {
        step: 1,
        question: window.gettext("Gerencio meu tempo para atender primeiro as tarefas prioritárias"),
    },
    {
        step: 1,
        question: window.gettext("Ao concluir minhas tarefas essenciais, me dedico a tarefas extras"),
    },
    {
        step: 1,
        type: 'desejabilidade_social',
        question: window.gettext("Acredito que sou o(a) melhor funcionário(a) de toda a empresa"),
    },



    {
        step: 2,
        question: window.gettext("Fico nervoso(a)/ansioso(a) quando preciso apresentar resultados em reuniões de negócio")
    },
    {
        step: 2,
        question: window.gettext("Evito expressar minha opinião durante discussões sobre o funcionamento da organização")
    },
    {
        step: 2,
        question: window.gettext("Fico nervoso(a)/ansioso(a) quando preciso contatar pessoas desconhecidas para discutir algum problema")
    },
    {
        step: 2,
        question: window.gettext("Consigo contribuir com ideias para o desenvolvimento de projetos e atualizações da organização")
    },
    {
        step: 2,
        question: window.gettext("Fico confortável quando preciso apresentar informações sobre o meu trabalho a um grupo de pessoas que já conheço")
    },
    {
        step: 2,
        question: window.gettext("Sei que consigo trabalhar de forma eficiente com indivíduos de diferentes organizações")
    },
    {
        step: 2,
        type: 'desejabilidade_social',
        question: window.gettext("Sou capaz de realizar qualquer tarefa que alguém da organização solicitar"),
    },


    {
        step: 3,
        question: window.gettext("Costumo ter dificuldades em alcançar os objetivos que estabeleço")
    },
    {
        step: 3,
        question: window.gettext("Costumo estabelecer prazos pessoais para a entrega de tarefas")
    },

    {
        step: 3,
        question: window.gettext("Costumo traçar metas e objetivos pessoais")
    },
    {
        step: 3,
        question: window.gettext("Estabelecer objetivos me motiva a alcançá-los")
    },
    {
        step: 3,
        question: window.gettext("Sou capaz de usar diferentes estratégias para alcançar os meus objetivos")
    },
    {
        step: 3,
        question: window.gettext("Acredito que sou capaz de descobrir maneiras de alcançar os meus objetivos")
    },
    {
        step: 3,
        question: window.gettext("Me esforço para alcançar meus objetivos")
    },
    {
        step: 3,
        type: 'desejabilidade_social',
        question: window.gettext("Não importa o que aconteça, jamais desisto de uma tarefa")
    },




    {
        step: 4,
        question: window.gettext("Demoro a me recuperar quando algo não sai conforme o esperado")
    },
    {
        step: 4,
        question: window.gettext("Consigo permanecer calmo(a) durante eventos adversos")
    },
    {
        step: 4,
        question: window.gettext("Me recupero rápido após passar por situações estressantes")
    },
    {
        step: 4,
        question: window.gettext("Sei buscar ativamente os recursos necessários para lidar com adversidades")
    },
    {
        step: 4,
        question: window.gettext("Pensar em meus objetivos me auxilia a lidar com problemas")
    },
    {
        step: 4,
        question: window.gettext("Sou capaz de me adaptar às adversidades")
    },
    {
        step: 4,
        type: 'desejabilidade_social',
        question: window.gettext("Acredito que não exista nenhuma adversidade que eu não seja capaz de superar")
    },



    {
        step: 5,
        question: window.gettext("Algumas situações não tem nenhum lado positivo")
    },
    {
        step: 5,
        question: window.gettext("Acredito que todas as situações tenham um lado positivo")
    },
    {
        step: 5,
        question: window.gettext("Acredito que posso tirar proveito das situações adversas")
    },
    {
        step: 5,
        question: window.gettext("Mesmo que as coisas não aconteçam conforme o planejado, acredito que irão melhorar no futuro")
    },
    {
        step: 5,
        question: window.gettext("Tenho uma visão otimista do futuro")
    },


    {
        step: 6,
        question: window.gettext("Tento solucionar conflitos entre colegas de trabalho")
    },
    {
        step: 6,
        question: window.gettext("Me ofereço para ser mentor(a) ou tutor(a) de novos colegas")
    },
    {
        step: 6,
        question: window.gettext("Compartilho projetos, apresentações e outros tipos de materiais pessoais para ajudar meus colegas de trabalho")
    },
    {
        step: 6,
        question: window.gettext("Percebo quando um(a) colega está desanimado(a) e busco formas de encorajá-lo(a)")
    },
    {
        step: 6,
        question: window.gettext("Ofereço auxilio no trabalho do(a) meu/minha líder")
    },
    {
        step: 6,
        question: window.gettext("Busco auxiliar os colegas de trabalho em tarefas difíceis")
    },


    {
        step: 7,
        question: window.gettext("Realizo tarefas adicionais mesmo que não tenha sido solicitado")
    },
    {
        step: 7,
        question: window.gettext("Busco cumprir somente as obrigações definidas")
    },
    {
        step: 7,
        question: window.gettext("Participo de eventos organizacionais que não são obrigatórios")
    },
    {
        step: 7,
        question: window.gettext("Divulgo a organização em que trabalho para outras empresas, por exemplo, em eventos, palestras, conversas com amigos")
    },
    {
        step: 7,
        question: window.gettext("Transmito uma imagem positiva da empresa em conversas informais")
    },
    {
        step: 7,
        type: 'desejabilidade_social',
        question: window.gettext("Falo somente coisas positivas sobre a organização, mesmo que existam diversos pontos negativos")
    },

    //


    {
        step: 8,
        question: window.gettext("Por vezes fico insatisfeito comigo mesmo(a)")
    },
    {
        step: 8,
        question: window.gettext("Algumas vezes me sinto desanimado(a) e sem qualquer esperança")
    },
    {
        step: 8,
        question: window.gettext("Acho que tenho menos energia do que a maioria das pessoas")
    },
    {
        step: 8,
        question: window.gettext("Normalmente tenho controle sobre o meu trabalho e carreira")
    },
    {
        step: 8,
        question: window.gettext("Estou satisfeito(a) com a maior parte de minhas características")
    },
    {
        step: 8,
        question: window.gettext("Normalmente obtenho sucesso na execução de meus projetos e tarefas")
    },
    {
        step: 8,
        question: window.gettext("Acredito que sou capaz de resolver a maior parte dos problemas que surgem em minha vida")
    },
    {
        step: 8,
        question: window.gettext("Aprecio minhas qualidades")
    },
    {
        step: 8,
        type: 'desejabilidade_social',
        question: window.gettext("Tenho total controle sobre meus pensamentos e emoções em todas as situações")
    },


    {
        step: 9,
        question: window.gettext("Raramente penso em soluções para problemas futuros, prefiro resolver os problemas conforme eles aparecem")
    },
    {
        step: 9,
        question: window.gettext("Tento prever problemas futuros e pensar em possíveis soluções")
    },
    {
        step: 9,
        question: window.gettext("Tento identificar recursos para lidar com possíveis problemas em minhas tarefas")
    },
    {
        step: 9,
        question: window.gettext("Converso com minha equipe e liderança para tentar antecipar possíveis problemas em nossos projetos")
    },
    {
        step: 9,
        question: window.gettext("Ofereço apoio e suporte durante a execução de tarefas")
    },
    {
        step: 9,
        type: 'desejabilidade_social',
        question: window.gettext("Não importa o tamanho do obstáculo, encontrarei uma forma de superá-lo sozinho(a)")
    },




    {
        step: 10,
        question: window.gettext("Sugiro a implementação de novas tarefas relacionadas às minhas habilidades profissionais")
    },
    {
        step: 10,
        question: window.gettext("Peço feedback à minha liderança sobre a qualidade de meu trabalho")
    },
    {
        step: 10,
        question: window.gettext("Gosto de pensar nos impactos de minhas tarefas")
    },
    {
        step: 10,
        question: window.gettext("Acredito que meu trabalho pode contribuir para o sucesso da organização")
    },


    {
        step: 11,
        question: window.gettext("Sou bom(a) em evitar distrações")
    },
    {
        step: 11,
        question: window.gettext("Me sinto desconfortável se não finalizar uma tarefa que comecei")
    },
    {
        step: 11,
        question: window.gettext("Costumo traçar e alcançar meus objetivos e metas profissionais")
    },
    {
        step: 11,
        question: window.gettext("Acredito que palavra dada vale o mesmo que um contrato")
    },
    {
        step: 11,
        question: window.gettext("Reviso as minhas tarefas para evitar erros")
    },
    {
        step: 11,
        question: window.gettext("As pessoas sabem que podem contar comigo se precisarem de ajuda")
    },


];

const STEPS = [
    {
        intro: window.gettext("DESEMPENHO NO TRABALHO"),
        description: window.gettext("O desempenho no trabalho está relacionado ao ato de realizar tarefas de forma efetiva de acordo com critérios de qualidade, quantidade e outros. O desempenho no trabalho é uma percepção do trabalhador sobre suas metas em tarefas ou funções relacionadas ao trabalho e à organização."),
        button: window.gettext("Continuar")
    },
    {
        intro: window.gettext("PSYCAP Autoeficácia"),
        description: window.gettext("Percepção do indivíduo sobre sua performance e capacidade de atingir objetivos."),
        button: window.gettext("Continuar")
    },
    {
        intro: window.gettext("PSYCAP Esperança"),
        description: window.gettext("Características cognitivas e motivacionais para planejar e atingir metas e objetivos, de forma engajada."),
        button: window.gettext("Continuar")
    },
    {
        intro: window.gettext("PSYCAP Resiliência"),
        description: window.gettext("Capacidade de ativar recursos internos e externos para minimizar os efeitos de eventos adversos e estressantes."),
        button: window.gettext("Continuar")
    },
    {
        intro: window.gettext("Otimismo"),
        description: window.gettext("Crença geral na capacidade de atingir resultados positivos, individual ou coletivamente. Confiança em desfechos positivos."),
        button: window.gettext("Continuar")
    },
    {
        intro: window.gettext("OCB-Individual"),
        description: window.gettext("Cidadania organizacional (individual e em relação à organização): Ações não requeridas e prescritas que visam a beneficiar a organização e as pessoas que a compõe. São ações proativas e voluntárias, tanto a nível das relações como de tarefas."),
        button: window.gettext("Continuar")
    },
    {
        intro: window.gettext("OCB-Organizacional"),
        description: window.gettext("Cidadania organizacional (individual e em relação à organização): Ações não requeridas e prescritas que visam a beneficiar a organização e as pessoas que a compõe. São ações proativas e voluntárias, tanto a nível das relações como de tarefas."),
        button: window.gettext("Continuar")
    },
    {
        intro: window.gettext("AUTO-AVALIAÇÃO NUCLEAR/CENTRAL"),
        description: window.gettext("Representam um traço de personalidade estável referente as avaliações fundamentais sobre si mesmo, suas próprias habilidades e seu próprio controle."),
        button: window.gettext("Continuar")
    },
    {
        intro: window.gettext("ENFRENTAMENTO PROATIVO"),
        description: window.gettext("Estratégias que são utilizadas para construir recursos gerais que facilitem a promoção em direção a metas desafiadoras e ao crescimento pessoal. O diferencial desse modelo de enfrentamento, com relação aos modelos tradicionais, se dá em função de que esse é utilizado para prever formas de enfrentar situações estressoras que podem ocorrer no futuro."),
        button: window.gettext("Continuar")
    },
    {
        intro: window.gettext("JOB CRAFTING"),
        description: window.gettext("São comportamentos proativos no trabalho que consistem em iniciativas individuais informais que buscam modificar o trabalho para otimizar seus recursos e demandas, ou para torná-lo mais envolvente e gratificante."),
        button: window.gettext("Continuar")
    },
    {
        intro: window.gettext("CONSCIENCIOSIDADE"),
        description: window.gettext("É um dos traços de personalidade do modelo dos cinco grandes fatores (big five). Está relacionado a capacidade de ser cuidadoso ou diligente. Implica o desejo de executar bem uma tarefa e levar a sério as obrigações para com os outros."),
        button: window.gettext("Concluir")
    },
];

export default withNotify(PerformanceGrou);
