import React from "react";

// reactstrap components
import {Badge, CardBody, CardHeader, Col, Row} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import CardShowContent from "./CardShowContent";

class WorkPerformance extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.score = 19;
        this.topScore = 24;
    }

    get total() {
        if (!this.props.data || !this.props.data.total_cut_point_applied) {
            return 0;
        }
        return this.props.data.total_cut_point_applied || 0;
    }

    renderHigh() {
        return (
            <>
                {window.gettext("Você possui um excelente nível de percepção positiva sobre seu desempenho, tanto em situações mais controladas quanto em situações inusitadas ou com alto grau de incerteza. Você nutre crenças de que é capaz de lidar com novos desafios, mesmo que eles ponham em xeque sua competência, para si ou frente aos demais. Apresenta facilidade de expor suas opiniões e ponto de vista, consegue ser assertivo.")}
            </>
        );
    }

    renderLower() {
        return (
            <>
                {window.gettext("Você se caracteriza por uma percepção de baixa a média em relação a sua expectativa de desempenho e em lidar com novos desafios. Você consegue perceber uma visão positiva do seu desempenho em situações mais controladas ou que não o exponham muito, contudo, em contextos com menos controle ou com maior grau de incerteza, você tende a desconfiar do seu desempenho e performance. Busque ser mais autoconfiante em cenários de menor controle.")}
            </>
        )
    }

    isHigher() {
        if (this.total) {
            return this.total >= this.score;
        }
        return false;
    }

    getDescription() {
        if (this.isHigher()) {
            return this.renderHigh();
        }
        return this.renderLower();
    }

    render() {

        return (
            <>
                <div className="content ancora-container">
                    <Row>
                        <Col md={12}>
                            <CardShowContent
                                isHigher={this.isHigher()}
                                topScore={this.topScore}
                                description={this.getDescription()}
                                total={this.total}
                                helper={window.gettext('Percepção do indivíduo sobre sua performance e capacidade de atingir objetivos.')}
                                title={window.gettext('Autoeficácia')}
                            />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(WorkPerformance);
