import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import defaultAvatar from "assets/img/default-avatar.png";
import logo from "assets/img/logo-negative-2.png";
import logoGrou from "assets/img/self-guru-grou-novo.png";
import logoSmall from "assets/img/logo-small.png";

import {
  getUserData,
  logout,
  redirectLogin,
  redirectToLoginIfNeeded,
  getUserFriendlyRole,
  isAdminCompany, updateUserData
} from "../../lib/Auth";
import Select from "react-select";
import ConfirmModal from "../Modals/ConfirmModal";
import apiRequest from "../../lib/Api";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getCollapseStates(props.routes),
      user: getUserData() || {},
      selectedProfile: {},
      confirmChangeProfile: false,
      logo: logo,
    };
    redirectToLoginIfNeeded();
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    return routes.map((prop, key) => {

      if (prop.redirect) {
        return null;
      }
      if (prop.permissions) {
        let {permissions} = prop;
        if (!Array.isArray(permissions)) {
          permissions = [permissions];
        }
        if (permissions.indexOf(this.state.user.role) === -1) {
          if (permissions.indexOf('admin') !== -1 && this.state.user.adminOrganization) {
            // acceptable, special case
          } else {
            return;
          }
        }
      }


      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];

        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }


      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          {!prop.absolute && (
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
          )}
          {prop.absolute && (
          <a href={prop.url} target="blank">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </a>
          )}
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }

    if (process.env.REACT_APP_NEW_VI === 'true') {
      this.setState({logo: logoGrou});
    }
    this.setProfileValue();
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  logout = (e) => {
    e.preventDefault();
    logout()
      .then(() => redirectLogin(true));
  }

  renderChangeCompanyMenu() {
    if (isAdminCompany()) {
      return (
          <>
            <li>
              <NavLink to="/admin/change-company" activeClassName="">
                <span className="sidebar-mini-icon">AC</span>
                <span className="sidebar-normal">
                        {window.gettext('Alterar Empresa')}
                      </span>
              </NavLink>
            </li>
          </>
      );
    }
  }

  renderCompanyName() {
    if (isAdminCompany()) {
      const organizationName = this.state.user.adminOrganization.name;
      return (
          <>
            <br />
            <small style={{opacity: 0.6}}>{organizationName}</small>
          </>
      );
    }
  }

  setProfileValue() {
    if (this.state.user) {
      let data = {
        value: "member",
        label: "Membro"
      };
      if (isAdminCompany()) {
        data = {
          value: "admin",
          label: "Administrador"
        };
      }
      this.setState({'selectedProfile' : data});
    }
  }

  changeProfile() {

    if (this.state.user.default_role !== 'admin') {
      return <></>;
    }
    return (
        <>
          <li>
            <Select
                className="select-profile"
                name="selectedProfile"
                value={this.state.selectedProfile}
                onChange={value =>
                    this.setState({ selectedProfile: value, confirmChangeProfile: true })
                }
                options={[
                  { value: "member", label: "Membro" },
                  { value: "admin", label: "Administrador" }
                ]}
                placeholder="Selecione..."
            />
          </li>
        </>
    );
  }

  setNewProfile() {
    apiRequest(`/users/change-profile/${this.state.selectedProfile.value}`, {method: 'POST'})
        .then((response) => {
          localStorage.setItem('userData', JSON.stringify(response.data.data));
          this.setState({user: response.data.data});
          this.setProfileValue();
          window.location.href='/admin/dashboard';
        });
  }

  render() {
    let avatar = this.state.user.pictureUrl || defaultAvatar;
    let friendlyRole = getUserFriendlyRole();
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          <a
            href="https://www.selfguru.com.br"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logoSmall} alt="selfguru-logo-small" />
            </div>
          </a>
          <a
            href="https://www.selfguru.com.br"
            className="simple-text logo-normal"
          >
            <img src={this.state.logo} alt="selfguru-logo" />
          </a>
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          <div className="user">
            <div className="photo">
              <img src={avatar} alt="Avatar" />
            </div>
            <div className="info">
              <a
                href="#"
                data-toggle="collapse"
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span>
                  {this.state.user.name}
                  <b className="caret" />
                  <br/>
                  <small style={{opacity: 0.6}}>{friendlyRole}</small>
                  {this.renderCompanyName()}
                </span>
              </a>
              <Collapse isOpen={this.state.openAvatar}>
                <ul className="nav">
                  {this.changeProfile()}
                  <li>
                    <NavLink to="/admin/user-profile" activeClassName="">
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">
                        {window.gettext('Meu perfil')}
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/change-password" activeClassName="">
                      <span className="sidebar-mini-icon">AS</span>
                      <span className="sidebar-normal">{window.gettext('Alterar senha')}</span>
                    </NavLink>
                  </li>
                  {this.renderChangeCompanyMenu()}
                  <li>
                    <a onClick={this.logout} style={{cursor: 'pointer'}}>
                      <span className="sidebar-mini-icon">L</span>
                      <span className="sidebar-normal">
                        {window.gettext('Logout')}
                      </span>
                    </a>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>

        <ConfirmModal message="Deseja alterar o seu perfil? Após a conclusão, esta janela será atualizada."
                      onConfirm={() => {this.setNewProfile()}}
                      onToggle={() => {this.setState({confirmChangeProfile:false});}}
                      open={this.state.confirmChangeProfile}
        />
      </div>
    );
  }
}

export default Sidebar;
