import React from "react";
import {Redirect} from "react-router-dom";
import AsyncSelect from "react-select/lib/Async";
// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment-timezone";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

import apiRequest from "lib/Api";
import withNotify from "lib/NotificationWrapper";
import {calendarMessages} from "lib/Locale";

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Button, ModalHeader, ModalBody, Label, Input, Table, Modal
} from "reactstrap";
import {getUserData} from "../../../lib/Auth";
import InputError from "../../components/InputError";

const DEFAULT_VIEW = 'agenda';
const DEFAULT_DURATION = 30;

let initDate = moment().add(2, 'day').toDate();
//initDate = moment().toDate();

class AppointmentCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slots: [],
      slotDuration: DEFAULT_DURATION,
      selectedSpecialist: '',
      previousAppointment: null,
      allowSpecialistSelect: false,
      wizard: false,
      redirect: '',
      activeUser: getUserData(),
      confirmAlert: '',
      appointmentId: null,
      working: false,
      purchase_service_id: null,
      view: DEFAULT_VIEW,
      guest: '',
      guestsInvited: [],
      openModalGuest: false,
      confirmDate: '',
      slotSelected: '',
      productRules: {},
      invalidEmails: [],
    };
    if (getUserData()) {
      moment.tz.setDefault(getUserData().timezone || 'America/Sao_Paulo');
    }
    this.localizer = BigCalendar.momentLocalizer(moment);

  }

  get purchaseServiceId() {
    if (this.props.match.params && this.props.match.params.purchaseServiceId) {
      return this.props.match.params.purchaseServiceId;
    }
    return null;
  }
  get userId() {
    if (this.props.match.params && this.props.match.params.userId) {
      return this.props.match.params.userId;
    }
    return null;
  }

  componentDidMount() {
    if (this.state.activeUser.role === 'super_admin' || this.state.activeUser.role === 'specialist') {
      this.setState({slotDuration: 60});
      initDate = moment().toDate();
    }
    if (this.props.match.params && this.props.match.params.specialistId) {
      let {specialistId} = this.props.match.params;
      this.setState({selectedSpecialist: {value: specialistId}, wizard: true},
        () => this.requestData()
      );
    }
    else if (this.props.match.params && this.props.match.params.previousAppointmentId) {
      let {previousAppointmentId} = this.props.match.params;
      this.fetchAppointment(previousAppointmentId)
        .then(appointment => {
          let newState = {
            selectedSpecialist: {value: appointment.specialist_id},
            previousAppointment: appointment,
            purchase_service_id: appointment.purchase_service_id
          };
          this.setState(newState, () => this.requestData());
        });
    }
    else {
      this.setState({allowSpecialistSelect: true, wizard: false});
      this.loadSpecialists();
    }

  }

  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
    let id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  fetchAppointment(id) {
    return apiRequest("/appointments/" + id, {method: "GET"})
        .then(response => response.data.data);
  }

  openModalToInviteGuest = (slot) => {

    this.toggleModalInviteGuest();
    this.setState(
        {
          'confirmDate': moment(slot.start).calendar(),
          'guest': '',
          'slotSelected': slot
        }
    );
  }

  toggleModalInviteGuest = () => {
    this.setState({'openModalGuest' : !this.state.openModalGuest});
  }

  createAppointmentWithGuest = () => {
    let slot = this.state.slotSelected;
    this.createAppointment(slot);


    // call again, to render modal with disabled button
    this.selectSlot(slot, true);
  }

  selectSlot = (slot, disabled = false) => {

    if (this.state.productRules.enable_guest) {

      this.openModalToInviteGuest(slot);
      return;
    }

    let hide = () => {
      this.setState({confirmAlert: null});
    }
    const user = this.state.activeUser;
    let confirmDate = moment(slot.start).lang(user.language).calendar();

    this.setState({
      confirmAlert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title={window.gettext('Confirmação')}
          onConfirm={() => {
            if (disabled) {
              return;
            }
            this.createAppointment(slot)
              .finally(() => hide());

            // call again, to render modal with disabled button
            this.selectSlot(slot, true);
          }}
          onCancel={() => hide()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={disabled ? window.gettext("Agendando...") : window.gettext("Sim, agendar!")}
          disabled={disabled}
          cancelBtnText={window.gettext("Cancelar")}
          showCancel
        >
          {window.gettext('Deseja agendar para %1?', confirmDate)}
        </ReactBSAlert>
      )
    });
  }

  createAppointment = slot => {
    let data = {
      specialist_id: this.state.selectedSpecialist.value,
      datetime_start: moment(slot.start).format('YYYY-MM-DD HH:mm'),
      datetime_end: moment(slot.end).format('YYYY-MM-DD HH:mm'),
      duration: this.state.slotDuration
    };


    this.setState({errors:{}});
    if (this.state.productRules.enable_guest) {

      let eregEmail = /\S+@\S+\.\S+/;
      let guests = this.state.guest.split(',');

      if (this.state.guest.trim() === '') {
        this.setState({errors:{guest:window.gettext(' Ao menos um convidado deve ser informado.')}});
        throw 'invalid email count';
      }

      let invalidEmails = [];
      for (const guest of guests) {
        if (!eregEmail.test(guest)) {
           invalidEmails.push(guest);
           this.setState({errors:{guest:window.gettext(' os seguintes emails estão inválidos: %1 ', invalidEmails.join(", "))}});
        }
      }
      if (invalidEmails.length > 0) {
        this.setState({invalidEmails: invalidEmails});
        throw 'invalid email';
      }

      this.setState({invalidEmails: invalidEmails});
      data.guests = guests;
    }


    if (this.state.previousAppointment) {
      data.previous_appointment_id = this.state.previousAppointment.id;
      data.purchase_service_id = this.state.previousAppointment.purchase_service_id;
    }
    else {
      data.purchase_service_id = this.purchaseServiceId;
    }

    if (this.state.activeUser.role === 'super_admin' || this.state.activeUser.role === 'specialist') {
      data.user_id = this.userId;
    }

    this.setState({working: true});

    /**
     * post to create a appointment
    */
    return apiRequest("/appointments", {data, method: 'POST'}).then(response => {
        this.props.notify({type: 'success', message: window.gettext('Agendamento realizado com sucesso')});
        this.state.appointmentId =  response.data.data.id;
        if (this.state.wizard) {
          setTimeout((response) => {
            let redirect = '/admin/dashboard';
            if (this.state.activeUser.role === 'super_admin') {
              redirect = `/admin/appointments/${this.state.appointmentId}/show`;
            }
            this.setState({redirect});
          }, 500);
        }
      })
      .catch(err => {
        let message = window.gettext('Não foi possível agendar. Tente mais tarde.');
        if (err.data && err.data.errors) {
          message = err.data.errors[0];
        }
        if (this.state.productRules.enable_guest) {
          this.toggleModalInviteGuest()
        }
        this.props.notify({type: 'error', message});
      })
      .finally(
        response => {
          this.setState({working: false});
          if (this.state.productRules.enable_guest) {
            this.toggleModalInviteGuest()
          }
        }
      );
  };

  loadSpecialists = searchTerm => {
    let params = {
      searchRole: 'specialist',
      search: searchTerm
    };
    return apiRequest("/users", {data: params, method: 'GET'})
      .then(response => {
        return response.data.data.map(user => ({value: user.id, label: user.name}));
      });
  }

  requestData() {
    let specialist = this.state.selectedSpecialist;
    if (!specialist) {
      return;
    }

    let params = {
      specialist_id: specialist.value,
      purchase_service_id: this.purchaseServiceId
    };
    if (this.state.purchase_service_id && !this.purchaseServiceId) {
      params.purchase_service_id = this.state.purchase_service_id
    }
    return apiRequest('/calendar', {method: 'GET', data: params})
      .then(response => {
        let slots = [];
        let {duration} = response.data.data;

        for (let dayData of response.data.data.slots) {
          for (let block of dayData.blocks) {
            if (!block.available) {
              continue;
            }

            let datetimeStart = moment(dayData.dayNum + ' ' + block.timeStart);
            let datetimeEnd = moment(dayData.dayNum + ' ' + block.timeEnd);

            let newSlot = {
              start: datetimeStart.toDate(),
              end: datetimeEnd.toDate()
            };
            slots.push(newSlot);
          }
        }
        let rules = {};
        if (response.data.data.product_rules) {
          rules = response.data.data.product_rules;
        }
        this.setState({slots, slotDuration: duration, productRules: rules});
      });
  }

  changeSpecialist = value => {
    this.setState({selectedSpecialist: value}, () => this.requestData());
  }

  onView = view => {
    this.setState({view});
  }

  render() {
    let hasError = InputError.convertToHasClass(this.state.errors);
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    let formats = {
      dayHeaderFormat: 'dddd'
    };

    return (
        <>
        {this.state.confirmAlert}
        <div className="content appointment-create-page">

          { this.state.allowSpecialistSelect &&

          <Row>
            <Col className="ml-auto mr-auto mb-2" md="10">
              <Row>
                <Form inline={true} className="form-calendar-header">
                  <FormGroup>
                    <AsyncSelect
                      className="react-select primary m-3"
                      classNamePrefix="react-select"
                      name="specialist_id"
                      value={this.state.selectedSpecialist}
                      onChange={this.changeSpecialist}
                      defaultOptions
                      loadOptions={this.loadSpecialists}
                      placeholder={window.gettext("Selecione um especialista")}
                    />
                  </FormGroup>
                </Form>
              </Row>
            </Col>
          </Row>

          }

          { this.state.selectedSpecialist && (

          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Card className="card-calendar">
                <CardBody>
                  <BigCalendar
                    selectable
                    className={`appointment-create-calendar ${this.state.view}-view`}
                    localizer={this.localizer}
                    culture={this.state.activeUser.language.replace("_", "-")}
                    events={this.state.slots}
                    timezone={this.state.activeUser.timezone}
                    defaultView={DEFAULT_VIEW}
                    timeslots={1}
                    step={this.state.slotDuration}
                    formats={formats}
                    messages={calendarMessages}
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={initDate}
                    onView={this.onView}
                    titleAccessor={event => {
                      return (
                        <React.Fragment>
                          <span>
                            {window.gettext('Disponível')}
                          </span>
                          <Button className="m-0 ml-3 btn-schedule" color="info" size="sm"
                            onClick={() => this.selectSlot(event)}
                          >
                            {window.gettext('Agendar')}
                          </Button>
                        </React.Fragment>
                      );
                    }}
                    components={{
                      toolbar: this.renderToolbar
                    }}
                  />
                </CardBody>
              </Card>
            </Col>

            <Modal isOpen={this.state.openModalGuest} size="md">
              <ModalBody>
                <div style={{textAlign: 'center'}}>
                <div>
                  <img src={require("assets/img/exclamation.png")} />
                </div>
                <div>
                  <h2>
                    {window.gettext('Confirmação')}
                  </h2>
                  <p style={{fontSize: '14px'}}>
                    {window.gettext('Confirma o agendamento para') + ' ' + this.state.confirmDate + '?'}
                  </p>
                </div>
                </div>
                <form>
                  <Row>
                    <Col>
                      <p style={{fontSize:'10px'}}>
                        {window.gettext('Para esse agendamento, você deve incluir no mínimo mais um convidado conforme combinado com a empresa (seu gerente, RH) que deve participar junto com você e a especialista. Você pode incluir mais de um e-mail, separando por vírgula.')}
                      </p>
                      <FormGroup className={hasError.guest}>
                        <Input placeholder='exemple@selfguru.com.br, otherexemple@selfguru.com.br' type="text" name="guest" value={this.state.guest} onChange={this.handleChangeGuest} />
                        <InputError name="guest" errors={this.state.errors} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Button color="danger" size="lg" onClick={this.toggleModalInviteGuest}>
                      CANCELAR
                    </Button>
                    <Button color="primary" size="lg" disabled={this.state.working} onClick={this.createAppointmentWithGuest}>
                      {this.state.working ? window.gettext("AGENDANDO...") : window.gettext("SIM, AGENDAR!")}
                    </Button>
                  </Row>
                </form>
              </ModalBody>
            </Modal>
          </Row>

          ) }

        </div>
      </>
    );
  }

  handleChangeGuest = (event) => {
    this.setState({guest: event.target.value});
  }

  renderToolbar = toolbar => {
    const dayView = () => {
      toolbar.onView('day');
    }
    const weekView = () => {
      toolbar.onView('week');
    }
    const agendaView = () => {
      toolbar.onView('agenda');
    }
    const goTo = (action) => {
      let newDate = moment(toolbar.date);
      let interval = toolbar.view === 'agenda' ? 'month' : toolbar.view;
      if (action === 'prev') {
        newDate.subtract(1, interval);
        if (newDate.isBefore(initDate)) {
          return false;
        }
      }
      else {
        newDate.add(1, interval);
      }
      toolbar.onNavigate(action, newDate);
    }
    const goNext = () => {
      goTo('next');
    }
    const goBack = () => {
      goTo('prev');
    }

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button" onClick={goBack}>
            {window.gettext('Anterior')}
            </button>
          <button type="button" onClick={goNext}>
            {window.gettext('Próximo')}
          </button>
        </span>
        <span className="rbc-toolbar-label" style={{fontSize: '30px'}}>
          {toolbar.label}
        </span>
        <span className="rbc-btn-group">
          <button type="button" onClick={weekView}>
            {window.gettext('Semana')}
          </button>
          <button type="button" onClick={dayView}>
            {window.gettext('Dia')}
          </button>
          <button type="button" onClick={agendaView}>
            {window.gettext('Agenda')}
          </button>
        </span>
      </div>
    );
  }
}

export default withNotify(AppointmentCreate);
