import React from "react";

// reactstrap components
import {Alert, Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,} from "reactstrap";
import withNotify from "../../../lib/NotificationWrapper";
import {getUserData} from "../../../lib/Auth";
import apiRequest from "../../../lib/Api";

class SpecialistBriefingModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_user: getUserData(),
            isOpen: false,
            textBriefing: '',
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isOpen === false && this.props.open) {
            this.setState({isOpen: true, textBriefing: this.props.textBriefing})
        }
    }

    cancel() {
        this.setState({isOpen: false, textBriefing: ''});
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    renderSaveButton() {
        if (this.props.disabledForm) {
            return <></>;
        }

        return (
            <>
                <Button
                    color='primary'
                    onClick={() => this.save()}>
                    {window.gettext('Salvar')}
                </Button>{'  '}
            </>
        );
    }

    async save() {

        if (this.props.selectedTab === 'desassociar') {
            const data = {
                text_briefing: this.state.textBriefing,
                user_id: this.props.user.value,
                purchase_id: this.props.purchaseId,
            }
            await apiRequest(`/specialist-briefings`, {data})
            this.props.notify(
                {
                    type: 'success',
                    message: window.gettext('Briefing salvo com sucesso.')
                });
            this.setState({textBriefing: ''}, () => this.cancel() )
            return
        }

        if (this.props.selectedTab === 'associar') {
            const data = {
                text_briefing: this.state.textBriefing,
                user_id: this.props.user.id,
            }
            this.props.onSave(data)
            this.cancel()
        }
    }

    handleChange = (event) => {
        const {name} = event.target
        this.setState({[name]: event.target.value})
    }

    render() {
        return (
            <>
                <Modal
                    size='lg'
                    isOpen={this.props.open}
                >
                    <ModalHeader >
                        {window.gettext('Briefing para Especialistas')}
                    </ModalHeader>
                    <ModalBody>
                        <Label for='textBriefing'>Briefing</Label>
                        <Input type="textarea"
                               disabled={this.props.disabledForm || false}
                               name="textBriefing"
                               value={this.state.textBriefing}
                               onChange={this.handleChange}
                               placeholder={window.gettext("Digite o briefing para o usuário selecionado.")}
                               style={{height: '300px', maxHeight: '300px'}}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => this.cancel()}>
                            {window.gettext('Fechar')}
                        </Button>
                        &nbsp;
                        {this.renderSaveButton()}
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default withNotify(SpecialistBriefingModal);
