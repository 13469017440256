import apiRequest from "lib/Api";
import enviroment from "../services/enviroment";
import {getAuthToken} from "../lib/Auth";

const SPECIALIST_CALL = 'specialist_call';
const REVIEW_CV = 'review_cv';
const ANCORA = 'ancora';
const PERFORMANCE_GROU = 'performance_grou';
const DISC = 'disc';
const DILEMAS = 'dilemas';
const PDA = 'pda';
const MBTI = 'mbti';
const ONLINE_COURSE = 'online_course';


class Services {
  async getPurchasedServices(tasks) {
    let url = "/purchase-services";
    if (tasks) {
      url += '?include=tasks';
    }
    return apiRequest(url, {method: "GET"})
      .then(response => response.data.data);
  }

  async getPurchasedService(id) {
    return apiRequest("/purchase-services/" + id, {method: "GET"})
      .then(response => response.data.data);
  }

  async getPurchasedServicesByName(serviceName) {
    return this.getPurchasedServices()
      .then(purchaseServices => {
        let items = purchaseServices.filter(purchaseService => purchaseService.service.name == serviceName);
        return items.length ? items : false;
      });
  }

  async getSpecialistCallServices() {
    return this.getPurchasedServicesByName(SPECIALIST_CALL);
  }

  getAction(purchaseService) {
    let {service, productService} = purchaseService;


    if (service.name === SPECIALIST_CALL) {
      return {route: `/admin/choose-specialist/${purchaseService.id}`};
    }
    else if (service.name === ANCORA) {
      return {route: `/admin/ancora`};
    }
    else if (service.name === ANCORA) {
      return {route: `/admin/ancora`};
    }
    else if (service.name === REVIEW_CV) {
      return {route: `/admin/upload-cv`};
    }
    else if (service.name === PERFORMANCE_GROU) {
      return {route: '/admin/performancegrou'};
    }
    else if (service.name === ONLINE_COURSE) {
      let token = window.btoa(getAuthToken());
      let linkUrl = enviroment.getEadUrl()+`courses/${productService.course.uuid}/view?t=${token}`;
      return {link: linkUrl}
    }
    else if (productService.params) {
      if (productService.params.link) {
        return {link: productService.params.link};
      }
      else if (productService.params.mailto) {
        return {onClick: () => this.onClick(purchaseService)};
      }
    }
    return {};
  }

  onClick = (purchaseService) => {
    return apiRequest(`/purchase-services/${purchaseService.id}/start`, {method: 'POST'})
      .then(response => response.data);
  }

  get consts() {
    return {
      SPECIALIST_CALL,
      REVIEW_CV,
      ANCORA,
      DISC,
      DILEMAS,
      PDA,
      MBTI,
      ONLINE_COURSE,
    };
  }
}

let singleton = new Services;
export default singleton;
