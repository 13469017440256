import React from "react";
import AsyncSelect from "react-select/lib/Async";
import Select from "react-select";
import InputMask from 'react-input-mask';
import {Redirect} from "react-router-dom";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Row
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import InputError from "../../components/InputError";
import {getUserData} from "../../../lib/Auth";
import {removeMask} from "../../../lib/Formatter";
import statesDef from "../../../variables/states";
import timezones from "../../../variables/timezones";
import Wizard from "models/Wizard";
import ExtendedDataFields from "../../../components/ExtendedData/ExtendedDataFields";
import TranslationPanel from "../../components/TranslationPanel";

class UserForm extends React.Component {
    constructor(props) {
        super(props);
        this.timeZones = timezones.map(timezone => ({label: timezone, value: timezone}))
        this.languages = [
            {label: window.gettext('Português'), value: 'pt_BR'},
            {label: window.gettext('Inglês'), value: 'en_US'},
        ]
        this.allCompanies = [];
        this.state = {
            name: '',
            email: '',
            password: '',
            cpf: '',
            address: '',
            city: '',
            state: '',
            cep: '',
            phone: '',
            mobile: '',
            linkedin: '',
            bio: '',
            is_foreigner: false,
            organization_id: '',
            organization_role: '',
            curriculum: null,
            role: '',
            working: false,
            extendedFieldType: null,
            errors: {},
            loggedUserData: getUserData(),
            redirect: '',
            language: this.languages.find(option => option.value === 'pt_BR'),
            timezone: this.timeZones.find(option => option.value === 'America/Sao_Paulo'),
            extended_data: {},
            organization_permissions: [],
            companyOptions: [],
            isShowCompanyAllowed: false,
            companiesAvaliable: []
        };
        this.statesDef = statesDef.map(state =>
            ({label: state.name, value: state.id})
        );
        this.isUpdate = false;
        this.handleCep = this.handleCep.bind(this);
    }

    componentWillMount() {
        if (this.id) {
            this.fetchUser();
            this.isUpdate = true;
        }
    }

    componentDidMount() {
        this.loadOrganizations(null);
    }

    async fetchUser() {
        return await apiRequest("/users/" + this.id, {method: "GET"})
            .then(response => {
                let {data} = response.data;

                for (let field of Object.keys(data)) {
                    if (data[field] === null) {
                        data[field] = '';
                    }
                }

                if (data.organization) {
                    let {organization} = data;
                    delete data.organization;

                    data.organization_id = {value: organization.id, label: organization.name};
                    this.loadOwnerOrganizations(organization.id);

                    let foundRole = this.orgUserRoles.find(item => item.value === organization.user_role);
                    data.organization_role = {...foundRole};
                }

                data.state = this.statesDef.find(option => option.value === data.state);
                data.language = this.languages.find(option => option.value === data.language);
                data.timezone = this.timeZones.find(option => option.value === data.timezone);

                data.organization_permissions = data.organization_permissions.map(organization => {
                    if (organization.id !== data.organization_id.value) {
                        return {
                            label: organization.name,
                            value: organization.id
                        }
                    }
                });

                console.log(data);
                this.setState(data);
            });
    }

    get id() {
        if (this.props.match.params) {
            let {id} = this.props.match.params;
            if (id === 'me') {
                id = this.state.loggedUserData.id;
            }
            return id;
        }
        return null;
    }

    get isSetup() {
        return !!this.props.match.path.match(/setup/);
    }

    get scope() {
        if (this.props.match.params.scope) {
            return this.props.match.params.scope;
        }
        if (this.props.match.path.match(/users/)) {
            return 'users';
        } else if (this.props.match.path.match(/specialists/)) {
            return 'specialists';
        }
        throw new Error("Invalid context");
    }

    get role() {
        if (this.scope === 'users') {
            return 'student';
        }
        if (this.scope === 'specialists') {
            return 'specialist';
        }
        return null;
    }

    get formTitle() {
        if (this.isSetup) {
            return window.gettext('Preencha seus dados');
        }
        if (this.scope === 'users') {
            if (this.id) {
                return window.gettext('Alteração de usuário');
            }
            return window.gettext('Cadastro de usuário');
        }
        if (this.scope === 'specialists') {
            if (this.id) {
                return window.gettext('Alteração de especialista');
            }
            return window.gettext('Cadastro de especialistas');
        }
        return '';
    }

    handleCep(name, value) {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }
        this.setState({cep: value});
        this.setCep(value)
    }

    setCep(cep) {

        cep = cep.replaceAll('-', '');
        cep = cep.replaceAll('_', '');

        if (cep.length < 8) {
            return;
        }

        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(response => response.json())
            .then(data => {

                const userState = data.state = this.statesDef.find(option => option.value === data.uf);
                this.setState({
                    city: data.localidade,
                    address: data.logradouro,
                    state: userState,
                    neighborhood: data.bairro,
                })
            })
            .catch(err => {
                console.log('cep Nao encontrado')
            })
    }

    clearForm() {
        this.setState({
            name: '',
            email: '',
            password: '',
            cpf: '',
            address: '',
            city: '',
            state: '',
            cep: '',
            phone: '',
            mobile: '',
            linkedin: '',
            bio: '',
            curriculum: null
        });
    }

    collectOrganizations() {
        let companies = [];
        if (this.state.organization_role.value === 'admin') {
            companies = this.allCompanies.map(item => ({value: item.id, label: item.name}));
        } else {
            this.allCompanies.forEach((item) => {
                if (item.is_parent === false) {
                    companies.push(
                        {value: item.id, label: item.name}
                    );
                }
            });
        }

        this.setState({
                companiesAvaliable: companies,
                companyOptions: [],
        }
        );

    }

    async loadOrganizations(searchTerm) {
        let params = {
            search: searchTerm
        };
        await apiRequest("/organizations", {data: params, method: 'GET'})
            .then(response => {
                this.allCompanies = response.data.data;
                this.collectOrganizations()
            });
    }

    async loadOwnerOrganizations(value) {

        this.setState({organization_permissions: []});
        if (!value) {
            return;
        }

        return await apiRequest(`/organizations/owner/${value}`, {method: 'GET'})
            .then(response => {
                let companies = []
                response.data.data.map(company => {
                    if (Number(company.id) !== Number(value)) {
                        companies.push({label: company.name, value: company.id});
                    }
                });
                this.setState({companyOptions: companies});
            });
    }

    get orgUserRoles() {
        return [
            {value: "admin", label: window.gettext("Administrador da empresa")},
            {value: "member", label: window.gettext("Membro da empresa")},
        ];
    }

    handleTesteChange = (e) => {
        let {name, value} = e.target;

        if (name === 'is_foreigner') {
            value = !this.state.is_foreigner;
        }

        this.setState({[name]: value});

        if (this.state.errors[name]) {
            this.setState({errors: {...this.state.errors, [name]: ''}});
        }
    }
    handleChange = (name, value) => {
        if (typeof name === 'object' && typeof name.target === 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }

        this.setState({[name]: value});

        if (this.state.errors[name]) {
            this.setState({errors: {...this.state.errors, [name]: ''}});
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let {
            name,
            email,
            cpf,
            address,
            city,
            state,
            cep,
            phone,
            mobile,
            linkedin,
            curriculum,
            bio,
            organization_id,
            organization_role,
            password,
            extended_data,
            timezone,
            language,
            organization_permissions,
            is_foreigner

        } = this.state;

        cpf = removeMask(cpf);
        cep = removeMask(cep);
        phone = removeMask(phone);
        mobile = removeMask(mobile);
        state = state ? state.value : '';
        if (state === undefined) {
            state = '';
        }
        let data = {
            name,
            email,
            cpf,
            address,
            city,
            state,
            cep,
            phone,
            mobile,
            linkedin,
            bio,
            extended_data,
            organization_permissions,
            is_foreigner
        };

        if (password) {
            data.password = password;
        }
        if (organization_id) {
            data.organization_id = organization_id.value;
            if (organization_role) {
                data.organization_role = organization_role.value;
            }
        }
        data.language = language.value;
        data.timezone = timezone.value;
        data.is_foreigner = data.is_foreigner ? 1 : 0;

        // use form data because we need to attach a file together
        let formData = new FormData()
        let companies = [];
        for (const [key, value] of Object.entries(data)) {
            if (key === 'organization_permissions') {
                companies = value;
                continue;
            }
            formData.append(key, value);
            if (key === 'extended_data') {
                formData.set(key, JSON.stringify(value));
            }

        }

        let organizationCode = [this.state.organization_id.value];
        if (data.organization_role === 'admin' && this.state.organization_permissions.length > 0) {
            this.state.organization_permissions.forEach(organization => {
                if (organization && organization !== undefined && organization.value !== null) {
                    organizationCode.push(organization.value);
                }
            });
        }
        formData.append('organization_permissions', JSON.stringify(organizationCode));

        if (curriculum) {
            formData.append('curriculum', curriculum);
        }

        this.setState({working: true});

        let request;
        let isEdit = !!this.id;
        if (this.id) {
            request = apiRequest("/users/" + this.id, {data: formData, method: 'PUT'})
        } else {
            formData.append('role', this.role);
            request = apiRequest("/users", {data: formData, method: 'POST'});
        }

        request.then(response => {
            if (!isEdit) {
                this.clearForm();
            }
            this.props.notify({type: 'success', message: window.gettext('Usuário armazenado com sucesso')});
            if (this.isSetup) {
                this.setupRedirect();
            }
        })
            .catch(err => {
                this.setState({errors: err.data.errors});
            })
            .finally(
                response => this.setState({working: false})
            );
    }

    attachCurriculum = (e) => {
        this.setState({curriculum: e.target.files[0]});
    }

    setupRedirect() {
        Wizard.afterUserProfile().then(redirect => {
            this.setState({redirect})
        });
    }

    setExtendedData = (data) => {
        this.setState({extended_data: data});
    }

    renderCompanyAllowed() {

        if (this.state.organization_role.value === 'admin') {

            return (
                <>
                    <Row>
                        <Label sm="2">
                            {window.gettext('Pode acessar:')}
                        </Label>
                        <Col>
                            <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Permissões de Acesso"
                                name="multipleSelect"
                                closeMenuOnSelect={false}
                                isMulti
                                value={this.state.organization_permissions}
                                onChange={value =>
                                    this.setState({organization_permissions: value})
                                }
                                options={[
                                    {
                                        value: "",
                                        label: "Selecione...",
                                        isDisabled: true
                                    },
                                    ...this.state.companyOptions
                                ]}
                            />
                        </Col>
                    </Row>
                </>
            );
        }
    }

    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }

        return (
            <>
                <div className="new-content">
                    <Row>
                        <Col md="12">
                            <Form action="" className="form" method="POST"
                                  onSubmit={this.handleSubmit}
                            >
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{this.formTitle}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('Nomes')}
                                            </Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.name}>
                                                    <Input type="text" name="name" value={this.state.name}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="name" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('E-mail')}
                                            </Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.email}>
                                                    <Input type="text" name="email" value={this.state.email}
                                                           disabled={this.isUpdate}
                                                           readOnly={this.isUpdate}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="email" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('Senha')}
                                            </Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.password}>
                                                    <Input type="text" autoComplete="off" name="password"
                                                           value={this.state.password}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="password" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('CPF')}
                                            </Label>
                                            <Col sm="6">
                                                <FormGroup className={hasError.cpf}>
                                                    <InputMask mask="999.999.999-99" value={this.state.cpf}
                                                               onChange={this.handleChange} name="cpf"
                                                               disabled={this.state.is_foreigner}
                                                               style={{backgroundColor: this.state.is_foreigner ? '#E3E3E3' : ''}}
                                                    >
                                                        {(inputProps) => <Input type="text" {...inputProps}/>}
                                                    </InputMask>
                                                    <InputError name="cpf" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" checked={this.state.is_foreigner}
                                                               onChange={this.handleTesteChange} name='is_foreigner'
                                                        />
                                                        <span className="form-check-sign"/>
                                                        {window.gettext('Eu estou fora do Brasil')}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('CEP')}
                                            </Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.cep}>
                                                    <InputMask mask="99999-999" value={this.state.cep}
                                                               onChange={this.handleCep} name="cep"
                                                               disabled={this.state.is_foreigner}
                                                               style={{backgroundColor: this.state.is_foreigner ? '#E3E3E3' : ''}}
                                                    >
                                                        {(inputProps) => <Input type="text" {...inputProps} />}
                                                    </InputMask>
                                                    <InputError name="cep" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('Endereço')}
                                            </Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.address}>
                                                    <Input type="text" name="address" value={this.state.address}
                                                           onChange={this.handleChange}
                                                           disabled={this.state.is_foreigner}
                                                    />
                                                    <InputError name="address" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('Cidade')}
                                            </Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.city}>
                                                    <Input type="text" name="city" value={this.state.city}
                                                           onChange={this.handleChange}
                                                           disabled={this.state.is_foreigner}
                                                    />
                                                    <InputError name="city" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('Estado')}
                                            </Label>
                                            <Col sm="4">
                                                <FormGroup className={hasError.state}>
                                                    <Select
                                                        isDisabled={this.state.is_foreigner}
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="state"
                                                        value={this.state.state}
                                                        onChange={value =>
                                                            this.handleChange("state", value)
                                                        }
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: window.gettext("Selecione um estado"),
                                                                isDisabled: true
                                                            },
                                                            ...this.statesDef
                                                        ]}
                                                        placeholder={window.gettext("Selecione um estado")}
                                                    />
                                                    <InputError name="state" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('Telefone')}
                                            </Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.phone}>
                                                    <InputMask mask="(99) 9999tt999?"
                                                               formatChars={{
                                                                   "9": "[0-9]",
                                                                   "t": "[0-9\-]",
                                                                   "?": "[0-9 ]"
                                                               }}
                                                               maskChar={null}
                                                               value={this.state.phone}
                                                               onChange={this.handleChange} name="phone"
                                                    >
                                                        {(inputProps) => <Input type="text" {...inputProps} />}
                                                    </InputMask>
                                                    <InputError name="phone" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('Celular')}
                                            </Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.mobile}>
                                                    <InputMask mask="(99) 9999tt999?"
                                                               formatChars={{
                                                                   "9": "[0-9]",
                                                                   "t": "[0-9\-]",
                                                                   "?": "[0-9 ]"
                                                               }}
                                                               maskChar={null}
                                                               value={this.state.mobile}
                                                               onChange={this.handleChange} name="mobile"
                                                    >
                                                        {(inputProps) => <Input type="text" {...inputProps} />}
                                                    </InputMask>
                                                    <InputError name="mobile" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('LinkedIn')}
                                            </Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.linkedin}>
                                                    <Input type="text" name="linkedin" value={this.state.linkedin}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="linkedin" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('Idioma da Aplicação')}
                                            </Label>
                                            <Col sm="4">
                                                <FormGroup className={hasError.state}>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="language"
                                                        value={this.state.language}
                                                        onChange={value =>
                                                            this.handleChange("language", value)
                                                        }
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: window.gettext("Selecione um idioma"),
                                                                isDisabled: true
                                                            },
                                                            ...this.languages
                                                        ]}
                                                        placeholder={window.gettext("Selecione um idioma")}
                                                    />
                                                    <InputError name="language" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('Fuso Horário')}
                                            </Label>
                                            <Col sm="4">
                                                <FormGroup className={hasError.state}>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="timezone"
                                                        value={this.state.timezone}
                                                        onChange={value =>
                                                            this.handleChange("timezone", value)
                                                        }
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: window.gettext("Selecione um fuso horário"),
                                                                isDisabled: true
                                                            },
                                                            ...this.timeZones
                                                        ]}
                                                        placeholder={window.gettext("Selecione um fuso horário")}
                                                    />
                                                    <InputError name="language" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {this.role === 'specialist' &&
                                        <Row>
                                            <Label sm="2">Bio</Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.bio}>
                                                    <Input type="textarea" name="bio" value={this.state.bio}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="bio" errors={this.state.errors}/>
                                                    <FormText color="default" tag="span">
                                                        {window.gettext('Biografia resumida')}
                                                    </FormText>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        }


                                        {this.state.loggedUserData.role === 'super_admin' && (this.state.role === 'student' || this.role === 'student') &&
                                        <React.Fragment>
                                            <Row>
                                                <Label sm="2">
                                                    {window.gettext('Papel do usuário')}
                                                </Label>
                                                <Col sm="4">
                                                    <FormGroup className={hasError.organization_role}>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="organization_role"
                                                            value={this.state.organization_role}
                                                            onChange={async value => {
                                                                await this.handleChange("organization_role", value);
                                                                this.setState({
                                                                    organization_id: null,
                                                                    companiesAvaliable: [],
                                                                    organization_permissions: []
                                                                });
                                                                this.collectOrganizations();
                                                            }}
                                                            options={[
                                                                {
                                                                    value: "",
                                                                    label: window.gettext("Selecione um papel"),
                                                                    isDisabled: true
                                                                },
                                                                ...this.orgUserRoles
                                                            ]}
                                                            placeholder={window.gettext("Selecione um papel de usuário")}
                                                        />
                                                        <InputError name="organization_role"
                                                                    errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2">
                                                    {window.gettext('Empresa')}
                                                </Label>
                                                <Col sm="10">
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="organization_id"
                                                            value={this.state.organization_id}
                                                            onChange={value => {
                                                                this.handleChange("organization_id", value);
                                                                this.loadOwnerOrganizations(value.value);
                                                            }}
                                                            options={[
                                                                {
                                                                    value: "",
                                                                    label: window.gettext("Selecione uma empresa"),
                                                                },
                                                                ...this.state.companiesAvaliable
                                                            ]}
                                                            placeholder={window.gettext("Selecione uma empresa")}
                                                        />
                                                        <InputError name="organization_id"
                                                                    errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {this.renderCompanyAllowed()}

                                        </React.Fragment>
                                        }
                                        {(this.role === 'specialist' || this.state.loggedUserData.role === 'specialist') &&
                                        <ExtendedDataFields type="specialist" data={this.state.extended_data}
                                                            fillFields={this.setExtendedData}/>
                                        }
                                    </CardBody>
                                    <CardFooter>
                                        <Row>
                                            <Col md="2"/>
                                            <Col md="10">
                                                <Button className="btn" color="primary" type="submit"
                                                        disabled={this.state.working}
                                                >
                                                    {window.gettext('Salvar')}
                                                </Button>{' '}
                                                {this.role === 'specialist' && this.state.loggedUserData.role === 'super_admin'
                                                && this.id && <TranslationPanel type={'user'} code={this.id}/>}
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(UserForm);
