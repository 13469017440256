import React from "react";

// reactstrap components
import {Badge, CardBody, CardHeader, Col, Row} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import CardShowContent from "./CardShowContent";

class OCBOrganization extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.score = 17;
        this.topScore = 20;
    }

    get total() {
        if (!this.props.data || !this.props.data.total_cut_point_applied) {
            return 0;
        }
        return this.props.data.total_cut_point_applied || 0;
    }

    renderHigh() {
        return (
            <>
                {window.gettext("Você além de transmitir e enfatizar aspectos positivos de sua organização, busca cumprir tarefas não prescritas ou solicitadas formalmente por superiores ou colegas. Você percebe que pode atua de forma proativa e voluntária para meelhorar a segurança ou melhorar processos dentro da organização.")}
            </>
        );
    }

    renderLower() {
        return (
            <>
                {window.gettext("Você se esforça em transmitir uma imagem positiva e promover sua organização em ambientes externos ao trabalho. Você procura ressaltar o que há de melhor em na oganização em que você trabalha.")}
            </>
        )
    }

    isHigher() {
        if (this.total) {
            return this.total >= this.score;
        }
        return false;
    }

    getDescription() {
        if (this.isHigher()) {
            return this.renderHigh();
        }
        return this.renderLower();
    }

    render() {

        return (
            <>
                <div className="content ancora-container">
                    <Row>
                        <Col md={12}>
                            <CardShowContent
                                isHigher={this.isHigher()}
                                topScore={this.topScore}
                                description={this.getDescription()}
                                total={this.total}
                                helper={window.gettext('Cidadania organizacional (individual e em relação à organização): Ações não requeridas e prescritas que visam a beneficiar a organização e as pessoas que a compõe. São ações proativas e voluntárias, tanto a nível das relações como de tarefas.')}
                                title={window.gettext('OCB Organização')}
                            />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(OCBOrganization);
