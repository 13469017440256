import React from "react";

// reactstrap components
import {Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Label, Row, Table,} from "reactstrap";
import withNotify from "../../../lib/NotificationWrapper";
import {getUserData} from "../../../lib/Auth";
import apiRequest from "../../../lib/Api";
import AsyncSelect from "react-select/lib/Async";
import InputError from "../../components/InputError";
import Select from "react-select";
import momentFormat from "../../../lib/Locale";
import moment from "moment-timezone";
import SpecialistDiaryAnswer from "./SpecialistDiaryAnswer";
import TasksAnswer from "./TasksAnswer";

class SpecialistDiary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: getUserData(),
      userSelected: null,
      programsUserSelected: [],
      programSelected: null,
      purchase_user_id: null,
      openModal: false,
      appointment: null,
      appointments: [],
      currentGoalsAndGainsStart: null,
      currentGoalsAndGainsEnd: null,
      taskSelected: null,
    }
  }
  componentDidMount() {
    moment.lang(this.state.user.language);
    moment.tz.setDefault(this.state.user.timezone)
  }

  loadUsers = searchTerm => {

    let params = {
      searchRole: 'student',
      search: searchTerm,
      onlyAppointments: 1
    };
    return apiRequest("/users", {data: params, method: 'GET'})
        .then(response => {
          return response.data.data.map(user => ({value: user.id, label: user.name}));
        });
  }

  clear = () => {
    this.setState({
      programsUserSelected: [],
      programSelected: null,
      openModal: false,
      purchase_user_id: null,
      appointment: null,
      appointments: [],
      currentGoalsAndGainsStart: null,
      currentGoalsAndGainsEnd: null,
      openGoalsAndGainsModal: false,
      currentGoalsAndGainsStartAppointment: null,
    });
  }

  onUserChange = (value) => {
    this.clear();
    this.setState({userSelected: value}, () => {
      this.loadPrograms();
    });
  }


  onSelectProgram = (program) => {
    this.setState({programSelected: program, purchase_user_id: program.value}, () => {
      this.loadServices(program.value);
    });
  }

  loadPrograms() {
    return apiRequest(`/users/${this.state.userSelected.value}/purchases?include=status`, {method: "GET"}).then(response => {
      this.setState({
        programsUserSelected: response.data.purchases.map(purchase => {
          return {
            label: purchase.product_name,
            value: purchase.id,
            begin_date: purchase.begin_date,
            user: purchase.purchase.user,
          }
        }),
      });
    });
  }

  loadServices(serviceId)
  {
    return apiRequest(`/programs/${serviceId}/services?type=specialist_call`, {method:'GET'}).then(
        success => {
          this.setState({appointments: success.data.data}, () => {this.loadGoalsAndGains()});
        }
    );
  }

  loadGoalsAndGains = () => {
    let appointmentsId = [];
    this.state.appointments.forEach(appointment => {
      appointmentsId.push(appointment.id);
    })

    if (appointmentsId.length === 0) {
      return;
    }

    apiRequest(`/tasks/goals-and-gains?appointments=${appointmentsId.join(',')}`, {method:'GET'}).then(
      response => {
        this.setState({
          currentGoalsAndGainsStart: response.data.data.task_start,
          currentGoalsAndGainsEnd: response.data.data.task_end,
        })
      }
    );
  }

  renderFilterUser = (hasError) => {
    return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">{window.gettext('Diário da Especialista')}</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Label md={2}>
                {window.gettext('Usuário')}
              </Label>
              <Col md={10}>
                <FormGroup className={hasError.user_id}>
                  <AsyncSelect
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="user_id"
                      value={this.state.user_id}
                      onChange={this.onUserChange}
                      defaultOptions
                      loadOptions={this.loadUsers}
                      placeholder={window.gettext("Selecione um usuário")}
                  />
                  <InputError name="user_id" errors={this.state.errors}/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label md={2}>
                {window.gettext('Programa')}
              </Label>
              <Col md={10}>
                <FormGroup className={hasError.user_id}>
                  <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="purchase_user_id"
                      onChange={this.onSelectProgram}
                      value={this.state.programSelected}
                      options={this.state.programsUserSelected}
                      placeholder={window.gettext("Selecione um programa")}
                  />
                  <InputError name="purchase_user_id" errors={this.state.errors}/>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
    );
  }

  openModal (appointmentSelected) {
    this.setState({openModal: true, appointment: appointmentSelected})
  }


  renderUserData = () => {
    let user = this.state.programSelected.user;
    let program = this.state.programSelected || ''
    let date = program.begin_date;
    if (date !== '') {
      date = moment(program.begin_date).format(momentFormat.DATE_PTBR)
    }
    return (
        <>
          <Row>
            <Col md={12}><b>{window.gettext('Empresa:')}</b> {user.organization.name || ''}</Col>
            <Col md={12}><b>{window.gettext('Programa:')}</b> {program.label}</Col>
            <Col md={12}><b>{window.gettext('Data de início:')}</b> {date}</Col>
          </Row>
          { this.renderButtonGoalsAndGains() }
          { this.renderButtonGoalsAndGainsEnd() }
        </>
    )
  }

  renderButtonGoalsAndGains = () => {
    if (this.state.currentGoalsAndGainsStart) {
      return <>
        <Row>
          <Col className="text-right">
            <Button
              color="info"
              id={this.state.currentGoalsAndGainsStart.id}
              size="sm"
              type="button"
              onClick={ this.openGainsModal }
            >
              Objetivos - Encontro Inicial
            </Button>
          </Col>
        </Row>
      </>
    }
  }

  renderButtonGoalsAndGainsEnd = () => {
    if (this.state.currentGoalsAndGainsEnd) {
      return <>
        <Row>
          <Col className="text-right">
            <Button
              color="info"
              id={this.state.currentGoalsAndGainsEnd.id}
              size="sm"
              type="button"
              onClick={ this.openGoalsModal }
            >
              Ganhos - Encontro Final
            </Button>
          </Col>
        </Row>
      </>
    }
  }

  openGainsModal = () => {
    this.setState({taskSelected: this.state.currentGoalsAndGainsStart})
  }

  openGoalsModal = () => {
    this.setState({taskSelected: this.state.currentGoalsAndGainsEnd})
  }

  renderServices = () => {

    if (!this.state.programSelected || !this.state.appointments.length === 0) {
      return <></>;
    }

    return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">{window.gettext('Encontros de') + ' ' + this.state.programSelected.label}</CardTitle>
          </CardHeader>
          <CardBody>
            {this.renderUserData()}
            <Table responsive>
              <thead className="text-primary">
              <tr>
                <th className="text-center">#</th>
                <th>{window.gettext('Serviço')}</th>
                <th>{window.gettext('Data')}</th>
                <th className="text-center">{window.gettext('Ação')}</th>
              </tr>
              </thead>
              <tbody>
              {this.state.appointments.map((appointment, index)=> {
                index++;
                return (
                    <tr key={index}>
                      <td className="text-center">{index}</td>
                      <td>{appointment.service_title}</td>
                      <td>{moment(appointment.start_date).format(momentFormat.DATE_PTBR +' '+momentFormat.HOUR)}</td>
                      <td className={'text-center'}>
                        <Button
                            className="btn-icon"
                            color="info"
                            id={appointment.id}
                            size="md"
                            type="button"
                            onClick={() => {this.openModal(appointment)}}
                        >
                          {appointment.data.length === 0 ? <i className="nc-icon nc-simple-delete" /> : <i className="nc-icon nc-check-2" />}
                        </Button>
                      </td>
                    </tr>
                );
              })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
    );
  }

  render() {
    let hasError = InputError.convertToHasClass(this.state.errors);

    return (
        <>
          <SpecialistDiaryAnswer
              openModal={this.state.openModal}
              appointment={this.state.appointment}
              program={this.state.purchase_user_id}
              onClose={() => {this.setState({appointment: null, openModal: false});}}
              onSave={() => {
                this.setState({appointment: null, openModal: false});
                this.loadServices(this.state.purchase_user_id)
              }}
          />

          <TasksAnswer
            task={this.state.taskSelected}
            disabledForm={true}
            onClose={() => {
              this.setState({taskSelected: null})
            }}
          />

          <div className="content">
            <Row>
              <Col md={12}>
                {this.renderFilterUser(hasError)}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {this.renderServices()}
              </Col>
            </Row>
          </div>
        </>
    );
  }
}

export default withNotify(SpecialistDiary);
