import React from "react";
import {Redirect} from "react-router-dom";
import queryString from 'query-string';
import enviroment from "../../services/enviroment";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";


import apiRequest from "lib/Api";
import {
  saveAuth,
  getUserData,
  getRedirectUrl,
  clearRedirect,
  shouldCompleteProfile
} from "lib/Auth";
import InputError from "views/components/InputError";
import SocialLogin from "views/components/SocialLogin";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      working: false,
      errors: {},
      loggedIn: !!localStorage.getItem('accessToken'),
      completeProfile: false,
      styleLogin: {
        backgroundImage: `url(${require("assets/img/bg/pattern.png")})`
      },
    }

  }

  componentDidMount() {
    document.body.classList.toggle("login-page");

    if (enviroment.isNewUserInterface()) {
      this.setState({styleLogin: {backgroundColor: "#1E2B4E"}});
    }
    if (enviroment.isLumiUserInterface()) {
      this.setState({styleLogin: {backgroundImage: `url(${require("assets/img/bg/lumi-fundo-de-tela-azul.png")})`}});
    }

  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  handleChange = (e) => {
    let {name, value} = e.target;
    this.setState({[name]: value});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let {email, password} = this.state;
    let data = {email, password};

    this.setState({working: true});

    apiRequest("/auth/login", {data})
      .then(response => {
        response.data.reload = true;
        saveAuth(response.data);
        return this.redirectAfterLogin();
      })
      .catch(err => {
        this.setState({errors: {password: window.gettext('E-mail ou senha inválidos')}});
      })
      .finally(
        response => this.setState({working: false})
      );
  }

  redirectAfterLogin() {
    let user = getUserData();

    if (user.role === 'super_admin' || user.role === 'specialist') {
      this.setState({loggedIn: true});
      return true;
    }
    if (!user.organization || user.adminOrganization) {
      this.setState({loggedIn: true});
      return true;
    }

    return shouldCompleteProfile()
      .then(result => {
        if (result) {
          this.setState({completeProfile: user.id});
        }
        else {
          this.setState({loggedIn: true});
        }
      });
  }

  getRedirectUrl() {
    let qs = this.props.location.search || '';
    if (qs) {
      let params = queryString.parse(qs);
      if (params.redirect == 1) {
        return getRedirectUrl();
      }
    }
    return '/admin/dashboard';
  }

  render() {
    if (this.state.completeProfile) {
      return <Redirect to={"/setup/users/" + this.state.completeProfile + "/edit"} />;
    }
    if (this.state.loggedIn) {
      const redirectTo = this.getRedirectUrl();
      clearRedirect();
      return <Redirect to={redirectTo} />;
    }

    let hasError = InputError.convertToHasClass(this.state.errors);

    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card className="card-login text-center">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">{window.gettext('Login')}</h3>
                      <div className="social">
                        {/*<SocialLogin />*/}
                        {/*<p className="card-description">{window.gettext('ou preencha abaixo')}</p>*/}
                      </div>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <FormGroup className={hasError.email}>
                      <InputGroup style={{marginBottom:0}}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder={window.gettext('Email...')} type="text"
                          name="email"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputError name="email" errors={this.state.errors} />
                    </FormGroup>
                    <FormGroup className={hasError.password}>
                      <InputGroup style={{marginBottom:0}}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={window.gettext('Senha')}
                          type="password"
                          autoComplete="off"
                          name="password"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputError name="password" errors={this.state.errors} />
                    </FormGroup>
                    <br />
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="warning"
                      disabled={this.state.working}
                    >
                      {window.gettext('Iniciar')}
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={this.state.styleLogin}
        />
      </div>
    );
  }
}

export default Login;
