import React from "react";

// reactstrap components
import {Badge, CardBody, CardHeader, Col, Row} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import CardShowContent from "./CardShowContent";

class Resilience extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.score = 20;
        this.topScore = 24;
    }

    get total() {
        if (!this.props.data || !this.props.data.total_cut_point_applied) {
            return 0;
        }
        return this.props.data.total_cut_point_applied || 0;
    }

    renderHigh() {
        return (
            <>
                {window.gettext("Você possui uma grande capacidade de lidar com adversidades. Além de reunir recursos internos e externos para lidar com situações adversas, você consegue manter o autocontrole nessas situações. Além disso, você consegue se recuperar rapidamente de eventos negativos, retomando seu nível usual de motivação. ")}
            </>
        );
    }

    renderLower() {
        return (
            <>
                {window.gettext("Você possui boas habilidades para lidar com as adversidades. Encontra força para lidar com desafios, obstáculos e eventos adversos focando em seus objetivos e agrupando recursos externos para lidar com eles. Contudo, você ainda pode demorar no que diz respeito ao autocontrole em situações adversas e na sua recuperação quando algo não acontece de forma esperada.")}
            </>
        )
    }

    isHigher() {
        if (this.total) {
            return this.total >= this.score;
        }
        return false;
    }

    getDescription() {
        if (this.isHigher()) {
            return this.renderHigh();
        }
        return this.renderLower();
    }

    render() {

        return (
            <>
                <div className="content ancora-container">
                    <Row>
                        <Col md={12}>
                            <CardShowContent
                                isHigher={this.isHigher()}
                                topScore={this.topScore}
                                description={this.getDescription()}
                                total={this.total}
                                helper={window.gettext('Capacidade de ativar recursos internos e externos para minimizar os efeitos de eventos adversos e estressantes.')}
                                title={window.gettext('Resiliência')}
                            />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(Resilience);
