import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import InputError from "../../components/InputError";

class ProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            name: '',
            subject: '',
            body: '',
            product_service_id: '',
            reminder: '',
            working: false,
            errors: {},
            productServices: [],
        }
    }

    componentDidMount() {
        this.fetchOptions();
    }

    fetchOptions() {
        return apiRequest("/email-templates/names", {method: "GET"})
            .then(response => {
                let options = response.data;
                options = options.map(opt => ({value: opt.name, label: opt.description}));
                this.setState({options});
            });
    }

    fetchItem() {
        let {name, product_service_id} = this.state;
        if (!name) {
            return;
        }

        let data = {};
        if (product_service_id && product_service_id.value != -1) {
            data.product_service_id = product_service_id.value;
        }

        return apiRequest("/email-templates/" + name.value, {method: "GET", data})
            .then(response => {
                if (response.status == 200) {
                    let {data} = response.data;
                    let template = {
                        name: this.state.options.find(item => item.value == data.name),
                        subject: data.subject,
                        body: data.body,
                        reminder: data.reminder || ''
                    };
                    if (data.productService) {
                        template.product_service_id = {
                            value: data.productService.id,
                            label: this.getProductServiceLabel(data.productService)
                        };
                    }
                    this.setState(template);
                } else {
                    this.setState({subject: '', body: '', reminder: ''});
                }
            });
    }

    loadProductServices = searchTerm => {
        let data = {
            sort_by: 'service_id',
            search: searchTerm
        };
        return apiRequest("/product-services", {data, method: 'GET'})
            .then(response => {
                let {data} = response.data;
                let options = data.map(ps => {
                    return {value: ps.id, label: this.getProductServiceLabel(ps)};
                });
                options.unshift({value: -1, label: window.gettext('Nenhum produto')});
                return options;
            });
    }

    getProductServiceLabel(ps) {
        return ps.service.displayName + ' - ' + ps.product.name;
    }

    clearForm() {
        this.setState({
            name: '',
            subject: '',
            body: '',
            product_service_id: '',
            reminder: '',
            errors: {}
        });
    }

    handleChange = (e) => {
        let {name, value} = e.target;

        this.setState({
            [name]: value
        });

        if (this.state.errors[name]) {
            this.setState({errors: {...this.state.errors, [name]: ''}});
        }
    }

    handleSelect = (option) => {
        this.setState({name: option}, () => {
            this.fetchItem();
        });

        if (this.state.errors.name) {
            this.setState({errors: {...this.state.errors, name: ''}});
        }
    }

    handleProductChange = (option) => {
        this.setState({product_service_id: option}, () => {
            this.fetchItem();
        });

        if (this.state.errors.product_service_id) {
            this.setState({errors: {...this.state.errors, product_service_id: ''}});
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let {name, subject, body, product_service_id, reminder} = this.state;
        if (!name) {
            return;
        }

        let data = {
            name: name.value,
            description: name.label,
            subject,
            body,
            reminder
        };

        if (product_service_id && product_service_id.value != -1) {
            data.product_service_id = product_service_id.value;
            data.description += ' ' + product_service_id.label;
        }

        this.setState({working: true});

        let request = apiRequest("/email-templates", {data, method: 'POST'})
            .then(response => {
                this.clearForm();
                this.props.notify({type: 'success', message: window.gettext('Template armazenado com sucesso')});
            })
            .catch(err => {
                this.setState({errors: err.data.errors});
            })
            .finally(
                response => this.setState({working: false})
            );
    }

    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Form action="" className="form" method="POST"
                                  onSubmit={this.handleSubmit}
                            >
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{window.gettext('Template de e-mail')}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <Row>
                                            <Label sm="2">{window.gettext('Nome do template')}</Label>
                                            <Col sm="6">
                                                <FormGroup className={hasError.name}>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="type"
                                                        value={this.state.name}
                                                        onChange={this.handleSelect}
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: window.gettext("Selecione um template"),
                                                                isDisabled: true
                                                            },
                                                            ...this.state.options
                                                        ]}
                                                        placeholder={window.gettext("Selecione um template")}
                                                    />
                                                    <InputError name="name" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Label sm="2">{window.gettext('Produto')}</Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.product_service_id}>
                                                    <AsyncSelect
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="product_service_id"
                                                        value={this.state.product_service_id}
                                                        onChange={this.handleProductChange}
                                                        defaultOptions
                                                        loadOptions={this.loadProductServices}
                                                        placeholder={window.gettext("Selecione um produto (opcional)")}
                                                    />
                                                    <InputError name="product_service_id" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Label sm="2">{window.gettext('Assunto do e-mail')}</Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.subject}>
                                                    <Input type="text" name="subject" value={this.state.subject}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="subject" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Label sm="2">{window.gettext('Corpo do e-mail')}</Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.body}>
                                                    <Input type="textarea" name="body" value={this.state.body}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="body" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Label sm="2">{window.gettext('Tempo do lembrete')}</Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.reminder}>
                                                    <Input type="number" name="reminder" value={this.state.reminder}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="reminder" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                    <CardFooter>
                                        <Row>
                                            <Col md="2"/>
                                            <Col md="10">
                                                <Button className="btn-round" color="info" type="submit"
                                                        disabled={this.state.working}
                                                >
                                                    {window.gettext('Salvar')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(ProductForm);
