import React from "react";
import AsyncSelect from "react-select/lib/Async";
import Select from "react-select";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Input,
  FormGroup,
  Form,
  Row,
  Col
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import InputError from "../../components/InputError";

const FINISHED = 'finished';
const PENDING = 'pending';
const serviceStatusOptions = [
  {label: window.gettext('Não-finalizado'), value: PENDING},
  {label: window.gettext('Finalizado'), value: FINISHED}
];

class UserServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      purchaseServices: [],
      selectedPurchaseService: null,
      loadingServices: false,
      serviceStatus: null,
      working: false,
      errors: {}
    }
  }

  clearForm() {
    this.setState({
      user_id: '',
      purchaseServices: [],
      selectedPurchaseService: null,
      loadingServices: false,
      errors: {}
    });
  }

  loadUsers = searchTerm => {
    let params = {
      searchRole: 'student',
      search: searchTerm,
      onlyAppointments: 1
    };
    return apiRequest("/users", {data: params, method: 'GET'})
      .then(response => {
        return response.data.data.map(user => ({value: user.id, label: user.name}));
      });
  }

  loadEnabledServices() {
    let {user_id} = this.state;
    let data = {user_id: user_id.value};

    if (!user_id) {
      return;
    }

    this.setState({loadingServices: true});

    return apiRequest("/purchase-services", {data, method: "GET"}).then(response => {
      this.setState({
        purchaseServices: response.data.data,
        loadingServices: false
      });
    });
  }

  onUserChange = value => {
    this.setState({user_id: value}, () => {
      this.loadEnabledServices();
    });
  }

  onServiceChange = event => {
    let purchaseServiceId = parseInt(event.target.value);
    let purchaseService = this.state.purchaseServices.find(ps => ps.id === purchaseServiceId);
    let serviceStatus = purchaseService.status !== 1 ? PENDING : FINISHED;
    serviceStatus = serviceStatusOptions.find(option => option.value === serviceStatus);
    this.setState({selectedPurchaseService: purchaseServiceId, serviceStatus});
  }

  onStatusChange = value => {
    this.setState({serviceStatus: value});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let {user_id: userId, selectedPurchaseService, serviceStatus} = this.state;
    if (!userId || !selectedPurchaseService || !serviceStatus) {
      console.warn('Missing some field');
      return;
    }
    userId = userId.value;
    serviceStatus = serviceStatus.value;
    selectedPurchaseService = this.state.purchaseServices.find(ps => ps.id === selectedPurchaseService);

    let data = {
      usage: serviceStatus === FINISHED ? selectedPurchaseService.quota : 0
    };

    this.setState({working: true});

    let request = apiRequest("/purchase-services/" + selectedPurchaseService.id, {data, method: 'PATCH'});
    request.then(response => {
        this.clearForm();
        this.props.notify({type: 'success', message: window.gettext('Status atualizado com sucesso')});
      })
      .catch(err => {
        this.props.notify(
            {type: 'error',
              message: window.gettext('Não foi possível atualizar o status, tente mais tarde.')
            });
      })
      .finally(
        response => this.setState({working: false})
      );
  }

  render() {
    let hasError = InputError.convertToHasClass(this.state.errors);

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      {window.gettext('Serviços do Usuário')}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Label sm="2">
                        {window.gettext('Usuário')}
                      </Label>
                      <Col sm="10">
                        <FormGroup className={hasError.user_id}>
                          <AsyncSelect
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="user_id"
                            value={this.state.user_id}
                            onChange={this.onUserChange}
                            defaultOptions
                            loadOptions={this.loadUsers}
                            placeholder={window.gettext("Selecione um usuário")}
                          />
                          <InputError name="user_id" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">
                        {window.gettext("Serviços habilitados")}
                      </Label>
                      <Col className="checkbox-radios" sm="10">
                        {this.state.loadingServices && (
                          <span>
                        {window.gettext("Carregando serviços...")}</span>
                        )}
                        {!this.state.loadingServices && this.state.purchaseServices.map(purchaseService => (
                          <div className="form-check-radio" key={'purchase-service-' + purchaseService.id}>
                            <Label check>
                              <Input type="radio"
                                checked={this.state.selectedPurchaseService === purchaseService.id}
                                value={purchaseService.id}
                                onChange={this.onServiceChange}
                                name="purchase-service"
                              />
                              <span className="form-check-sign" />
                              {purchaseService.productService.product.name+' - '+purchaseService.name}
                            </Label>
                          </div>
                        ))}
                      </Col>
                    </Row>

                    {this.state.selectedPurchaseService &&
                    <Row>
                      <Label sm="2">
                        {window.gettext('Status')}
                      </Label>
                      <Col sm="4">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="status"
                            value={this.state.serviceStatus}
                            onChange={this.onStatusChange}
                            options={serviceStatusOptions}
                            placeholder={window.gettext("Selecione um status")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    }

                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md="2" />
                      <Col md="10">
                        <Button className="btn-round" color="info" type="submit"
                          disabled={this.state.working}
                        >
                          {window.gettext('Salvar')}
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withNotify(UserServices);
