import enUS from '../i18n/en_US/message.en.json'
import i18n from 'gettext.js';
import {getUserData} from "../lib/Auth";

class Translator {

    constructor() {
        this.gt = new i18n();
        this.gt.loadJSON(enUS, 'messages');
        let lang = this.getLang();
        this.gt.setLocale(lang);

    }
    setLocale(locate) {
        this.gt.setLocale(locate);
    }

    gettext = function () {
        return this.gt.gettext(...arguments);
    }
    getLang() {
        let userData = getUserData();
        if (userData && (userData.language !== '' && userData.language !== undefined) && userData.language !== null) {
            return userData.language;
        }
        if (navigator.languages !== undefined) {
            return navigator.languages[0].replace('-','_');
        }
        return 'pt_BR'
    }
}
let translator = new Translator();
window.translator = translator;
window.gettext = translator.gettext.bind(translator);

export default translator;
