import React from "react";

// reactstrap components
import {Badge, CardBody, CardHeader, Col, Row} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import CardShowContent from "./CardShowContent";

class Conscientiousness extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.score = 21;
        this.topScore = 24;
    }

    get total() {
        if (!this.props.data || !this.props.data.total_cut_point_applied) {
            return 0;
        }
        return this.props.data.total_cut_point_applied || 0;
    }

    renderHigh() {
        return (
            <>
                {window.gettext("Você possui um alto senso de compromisso e responsabilidade. Você prefere terminar tarefas antes de assumir novas tarefas em seu trabalho. Você consegue manter o foco em suas tarefas, controlando distratores, e assim manter a sua produtividade no trabalho.")}
            </>
        );
    }

    renderLower() {
        return (
            <>
                {window.gettext("Você possui um grande senso de compromisso com seus colegas e com suas tarefas. Você é cuidadoso em revisar tarefas e entregas para dirimir erros e evitar problemas futuros. Contudo, você pode focar em algumas habilidades importante relacionadas ao controle de distratores no trabalho e relativos ao foco. É desejável que uma tarefa estabelecida seja finalizada, antes de assumir novas tarefas.")}
            </>
        )
    }

    isHigher() {
        if (this.total) {
            return this.total >= this.score;
        }
        return false;
    }

    getDescription() {
        if (this.isHigher()) {
            return this.renderHigh();
        }
        return this.renderLower();
    }

    render() {

        return (
            <>
                <div className="content ancora-container">
                    <Row>
                        <Col md={12}>
                            <CardShowContent
                                isHigher={this.isHigher()}
                                topScore={this.topScore}
                                description={this.getDescription()}
                                total={this.total}
                                helper={window.gettext('É um dos traços de personalidade do modelo dos cinco grandes fatores (big five). Está relacionado a capacidade de ser cuidadoso ou diligente. Implica o desejo de executar bem uma tarefa e levar a sério as obrigações para com os outros.')}
                                title={window.gettext('Conscienciosidade')}
                            />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(Conscientiousness);
