import React from "react";
import withNotify from "../../lib/NotificationWrapper";
import apiRequest from "../../lib/Api";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import InputError from "../../views/components/InputError";
import Select from "react-select";


// reactstrap components

class ExtendDataFields extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: props.type,
            extendedDataId: props.extended_data_id,
            data: {},
            errors: {},
            sections: [{fields: []}],
            values: {},
            plainValue: {}
        }
    }

    get isDisabled() {
        if (this.props.isDisabled) {
            return this.props.isDisabled;
        }
        return false;
    }

    setFieldsValues(field, values) {

        if (!values.values) {
            return ;
        }
        let stateValues = this.state.values;
        if (!values.values[field.property]) {
            return ;
        }
        switch (field.type) {
            case 'chips':
                let items = [];
                for (let value of values.values[field.property].values) {

                    let item = {
                        value: value,
                        label: value,
                        isDisabled: false
                    }
                    items.push(item);
               }


                stateValues[field.property] = items;
                break;
            case 'list':
                let item = {
                    value: values.values[field.property].values,
                    label: values.values[field.property].values,
                    isDisabled: false
                }
                stateValues[field.property] = item;
                break;
            default:
                stateValues[field.property] = values.values[field.property].values;
                break;
        }
        this.setState({values: stateValues});
    }

    async componentDidMount() {

        let route = "/extended-data/place/" + this.state.type;
        if (this.state.extendedDataId) {
            route = `/extended-data/${this.state.extendedDataId}`;
        }
        await apiRequest(route, {method: 'GET'})
            .then(response => {
                let {data} = response.data;
                const fields = data.extended_data.sections[0].fields;
                this.setState({sections: data.extended_data.sections, data: data})
                let valuesList = {};
                let hasValuesSet = false;
                for (let field of fields) {
                    let defaultValue = '';
                    if (field.type === 'chips') {
                        defaultValue = [];
                    }
                    valuesList[field.property] = defaultValue;

                    if (this.props.data) {
                        hasValuesSet = true;
                        this.setFieldsValues(field, this.props.data)
                    }
                }
                if (!hasValuesSet) {
                    this.setState({values: valuesList});
                    this.state.values = valuesList;
                }
            });
    }

    handleChange = (name, value) => {
        const fieldValue = (value.target.value);
        let values = this.state.values;
        values[name] = fieldValue;
        let plainvalues = this.parseReturn(values);
        this.setState({values: values, plainvalues: plainvalues});
        this.props.fillFields(plainvalues);
    }
    handleSelectChange = (name, value) => {

        let values = this.state.values;
        values[name] = value;
        /**
         * set the data as array/string
         */
        let plainvalues = this.parseReturn(values);
        this.setState({values: values, plainvalues: plainvalues});
        this.props.fillFields(plainvalues);
    }

    getFieldById(fieldName) {
        let fields = this.state.sections[0].fields;
        return fields.find(field => field.property === fieldName);

    }

    parseReturn = (data) => {
        let dataToReturn = {id: this.state.data.id, values: {}};
        for (let field in data) {

            if (typeof field == 'function') {
                continue;
            }
            let value = this.state.values[field];
            let valueToReturn = this.state.values[field];
            if (value instanceof Array) {
                valueToReturn = valueToReturn.map(element => element.value);
            } else if (typeof value == 'object') {
                valueToReturn = valueToReturn.value;
            }
            const fieldData = this.getFieldById(field);
            const valueObject = {
                field: fieldData.uuid,
                value: valueToReturn
            }
            dataToReturn.values[field] = valueObject;
        }
        return dataToReturn;
    }

    renderInputText(info, value) {
        return <Input name={info.property}
                      value={value}
                      type="textarea"
                      disabled={this.isDisabled}
                      onChange={(value) => this.handleChange(info.property, value)}
        />
    }

    renderTypeText(info) {
        let textFieldValue = this.state.values[info.property] ? this.state.values[info.property] : '';
        return (this.renderInputText(info, textFieldValue));
    }

    renderTypeSelect(info) {

        if (this.isDisabled && this.state.values[info.property]) {
            let valueInput = '';
            if (info.type === 'chips') {
                valueInput = this.state.values[info.property].map(data => {
                    return data.value;
                })
            }
            if (info.type === 'list') {
                valueInput = this.state.values[info.property].value;
            }
            return (this.renderInputText(info, valueInput));
        }

        let fieldValue = this.state.values[info.property] ? this.state.values[info.property].value : '';
        if (info.type === 'chips' && fieldValue === '') {
            fieldValue = [];
        }
        let options = [];
        for (const option of info.options) {
            let item = {
                value: String(option).valueOf(),
                label: option,
                isDisabled: false
            }
            options.push(item);
        }
        let isMulti = info.type === 'chips';
        return (<Select name={info.property} isMulti={isMulti}
                        className="react-select primary text-capitalize"
                        classNamePrefix="react-select"
                        value={this.state.values[info.property]}
                        options={options}
                        isSearchable={true}
                        onChange={value =>
                            this.handleSelectChange(info.property, value)
                        }
        />)
    }

    renderFields() {

        let hasError = InputError.convertToHasClass(this.state.errors);
        return (

            this.state.sections[0].fields.map(info => {
                return (
                    <>
                        <Row key={info.uuid}>
                            <Label sm={!this.props.oneColumn ? '2' : '12'}>{info.name}</Label>
                            <Col sm={!this.props.oneColumn ? '10' : '12'}>
                                <FormGroup className={hasError[info.property]}>
                                    {(() => {
                                        let fieldValue = this.state.values[info.property] ? this.state.values[info.property].value : '';
                                        switch (info.type) {
                                            case 'text':
                                                {return this.renderTypeText(info)}
                                                break;
                                            case 'chips':
                                            case 'list':
                                                {return this.renderTypeSelect(info)}
                                                break;
                                        }
                                    })()}
                                    <InputError name={info.property} errors={this.state.errors}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </>
                )
            })
        );
    }

    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);
        return (<>
                {this.renderFields()}
            </>
        );
    }
}

export default withNotify(ExtendDataFields);
