import React from "react";

// reactstrap components
import {Badge, CardBody, CardHeader, Col, Row} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import CardShowContent from "./CardShowContent";

class OCBIndividual extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.score = 21;
        this.topScore = 24;
    }

    get total() {
        if (!this.props.data || !this.props.data.total_cut_point_applied) {
            return 0;
        }
        return this.props.data.total_cut_point_applied || 0;
    }

    renderHigh() {
        return (
            <>
                {window.gettext("Você oferece auxílio emocional e instrumental para seus colegas de trabalho. Além disso, você atua como mediador de conflitos entre seus colegas, ou entre os colegas e seus superiores. Você ainda atua como tutor de novos colegas ou pessoas que foram transferidas de setor.")}
            </>
        );
    }

    renderLower() {
        return (
            <>
                {window.gettext("Você auxiia seus colegas, mesmo que isto não seja uma tividade prescrita em sua organização. Você oferece auxílio emocional ou instrumental mesmo quando não solicitado formalmente. ")}
            </>
        )
    }

    isHigher() {
        if (this.total) {
            return this.total >= this.score;
        }
        return false;
    }

    getDescription() {
        if (this.isHigher()) {
            return this.renderHigh();
        }
        return this.renderLower();
    }

    render() {

        return (
            <>
                <div className="content ancora-container">
                    <Row>
                        <Col md={12}>
                            <CardShowContent
                                isHigher={this.isHigher()}
                                topScore={this.topScore}
                                description={this.getDescription()}
                                total={this.total}
                                helper={window.gettext('Cidadania organizacional (individual e em relação à organização): Ações não requeridas e prescritas que visam a beneficiar a organização e as pessoas que a compõe. São ações proativas e voluntárias, tanto a nível das relações como de tarefas.')}
                                title={window.gettext('OCB Individual')}
                            />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(OCBIndividual);
