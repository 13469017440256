import React from "react";

class BSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: props.active,
            title: props.title || ''
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.active !== this.props.active ) {
            this.setState({active: this.props.active})
        }
   }

    close = () => {
        this.setState({active: false})
        if (this.props.onClose) {
            this.props.onClose()
        }
    }
    render() {
        return (
            <>
            <nav id="sidebar" className={'bsidebar '+(this.state.active ? ' active': '')}>

                <div id="dismiss" onClick={this.close}>
                    <svg data-v-467b32d8="" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px"
                         viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                         strokeLinejoin="round" className="ml-1 cursor-pointer feather feather-x">
                        <line data-v-467b32d8="" x1="18" y1="6" x2="6" y2="18"/>
                        <line data-v-467b32d8="" x1="6" y1="6" x2="18" y2="18"/>
                    </svg>
                </div>

                <div className="sidebar-header">
                    <h3>{this.state.title}</h3>
                </div>
                <div style={{padding: '10px'}}>
                {this.props.children}
                </div>
            </nav>
            <div className={(this.state.active ? 'bsidebar-overlay-active': 'bsidebar-overlay-inactive')}
                />
        </>)
    }
}
export default BSidebar;
