import React from "react";

// reactstrap components
import {Badge, CardBody, CardHeader, Col, Row} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import CardShowContent from "./CardShowContent";

class Optimism extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.score = 18;
        this.topScore = 20;
    }

    get total() {
        if (!this.props.data || !this.props.data.total_cut_point_applied) {
            return 0;
        }
        return this.props.data.total_cut_point_applied || 0;
    }

    renderHigh() {
        return (
            <>
                {window.gettext("Você espera sempre o melhor das situações de trabalho. Mesmo na presença de adversidades, você consegue vislumbrar aprendizados e lições importantes, para atingir os resultados positivos no futuro.")}
            </>
        );
    }

    renderLower() {
        return (
            <>
                {window.gettext("Você espera o melhor do futuro e possui crenças positivas sobre desfechos desejados. Contudo, você precisa desenvolver uma melhor capacidade de ver o lado positivo mesmo em situações adversas, em que aparentemente não há nada de bom. Experiências negativas podem trazer aprendizado significativo para lidar melhor com situações futuras. ")}
            </>
        )
    }

    isHigher() {
        if (this.total) {
            return this.total >= this.score;
        }
        return false;
    }

    getDescription() {
        if (this.isHigher()) {
            return this.renderHigh();
        }
        return this.renderLower();
    }

    render() {

        return (
            <>
                <div className="content ancora-container">
                    <Row>
                        <Col md={12}>
                            <CardShowContent
                                isHigher={this.isHigher()}
                                topScore={this.topScore}
                                description={this.getDescription()}
                                total={this.total}
                                helper={window.gettext('Crença geral na capacidade de atingir resultados positivos, individual ou coletivamente. Confiança em desfechos positivos.')}
                                title={window.gettext('Otimismo')}
                            />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(Optimism);
