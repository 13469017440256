import React from "react";
import {Link} from 'react-router-dom'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip, Badge
} from "reactstrap";

import PaginationCtrl from "components/Pagination/PaginationCtrl";

import apiRequest from "../../../lib/Api";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import withNotify from "../../../lib/NotificationWrapper";
import {toCurrency} from "../../../lib/Formatter";

class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      cloneId: null,
      showDeleteConfirmation: false,
      showCloneConfirmation: false,
      meta: {}
    };
  }

  componentDidMount() {
    this.requestData();
  }

  requestData = page => {
    if (!page) {
      page = 1;
    }
    let params = {page, include:'parent'};
    apiRequest("/products", {data: params, method: "GET"})
      .then(response => {
        let {data, meta} = response.data;
        this.setState({items: data, meta});
      });
  }

  toggleConfirmation = (show) =>  {
    this.setState({showDeleteConfirmation: show});
  }
  toggleCloneConfirmation = (show) =>  {
    this.setState({showCloneConfirmation: show});
  }

  maybeDelete = (id) => {
    this.setState({deleteId: id});
    this.toggleConfirmation(true);
  }
  maybeClone = (id) => {
    this.setState({cloneId: id});
    this.toggleCloneConfirmation(true);
  }

  delete = () => {
    const id = this.state.deleteId;
    apiRequest("/products/" + this.state.deleteId, {method: "DELETE"})
      .then(() => {
        this.props.notify({type: 'success', message: window.gettext('Produto removido com sucesso')});

        let items = this.state.items.filter(item => item.id !== id);
        this.setState({items});
      });
  }

  clone = () => {
    apiRequest(`/products/${this.state.cloneId}/version`, {method: "POST"})
        .then((response) => {
          this.props.notify({type: 'success', message: window.gettext('Nova versão do produto foi criada com sucesso.')});
          this.props.history.push(`/admin/products/${response.data.data.id}/edit`);
        });
  }

  getPublicUrl(prod) {
    return process.env.REACT_APP_HOST + `/setup/product/${prod.code}`;
  }

  renderStatus(status) {

    if (status) {
        return (
            <Badge color="success">{window.gettext('Sim')}</Badge>
        )
    }
    if (!status) {
      return (
          <Badge color="danger">{window.gettext('Não')}</Badge>
      )
    }
  }

  getParentName(parent) {

    if (!parent) {
      return(<></>);
    }

    return (
        <>
          <br/>
            (versão de #{parent.id} - {parent.name})
        </>
    )
  }
  render() {
    let {items} = this.state;

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    {window.gettext('Lista')}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">#</th>
                        <th>
                          {window.gettext('Nome')}
                        </th>
                        <th>
                          {window.gettext('Valor')}
                        </th>
                        <th>
                          {window.gettext('Ativo')}
                        </th>
                        <th>{window.gettext('URL pública')}
                        </th>
                        <th className="text-right">
                          {window.gettext('Ações')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      {items.map(item => {

                        return <tr key={item.id}>
                          <td >{item.id}</td>
                          <td>
                              {item.name}
                              {this.getParentName(item.parent)}
                          </td>
                          <td>{toCurrency(item.price)}</td>
                          <td>{this.renderStatus(item.active)}</td>
                          <td>
                            <a href={this.getPublicUrl(item)}>{item.code}</a>
                          </td>
                          <td className="text-right">
                            <Button
                              tag={Link}
                              className="btn-icon btn-neutral"
                              color="success"
                              size="sm"
                              to={`/admin/products/${item.id}/edit`}
                            >
                              <i className="fa fa-edit" />
                            </Button>{" "}
                            <Button
                                className="btn-icon btn-neutral"
                                color="secondary"
                                size="sm"
                                onClick={() => this.maybeClone(item.id)}
                            >
                              <i className="fa fa-clone"></i>
                            </Button>{" "}
                            <Button
                              className="btn-icon btn-neutral"
                              color="danger"
                              size="sm"
                              type="button"
                              onClick={() => this.maybeDelete(item.id)}
                            >
                              <i className="fa fa-times" />
                            </Button>{" "}

                          </td>
                        </tr>;
                      })}
                    </tbody>
                  </Table>
                  <PaginationCtrl responseMeta={this.state.meta}
                    requestPage={this.requestData}
                  />
                </CardBody>
              </Card>
            </Col>

          </Row>
        </div>

        <ConfirmModal message={window.gettext("Tem certeza que deseja remover este produto?")}
          onConfirm={() => this.delete()}
          onToggle={() => this.toggleConfirmation(false)}
          open={this.state.showDeleteConfirmation}
        />
        <ConfirmModal message={window.gettext("Tem certeza que deseja criar uma nova versão deste produto?")}
                      onConfirm={() => this.clone()}
                      onToggle={() => this.toggleCloneConfirmation(false)}
                      open={this.state.showCloneConfirmation}
        />
      </>
    );
  }
}

export default withNotify(ProductList);
