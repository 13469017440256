import React from "react";

// reactstrap components
import {Badge, CardBody, CardHeader, Col, Row} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import CardShowContent from "./CardShowContent";

class ConfrontationProactive extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.score = 17;
        this.topScore = 20;
    }

    get total() {
        if (!this.props.data || !this.props.data.total_cut_point_applied) {
            return 0;
        }
        return this.props.data.total_cut_point_applied || 0;
    }

    renderHigh() {
        return (
            <>
                {window.gettext("Você se esforça em antecipar problemas e barreiras futuras em relação aos seus objetivos e metas. Você busca imaginar cenários adversos e delimitar ações e planos para superá-los. Você não apenas está focado em desafios atuais, mas atento aos desafios futuros em seu contexto de trabalho.")}
            </>
        );
    }

    renderLower() {
        return (
            <>
                {window.gettext("Você oferece apoio emocional ou instrumental para seus colegas e colaboradores. Você consegue estabelecer estratégias para lidar com problemas atuais em seu trabalho. Contudo, você ainda precisa melhorar sua capacidade de enfrentar proativamente problemas futuros, antecipando eventos adversos ou barreias aos seus objetivos e estabelecendo planos para superá-las.")}
            </>
        )
    }

    isHigher() {
        if (this.total) {
            return this.total >= this.score;
        }
        return false;
    }

    getDescription() {
        if (this.isHigher()) {
            return this.renderHigh();
        }
        return this.renderLower();
    }

    render() {

        return (
            <>
                <div className="content ancora-container">
                    <Row>
                        <Col md={12}>
                            <CardShowContent
                                isHigher={this.isHigher()}
                                topScore={this.topScore}
                                description={this.getDescription()}
                                total={this.total}
                                helper={window.gettext('Estratégias que são utilizadas para construir recursos gerais que facilitem a promoção em direção a metas desafiadoras e crescimento pessoal. A diferença entre o enfretamento proativo e os modelos tracionais de enfrentamento é que o enfrentamento proativo é utilizado para prever formas de enfrentar situações estressores que podem ocorrer no futuro.')}
                                title={window.gettext('Enfrentamento Pró-Ativo')}
                            />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(ConfrontationProactive);
