import React from "react";
import AsyncSelect from "react-select/lib/Async";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Input,
  FormGroup,
  Form,
  Row,
  Col
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import InputError from "../../components/InputError";

class SpecialistServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      productServices: [],
      enabledServices: [],
      loadingServices: false,
      working: false,
      errors: {}
    }
  }

  componentDidMount() {
    this.loadAllServices();
  }

  clearForm() {
    this.setState({
      user_id: '',
      enabledServices: [],
      product_id: '',
      errors: {}
    });
  }

  loadUsers = searchTerm => {
    let params = {
      searchRole: 'specialist',
      search: searchTerm
    };
    return apiRequest("/users", {data: params, method: 'GET'})
      .then(response => {
        return response.data.data.map(user => ({value: user.id, label: user.name}));
      });
  }

  loadAllServices() {
    let data = {
      sort_by: 'service_id'
    };
    return apiRequest("/product-services", {data, method: 'GET'})
      .then(response => this.setState({productServices: response.data.data}));
  }

  loadEnabledServices() {
    let {user_id} = this.state;
    let data = {};

    if (!user_id) {
      return;
    }

    return apiRequest("/product-services/enabled/" + user_id.value, {data, method: "GET"}).then(response => {
      this.setState({
        enabledServices: response.data.data.map(s => s.id),
        loadingServices: false
      });
    });
  }

  hasService(serviceId) {
    return this.state.enabledServices.indexOf(serviceId) >= 0;
  }

  handleChange = (name, value) => {
    if (typeof name == 'object' && typeof name.target == 'object') {
      let event = name;
      event.preventDefault();
      name = event.target.name;
      value = event.target.value;
    }

    let newState = {
      [name]: value
    };

    if (this.state.errors[name]) {
      newState.errors = {...this.state.errors, [name]: ''};
    }

    this.setState(newState, () => {
      if (name === 'user_id' || name === 'product_id') {
        this.loadEnabledServices();
      }
    });
  }

  handleCheck = event => {
    let productServiceId = parseInt(event.target.value);
    let {enabledServices} = this.state;

    let foundIdx = enabledServices.indexOf(productServiceId);
    if (foundIdx === -1) {
      enabledServices.push(productServiceId);
    }
    else {
      enabledServices.splice(foundIdx, 1);
    }

    this.setState({enabledServices: [...enabledServices]});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let {user_id, enabledServices} = this.state;
    if (!user_id) {
      console.warn('Missing user id');
      return;
    }
    user_id = user_id.value;

    let data = {
      product_service_ids: enabledServices
    };

    this.setState({working: true});

    let request = apiRequest("/product-services/enabled/" + user_id, {data, method: 'POST'});
    request.then(response => {
        this.clearForm();
        this.props.notify({type: 'success', message: window.gettext('Serviços associados com sucesso')});
      })
      .catch(err => {
        this.setState({errors: err.data.errors});
      })
      .finally(
        response => this.setState({working: false})
      );
  }

  render() {
    let hasError = InputError.convertToHasClass(this.state.errors);

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      {window.gettext('Serviços do Especialista')}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Label sm="2">
                        {window.gettext('Usuário')}
                      </Label>
                      <Col sm="10">
                        <FormGroup className={hasError.user_id}>
                          <AsyncSelect
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="user_id"
                            value={this.state.user_id}
                            onChange={value =>
                              this.handleChange("user_id", value)
                            }
                            defaultOptions
                            loadOptions={this.loadUsers}
                            placeholder={window.gettext("Selecione um usuário")}
                          />
                          <InputError name="user_id" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">
                        {window.gettext('Serviços habilitados')}
                      </Label>
                      <Col className="checkbox-radios" sm="10">
                        {this.state.loadingServices && (
                          <span>
                            {window.gettext('Carregando serviços...')}
                          </span>
                        )}
                        {!this.state.loadingServices && this.state.productServices.map(productService => (
                          <FormGroup check key={productService.id}>
                            <Label check>
                              <Input type="checkbox" checked={this.hasService(productService.id)}
                                onChange={this.handleCheck} value={productService.id}
                              />
                              <span className="form-check-sign" />
                              {productService.service.displayName}
                              :&nbsp;&nbsp;<em>
                                {productService.product.name}
                              </em>
                            </Label>
                          </FormGroup>
                        ))}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md="2" />
                      <Col md="10">
                        <Button className="btn-round" color="info" type="submit"
                          disabled={this.state.working}
                        >
                          {window.gettext('Salvar')}
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withNotify(SpecialistServices);
