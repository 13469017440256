import React from "react";
import {Link} from 'react-router-dom';

// reactstrap components
import {Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row,} from "reactstrap";

import apiRequest from "lib/Api";
import OrganizationUsage from "views/components/OrganizationUsage";
import {getUserData} from "../../../lib/Auth";

class HomeAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overview: false,
      user: getUserData()
    };
  }

  componentDidMount() {
    apiRequest("/organizations/mine/usage-overview", {method: "GET"})
        .then(response => {
          this.setState({overview: response.data})
        });
  }

  renderResume() {

    if (this.state.overview.show_organization_usage) {

      return (<>
        <Row>
          <Col md="12">
            <Card className="card-tasks">
              <CardHeader>
                <CardTitle tag="h4">
                  {window.gettext('Atividades Cadastradas')}</CardTitle>
                <h5 className="card-category">
                  {window.gettext('Resumo para cada usuário')}
                </h5>
              </CardHeader>
              <CardBody>
                <OrganizationUsage/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>);
    }

  }

  renderCardFooter(type) {

    if (this.state.overview.show_organization_usage) {

      let textLink = '';
      let textButton = '';
      switch (type) {
        case 1:
          textLink = '/admin/users/list';
          textButton = window.gettext('Gerenciar usuários')
          break;
        case 2:
          textLink = '/admin/users/services';
          textButton = window.gettext('Associar atividades')
          break;
        case 3:
          textLink = '/admin/appointments/calendar';
          textButton = window.gettext('Ver agendamentos');
          break;
      }

      return (
          <>
            <CardFooter>
              <hr/>
              <div className="stats">
                <Button tag={Link} to={textLink} color="default" className="btn-link">
                  <i className="fa fa-info-circle"/>
                  {textButton}
                </Button>
              </div>
            </CardFooter>
          </>
      );
    }

  }

  render() {
    const {overview} = this.state;
    if (!overview) {
      return (
          <>
            <div className="content dashboard-admin-container">
              {window.gettext('Carregando dashboard...')}
            </div>
          </>
      );
    }

    return (
        <>
          <div className="content dashboard-admin-container">
            <Row>
              <Col lg="3" md="4" sm="4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-single-02 text-warning"/>
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">
                            {window.gettext('Usuários')}</p>
                          <CardTitle tag="p">{overview.users.total}</CardTitle>
                          <p/>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  {this.renderCardFooter(1)}
                </Card>
              </Col>
              <Col lg="5" md="4" sm="4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-cart-simple text-warning"/>
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">
                            {window.gettext('Atividades')}</p>
                          <CardTitle tag="p">
                            <span title={window.gettext("compradas")}>{overview.purchases.total}</span> &nbsp;
                            <i className="fa fa-thumb-tack"
                               title={window.gettext("associadas")}/>{overview.purchaseUsers.total} &nbsp;
                            <i className="fa fa-check-square-o"
                               title={window.gettext("finalizadas")}/>{overview.purchaseUsers.done}
                          </CardTitle>
                          <p/>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  {this.renderCardFooter(2)}
                </Card>
              </Col>
              <Col lg="4" md="4" sm="4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-calendar-60 text-success"/>
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">
                            {window.gettext('Agendamentos')}
                          </p>
                          <CardTitle tag="p">
                            {overview.appointments.total} &nbsp;
                            <i className="fa fa-check-square-o"
                               title={window.gettext("finalizados")}/>{overview.appointments.done}
                          </CardTitle>
                          <p/>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  {this.renderCardFooter(3)}
                </Card>
              </Col>
            </Row>
            {this.renderResume()}
          </div>
        </>
    );
  }
}

export default HomeAdmin;
