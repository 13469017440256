import React from "react";
import moment from 'moment';
import withNotify from "../../../lib/NotificationWrapper";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    Input,
    Label,
    Row
} from "reactstrap";

import apiRequest from "../../../lib/Api";


class ExportacaoAtendimentosEspecialistas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datainicial: moment().startOf('month').format('YYYY-MM-DD'),
            datafinal: moment().endOf('month').format('YYYY-MM-DD'),
        };
    }

    setValues = event => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value});
    }

    export = () => {

        let datainicial = moment(this.state.datainicial);
        let datafinal   = moment(this.state.datafinal);

        if ( datainicial > datafinal ) {
            this.props.notify({type: 'warning', message: window.gettext('A data inicial selecionada é maior que a data final. Corriga para continuar com a exportação.')});
            return false;
        }


        let data = {
            datainicial: this.state.datainicial,
            datafinal: this.state.datafinal,
        };

        const now = moment();
        const date = now.format('DD/MM/YYYY');
        const hour = now.format('HH:mm');

        apiRequest("/reports/appointments/export", {method: "GET", data}, {blob: true})
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                const nameFile = `atendimentos_${date}_${hour}.xlsx`;
                link.setAttribute('download', nameFile);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
    }


    render() {

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">
                                      {window.gettext('Exportação de atendimento')}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form className="form-horizontal mb-2">
                                        <Row>
                                            <Col md="3">
                                                <Label>
                                                {window.gettext('Intervalo de atendimentos')}
                                                </Label>
                                            </Col>
                                            <Col md="3">
                                                <Input type="date" name="datainicial" value={this.state.datainicial} onChange={this.setValues} />
                                            </Col>
                                            <Col md="3">
                                                <Input type="date" name="datafinal" value={this.state.datafinal} onChange={this.setValues} />
                                            </Col>
                                            <Col md="3">
                                                <Button color="primary" type="button" style={{margin: 0}}
                                                        onClick={this.export}
                                                >
                                                  {window.gettext('Exportar Excel')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}

export default withNotify(ExportacaoAtendimentosEspecialistas) ;
