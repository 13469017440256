import React from "react";
import {
    Button,
    Col,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane
} from "reactstrap";
import * as moment from "moment";
import {Link} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import apiRequest from "../../lib/Api";
import withNotify from "../../lib/NotificationWrapper";
import FileUpload from "../../components/CustomUpload/FileUpload";
import * as PropTypes from "prop-types";
import InputError from "../components/InputError";
import {getUserData} from "../../lib/Auth";
import ConfirmModal from "../../components/Modals/ConfirmModal";

FileUpload.propTypes = {
    originalUrl: PropTypes.string,
    onChangeImage: PropTypes.func
};

class FileIndex extends React.Component {

    constructor(props) {
        super(props);
        let label = this.props.label ? this.props.label : '';
        let userId = this.props.user_id ? this.props.user_id : null;
        let loggedUser = getUserData();
        this.state = {
            files: [],
            sharedFiles: [],
            modal: false,
            shared: false,
            loggerUser: loggedUser,
            userId: userId,
            confirmDelete: null,
            confirmShare: null,
            showSharedFiles: loggedUser.role !== 'student',
            confirmDeleteMessage: '',
            confirmShareMessage: '',
            fileToDelete: null,
            fileToShare: null,
            label: label,
            currentTab: 'user',
            model: {
                filename: '',
                shared: false,
                FileContent: '',
            },
            errors: {}
        }
        this.shared = false;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({userId: nextProps.user_id})
        this.getFiles();
        this.getSharedFiles();
    }

    componentDidMount() {
        this.getFiles();
        this.getSharedFiles();
    }

    closeModal = () => {
        this.setState({modal: false});
    }

    openModal = () => {
        this.setState({modal: true});
    }
    getFiles = async () => {

        if (!this.state.userId) {
            this.setState({files: []});
            return;
        }
        await apiRequest(`/fm/${this.state.userId}/files`, {
            method: 'GET'
        }).then(success => {
            let {data} = success.data;
            this.setState({files: data});
        });
    }


    getSharedFiles = async () => {

        if (!this.state.userId) {
            this.setState({files: []});
        }
        await apiRequest(`/fm/shared-files`, {
            method: 'GET'
        }).then(success => {
            let {data} = success.data;
            this.setState({sharedFiles: data});
        });
    }

    sendFile = () => {
        if (!this.state.model.FileContent) {
            this.props.notify({type: "error", message: window.gettext("falha ao fazer upload do arquivo")});
            return;
        }
        let data = this.state.model;
        if (this.state.userId !== null) {
            data.patient_id = this.state.userId;
        }
        let formData = new FormData()
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
        }

        apiRequest(`/fm/upload/arquivo`, {data: formData, method: 'POST'}).then(response => {
            this.closeModal();
            this.getFiles();
            this.getSharedFiles();
            let model = {
                filename: '',
                shared: false,
                FileContent: '',
            }
            this.setState({shared: false, model:model});
            this.props.notify({type: 'success', message: window.gettext('Arquivo enviado com sucesso.')});
        }).catch(error => {
            this.props.notify({type: 'error', message: window.gettext('Não foi possível enviar o arquivo.')});

        });
    }

    handleChange = (name, value) => {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }

        let model = this.state.model;
        model[name] = value;
        this.setState({model: model});

        if (this.state.errors[name]) {
            this.setState({errors: {...this.state.errors, [name]: ''}});
        }
    }

    handleChangeImage = file => {
        let model = this.state.model;
        model.FileContent = file;
        this.setState({
            model: model
        });
    }

    hideAlertDeleteFile = () => {
        this.setState({confirmDelete: null});
    };

    downloadFile = (file, shared) => {

        const type = file.type;
        const fileName = file.file;
        let userId = this.props.user_id;
        let url = `/fm/download/file/${file.uuid}`;
        if (shared) {
            userId = 'shared';
            url = `/fm/download/shared-file/${fileName}`
        }

        apiRequest(url, {method: "GET"}, {blob: true})
            .then(response => {
                var file = window.URL.createObjectURL(response);
                var a = document.createElement('a');
                a.href = file;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    confirmDeleteFile = (file, shared) => {
        if (shared) {
            this.shared = true;
        }

        this.setState({
            confirmDelete: true,
            fileToDelete : file,
            confirmDeleteMessage: window.gettext('Deseja excluir o arquivo %1?', file.name)
        });
    }

    deleteFile = () => {

        let file = this.state.fileToDelete;
        let userId = this.state.loggerUser.id;

        if (this.state.loggerUser.role !== 'student') {
            userId = this.state.userId;
        }

        let url = `/fm/${userId}/files/${file.uuid}`;
        if (this.shared) {
            url = `/fm/shared-files/${file.uuid}`;
        }
        apiRequest(url, {method: "DELETE"}).then(response => {
            this.props.notify({type: 'success', message: window.gettext('Arquivo %1 foi removido com sucesso.', file.name)});
            this.getFiles();
            this.getSharedFiles();
            this.shared = false;
            this.setState({confirmDelete: null});
        }).catch(error => {
            this.props.notify({type: 'error', message: window.gettext('Não foi possível remover o arquivo %1.', file.name)});
            this.setState({confirmDelete: null});
        })
    }

    addSharedFile = () => {
        let model = this.state.model;
        model.shared = true;
        this.setState({model: model});
        this.openModal();
    }

    renderLabel = () => {
        return (
            <Row>
                <Col>
                    <h3>{this.state.label}</h3>
                </Col>
            </Row>
        )
    }

    renderSharedDeleteButton(file) {

        if (this.state.loggerUser.role !== 'specialist') {
            return (
                <Button color="link" style={{fontSize: '20px'}}
                        onClick={(event) => {
                            this.confirmDeleteFile(file, true);
                        }}
                ><i className="fa fa-trash"/></Button>
            )
        }
        return (<></>);
    }

    renderShareButton(file) {

        if (this.state.loggerUser.role === 'specialist') {
            return (
                <Button color="link" style={{fontSize: '20px'}} title={window.gettext("Compartilhar arquivo")}
                        onClick={(event) => {
                            this.confirmShareFileWithUser(file);
                        }}
                ><i className="fa fa-share-alt"/></Button>
            )
        }
        return (<></>);
    }

    confirmShareFileWithUser = (file) => {

        if (!this.state.userId) {
            this.props.notify({type: 'error', message: window.gettext('Antes de compartilhar um arquivo, selecione um usuário.')});
            return;
        }
        this.setState({
            confirmShareMessage: window.gettext('Confirma o compartilhamento do arquivo %1', file.name),
            confirmShare: true,
            fileToShare: file
        });
    }
    hideAlertShareFile = () => {
        this.setState({confirmShare: null});
    };
    shareFileWithUser = () => {
        let data = {
            user_id: this.state.userId
        }
        let file = this.state.fileToShare;
        apiRequest(`/fm/share/${file.uuid}`, {data: data, method: 'POST'}).then(response => {
            this.closeModal();
            this.getFiles();
            this.getSharedFiles();
            this.setState({shared: false, confirmShare: null});
            this.props.notify({type: 'success', message: window.gettext('Arquivo compartilhado com sucesso.')});
        }).catch(error => {
            this.props.notify({type: 'error', message: window.gettext('Não foi possível enviar o arquivo.')});

        });
    }

    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);
        let files = this.state.files;
        let sharedFiles = this.state.sharedFiles;

        return (
            <>
                {this.state.confirmShare}
                {this.label && this.renderLabel()}
                <Row>
                    <Col lg="2" md="2" sm='2' xs="2">
                        <div className="nav-tabs-navigation verical-navs" style={{height: "100%"}}>
                            <div className="nav-tabs-wrapper">
                                <Nav
                                    className="flex-column nav-stacked"
                                    role="tablist"
                                    tabs
                                >
                                    <NavItem>
                                        <NavLink
                                            data-toggle="tab"
                                            href="#file"
                                            role="tab"
                                            className={
                                                this.state.currentTab === "user"
                                                    ? "active"
                                                    : ""
                                            }
                                            onClick={() =>
                                                this.setState({currentTab: "user"})
                                            }
                                        >
                                            {window.gettext('Usuário')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={{display:this.state.showSharedFiles ? '' : 'none'}}>
                                        <NavLink
                                            data-toggle="tab"
                                            href="#file"
                                            role="tab"
                                            className={
                                                this.state.currentTab === "shared"
                                                    ? "active"
                                                    : ""
                                            }
                                            onClick={() =>
                                                this.setState({currentTab: "shared"})
                                            }
                                        >
                                            {window.gettext('Compartilhados')}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </div>
                    </Col>
                    <Col lg="8" md="7" sm="8" xs="6">
                        {/* Tab panes */}
                        <TabContent activeTab={this.state.currentTab}>
                            <TabPane tabId="user">
                                <Row>

                                    <Col md={12}>
                                        <div style={{textAlign: 'right'}}>
                                            <Button color="primary" onClick={this.openModal}>
                                                <i className="fa fa-plus"/>
                                                {window.gettext('Adicionar')}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table hover>
                                            <thead>
                                            <tr>
                                                <th>
                                                    {window.gettext('Nome')}
                                                </th>
                                                <th>
                                                    {window.gettext('Enviado Por')}
                                                </th>
                                                <th>
                                                    {window.gettext('Data do Envio')}
                                                </th>
                                                <th>
                                                    {window.gettext('Arquivo')}
                                                </th>
                                                <th>
                                                    {window.gettext('Excluir')}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {files.map(file => {
                                                return (
                                                    <tr key={file.uuid}>
                                                        <td className="text-left">
                                                            {file.name}
                                                        </td>
                                                        <td>
                                                            {file.sender.name}
                                                        </td>
                                                        <td>
                                                            {moment(file.created_at).format('DD/MM/YYYY')}
                                                        </td>
                                                        <td>
                                                            <Link color="link" to={'#'}
                                                                  onClick={(event) => {
                                                                      event.preventDefault();
                                                                      this.downloadFile(file)
                                                                  }}
                                                            >{window.gettext('Baixar')}</Link>
                                                        </td>
                                                        <td>
                                                            <Button color="link" style={{fontSize: '20px'}}
                                                                    onClick={(event) => {
                                                                        this.confirmDeleteFile(file);
                                                                    }}
                                                            ><i className="fa fa-trash"/></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="shared">
                                <Row>

                                    <Col md={12}>
                                        <div style={{textAlign: 'right'}}>
                                            <Button color="primary" onClick={this.addSharedFile}>
                                                <i className="fa fa-plus"/>
                                                Adicionar
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table hover>
                                            <thead>
                                            <tr>
                                                <th>
                                                    {window.gettext('Nome')}
                                                </th>
                                                <th>
                                                    {window.gettext('Enviado Por')}
                                                </th>
                                                <th>
                                                    {window.gettext('Data do Envio')}
                                                </th>
                                                <th>
                                                    {window.gettext('Arquivo')}
                                                </th>
                                                <th>
                                                    {window.gettext('Ações')}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {sharedFiles.map(file => {
                                                return (
                                                    <tr key={file.uuid}>
                                                        <td className="text-left">
                                                            {file.name}
                                                        </td>
                                                        <td>
                                                            {file.sender.name}
                                                        </td>
                                                        <td>
                                                            {moment(file.created_at).format('DD/MM/YYYY')}
                                                        </td>
                                                        <td>
                                                            <Link color="link" to={'#'}
                                                                  onClick={(event) => {
                                                                      event.preventDefault();
                                                                      this.downloadFile(file, true)
                                                                  }}
                                                            >{window.gettext('Baixar')}</Link>
                                                        </td>
                                                        <td>
                                                            {this.renderShareButton(file)}
                                                            {this.renderSharedDeleteButton(file)}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
                <Modal isOpen={this.state.modal} size="md">
                    <ModalHeader style={{position: 'sticky', textAlign: 'left'}}>
                        {window.gettext('Adicionar arquivos')} {this.state.model.shared ? " " + window.gettext('compartilhados') : ''}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup className={hasError.name}>
                                    <label>{window.gettext('Nome')}</label>
                                    <Input value={this.state.model.filename}
                                           size="10"
                                           name="filename"
                                           onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>

                            <Col md="10" sm="10">
                                <FileUpload onChangeImage={this.handleChangeImage}
                                            originalUrl=''
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='error' onClick={this.closeModal}>{window.gettext('Fechar')}</Button>
                        <Button color='primary' onClick={this.sendFile}>{window.gettext('Enviar')}</Button>
                        &nbsp;
                    </ModalFooter>
                </Modal>
                <ConfirmModal message={this.state.confirmDeleteMessage}
                              onConfirm={() => this.deleteFile()}
                              onToggle={() => this.setState({confirmDelete:false})}
                              open={this.state.confirmDelete}
                />
                <ConfirmModal message={this.state.confirmShareMessage}
                              onConfirm={() => this.shareFileWithUser()}
                              onToggle={() => this.setState({confirmShare:false})}
                              open={this.state.confirmShare}
                />
            </>
        )
    }
}

export default withNotify(FileIndex);
