import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Input,
  FormGroup,
  Form,
  Row,
  Col
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import InputError from "../../components/InputError";
import Select from "react-select";
import {getUserData, saveAuth, updateUserData} from "../../../lib/Auth";
import methods from "react-table/lib/methods";

class ChangeCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active_company: null,
      next_company: null,
      companiesAvaliable: [],
      user: getUserData()
    }
  }

  async componentDidMount() {

    await apiRequest('/users/permissions', {method:'GET'})
        .then(response => {
          let companieAvaliable = [];
          companieAvaliable = response.data.data.map(company => {
            return {
              value: company.id,
              label: company.name
            }
          });
          this.setState({companiesAvaliable: companieAvaliable});
        });

    if (this.state.user.organization && this.state.user.organization.name) {
        this.setState({active_company: this.state.user.organization.name});
    }

  }

  clearForm() {
    this.setState({
      active_company: null,
      next_company: null,
      companiesAvaliable: [],
    });
  }

  handleChange = (value) => {
    this.setState({next_company: value})
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({working: true});

    apiRequest(`/users/change-company/${this.state.next_company.value}`, {method: 'POST'})
        .then(response => {
          if (response.status === 200) {
            this.props.notify({type: 'success', message: window.gettext('Empresa alterada com sucesso.')});
            this.setState({active_company: this.state.next_company.label, next_company: null})

            updateUserData('organization', response.data.organization);
            updateUserData('adminOrganization', response.data.organization);
            updateUserData('reload', false);
            window.location.reload();

          } else {
            this.props.notify({type: 'error', message: window.gettext('Não foi possível alterar a empresa.')});
          }
        }).finally(() => {
        this.setState({working: false});
    });

  }

  render() {

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{window.gettext('Alterar empresa autenticada')}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Label sm="2">{window.gettext('Empresa Atual:')}</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={this.state.active_company}
                            name="active_company"
                            readOnly={true}
                            disabled={true}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">{window.gettext('Alterar para empresa:')}</Label>
                      <Col sm="10">
                        <FormGroup >
                          <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="next_company"
                              value={this.state.next_company}
                              onChange={value =>
                                  this.handleChange( value)
                              }
                              options={[
                                {
                                  value: "",
                                  label: window.gettext("Selecione uma empresa"),
                                  isDisabled: true
                                },
                                ...this.state.companiesAvaliable
                              ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md="2" />
                      <Col md="10">
                        <Button className="btn-round" color="info" type="submit"
                          disabled={this.state.working}
                        >
                          {window.gettext('Salvar')}
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withNotify(ChangeCompany);
