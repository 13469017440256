import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

import Dropzone from "components/Dropzone/Dropzone";

import apiRequest from "lib/Api";
import withNotify from "lib/NotificationWrapper";

class ImportUsers extends React.Component {
  constructor(props) {
    super(props);
  }

  saveFile = files => {
    let formData = new FormData();
    formData.append('users', files[0]);

    apiRequest("/users/import", {data: formData, method: 'POST'})
      .then(res => {
        let {data} = res;
        let msg = window.gettext('%1 usuário(s) criados.', data.created);
        if (data.existing > 0) {
          msg += window.gettext('%1 usuário(s) já existia(m).', data.existing);
        }
        this.props.notify({type: 'success', message: msg});
      })
      .catch(err => {
        let {data: {row, errors}} = err;
        let firstError = Object.values(errors)[0];
        this.props.notify({type: 'error', message: window.gettext('Erro na linha %1:%2', row, firstError)});
      });
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    {window.gettext('Importar usuários')}</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="dropzone-container">
                      <Dropzone onFilesAdded={this.saveFile} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withNotify(ImportUsers);
