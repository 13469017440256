import React from "react";
import NotificationAlert from "react-notification-alert";

const iconCategories = {
  saved: 'nc-icon nc-cloud-upload-94'
};

const defaultIconCategory = 'saved';

const mappingTypes = {
  success: 'success',
  error: 'danger',
  warning: 'warning'
}

const withNotify = WrappedComponent => {
  class ComponentWithNotify extends React.Component {
    notify = ({type, category, message, place="tr"}) => {
      var options = {};
      options = {
        place: place,
        message: (
          <div>
            <div>
              {message}
            </div>
          </div>
        ),
        type: mappingTypes[type],
        icon: iconCategories[category || defaultIconCategory],
        autoDismiss: 7
      };
      if (this.refs.notificationAlert) {
        this.refs.notificationAlert.notificationAlert(options);
      }
      else {
        // failback
        window.alert(message);
      }
    }

    render() {
      return (
        <>
          <WrappedComponent notify={this.notify} {...this.props} />
          <NotificationAlert ref="notificationAlert" />
        </>
      );
    }
  }
  return ComponentWithNotify;
};

export default withNotify;
