import React from "react";

import {Badge, Button, Card, CardBody, CardFooter, Col} from "reactstrap";
import {getUserData} from "../../../lib/Auth";
import moment from "moment";
import {Link} from "react-router-dom";

class AppointmentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: getUserData(),
            appointment: {
                service_title: '',
                product_title: '',
                name: '',
                date: '',
            },
            isPast: false,
        };
    }

    get isPast() {
        if (this.props.isPast === undefined) {
            return false
        }

     }
    componentDidMount() {
        const isPast = this.props.isPast || false
        this.setState({appointment: this.props.appointment, isPast: isPast});
    }

    renderBadge() {
        if (this.state.isPast) {
            return <>
                <br/>
                <Badge color="danger">
                    {window.gettext('Em Atraso')}
                </Badge>
            </>
        }
    }

    render() {
        return <>
            <Col md={4}>
                <Card>
                    <CardBody style={{fontSize: "12px"}}>
                        <b>{this.state.appointment.product_title} / {this.state.appointment.service_title}</b><br/>
                        <br/>
                        <b>{window.gettext('Especialista')}:</b> {this.state.appointment.specialist_name}<br/>
                        <b>{window.gettext('Usuário')}:</b> {this.state.appointment.user_name}<br/>
                        <b>{window.gettext('Data / Início')}</b>: {moment(this.state.appointment.datetime_start).format('D/M/YYYY HH:mm')}<br/>
                        {this.renderBadge()}
                    </CardBody>
                    <CardFooter className={'text-center'}>
                        <Button
                            size="sm"
                            name='btnAccessAppointment'
                            color="primary"
                            tag={Link}
                            to={`/admin/appointments/${this.state.appointment.id}`}
                        >
                            Acessar
                        </Button>
                    </CardFooter>
                </Card>
            </Col>
        </>
    }
}

export default AppointmentList;
