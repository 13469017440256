import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Form,
  Label,
  FormGroup,
  Input, CardSubtitle, Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import withNotify from "../../../lib/NotificationWrapper";

import PaginationCtrl from "components/Pagination/PaginationCtrl";
import moment from "moment";
import momentFormat from "../../../lib/Locale";
import Select from "react-select";

class UsersBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isModalOpen: false,
      monthsSelected: {},
      meta: {},
      filter: '',
      exportInterval: 30,
      showChangeStatusConfirmation: false,
      purchaseUserId: null
    };

    this.months = [];
  }

  // get scope() {
  //   if (this.props.match.path.match(/users/)) {
  //     return 'users';
  //   }
  //   else if (this.props.match.path.match(/specialists/)) {
  //     return 'specialists';
  //   }
  //   throw new Error("Invalid scope");
  // }
  //
  // get role() {
  //   if (this.scope === 'users') {
  //     return 'student';
  //   }
  //   if (this.scope === 'specialists') {
  //     return 'specialist';
  //   }
  //   return null;
  // }

  componentDidUpdate(prevProps) {
    // TODO use params instead
    if (this.props.match.path !== prevProps.match.path) {
      this.requestData();
    }
  }

  componentDidMount() {
    this.requestData();

    let month = window.gettext('Mês');
    let months = window.gettext('Meses');
    this.months.push({
      label: 'Desativar',
      value: '',
    });
    for (let i = 1; i <= 12; i++) {
      this.months.push(
          {
            label: i === 1 ? `${i} ${month}` : `${i} ${months}`,
            value: i,
          }
      );
    }
    this.state.monthsSelected = this.months[0];

  }

  requestData = page => {
    let data = {
      page: page || 1
    };

    if (this.state.filter) {
      data.search = this.state.filter;
    }

    apiRequest("/users/blocked", {method: "GET", data})
      .then(response => {
        let {data} = response.data.data;

        let meta = {
          'current_page': response.data.data.current_page,
          'from': response.data.data.from,
          'last_page': response.data.data.last_page,
          'path': response.data.data.path,
          'per_page': response.data.data.per_page,
          'to': response.data.data.to,
          'total': response.data.data.total,
        }

        this.setState({users: data, meta: meta});
      });
  }

  toggleConfirmation = (show) =>  {
    this.setState({isModalOpen: show});
  }

  filterChange = (event) => {
    let value = event.target.value;
    this.setState({filter: value});
  }

  executeFilter = (event) => {
    event.preventDefault();
    this.requestData();
  }

  getLockIcon = (item) => {
    if (item.is_active) {
      return (<i className="fa fa-unlock" />);
    }
    return (<i className="fa fa-lock" />);
  }

  toggleConfirmationChangeStatus = (show) => {
    this.setState({isModalOpen: show});
  }

  maybeChangeStatus = (id) => {
    this.setState({purchaseUserId: id});
    this.toggleConfirmationChangeStatus(true);
  }

  changeStatusPurchase = () => {

    let params = {
      method: "POST",
      data: {
        months: this.state.monthsSelected.value
      }
    }
    apiRequest(`/purchases/${this.state.purchaseUserId}/change-status`, params)
        .then((response) => {
          let message =  window.gettext("Status alterado com sucesso.");
          this.props.notify({type: 'success', message: message});
          this.requestData();
          this.setState({isModalOpen: false});
        });
  }

  render() {
    let {users} = this.state;

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    {window.gettext('Usuários passíveis de bloqueio.')}
                  </CardTitle>
                  <CardSubtitle>
                    {window.gettext('Todos os usuários aqui listados tiveram seus produtos associados a mais de um ano.')}
                  </CardSubtitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontal">
                    <Row>
                      <Label md="1">
                        {window.gettext('Buscar')}</Label>
                      <Col md="3">
                        <FormGroup>
                          <Input placeholder="Nome ou e-mail" type="text"
                            onChange={this.filterChange} value={this.state.filter}
                            onKeyPress={event => event.which === 13 && event.preventDefault()}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="1">
                        <Button color="info" type="button" style={{margin: '1px -15px'}}
                          onClick={this.executeFilter}
                        >
                          <i className="fa fa-search"/>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">#</th>
                        <th>
                          {window.gettext('Nome/Email')}
                        </th>
                        <th>{window.gettext('Valido Até')}</th>
                        <th>{window.gettext('Produto')}</th>
                        <th className="text-right">{window.gettext('Ações')}</th>
                      </tr>
                    </thead>
                    <tbody>

                      {users.map(user => {

                        return <tr key={user.id}>
                          <td className="text-center">{user.id}</td>
                          <td>{user.user_name}<br />{user.user_email}</td>
                          <td>{moment(user.valid_until).format(`${momentFormat.DATE_PTBR} ${momentFormat.HOUR}`)}</td>
                          <td>{user.product_name}</td>

                          <td className="text-right">
                            <Button
                                className="btn-icon btn-neutral"
                                color={user.is_active ? 'success' : 'danger'}
                                size="sm"
                                onClick={() => this.maybeChangeStatus(user.id)}
                            >
                              {this.getLockIcon(user)}
                            </Button>
                          </td>
                        </tr>;

                      })}

                    </tbody>
                  </Table>
                  <PaginationCtrl responseMeta={this.state.meta}
                    requestPage={this.requestData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <ConfirmModal message={window.gettext('Tem certeza que deseja alterar o status desta compra?')}
                      onConfirm={() => this.changeStatusPurchase()}
                      onToggle={() => this.toggleConfirmationChangeStatus(false)}
                      open={this.state.showChangeStatusConfirmation}
        />

        <Modal isOpen={this.state.isModalOpen} size="md">
          <ModalHeader style={{position: 'sticky'}}>
            {window.gettext('Alterar data de validade')}
          </ModalHeader>
          <ModalBody>
            <Label>
              Liberar o usuário por
            </Label>
            <Select
                className="react-select primary"
                classNamePrefix="react-select"
                name="monthsSelected"
                value={this.state.monthsSelected}
                onChange={value =>
                    this.setState({ monthsSelected: value })
                }
                options={this.months}
                placeholder="Selecione"
            />
          </ModalBody>
          <ModalFooter>
            <Button
                color={'default'}
                onClick={() => this.setState({isModalOpen: false})}
            >
              {window.gettext('Fechar')}
            </Button>
            <Button
                color={'primary'}
                onClick={this.changeStatusPurchase}
            >
              {window.gettext('Salvar')}
            </Button>
          </ModalFooter>
        </Modal>

      </>
    );
  }
}

export default withNotify(UsersBlock);
