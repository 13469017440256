import React from "react";

// reactstrap components
import {Badge, CardBody, CardHeader, Col, Row} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import CardShowContent from "./CardShowContent";

class Hope extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.score = 26;
        this.topScore = 28;
    }

    get total() {
        if (!this.props.data || !this.props.data.total_cut_point_applied) {
            return 0;
        }
        return this.props.data.total_cut_point_applied || 0;
    }

    renderHigh() {
        return (
            <>
                {window.gettext("Você possui facilidade para estabelecer objetivos, desenvolver planos para atingí-los e na manutenção necessária para cumpri-los. Você consegue lidar com dificuldades e obstáculos entre você e seus objetivos e metas.")}
            </>
        );
    }

    renderLower() {
        return (
            <>
                {window.gettext("Você se esforça para alcançar metas e objetivos, com flexibilidade para usar diferentes estratégias. Contudo, você precisa melhorar no estabelecimento de metas e objetivos e, principalmente, na manutenção da motivação necessária para atingi-los.")}
            </>
        )
    }

    isHigher() {
        if (this.total) {
            return this.total >= this.score;
        }
        return false;
    }

    getDescription() {
        if (this.isHigher()) {
            return this.renderHigh();
        }
        return this.renderLower();
    }

    render() {

        return (
            <>
                <div className="content ancora-container">
                    <Row>
                        <Col md={12}>
                            <CardShowContent
                                isHigher={this.isHigher()}
                                topScore={this.topScore}
                                description={this.getDescription()}
                                total={this.total}
                                helper={window.gettext('Características cognitivas e motivacionais para planejar e atingir metas e objetivos, de forma engajada.')}
                                title={window.gettext('Esperança')}
                            />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(Hope);
