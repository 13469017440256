import React from "react";

// reactstrap components
import {Badge, CardBody, CardHeader, Col, Row} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import CardShowContent from "./CardShowContent";

class SelfEvaluation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.score = 26;
        this.topScore = 32;
    }

    get total() {
        if (!this.props.data || !this.props.data.total_cut_point_applied) {
            return 0;
        }
        return this.props.data.total_cut_point_applied || 0;
    }

    renderHigh() {
        return (
            <>
                {window.gettext("Você nutre e mantém uma autoimagem positiva de si mesmo. Você tem uma percepção realista e positiva sobre suas capacidades de enfrentar a maior parte das situações em seu trabalho. Você não apresenta autocrítica excessiva e perfeccionismo, a ponto de prejudicar a imagem positiva de si mesmo.")}
            </>
        );
    }

    renderLower() {
        return (
            <>
                {window.gettext("Você possui uma autoimagem positiva de si mesmo, conseguindo reconhecer seus pontos positivos e suas forças pessoais. Você se percebe como alguém capaz de exercer controle sobre a maioria das situações no seu trabalho.")}
            </>
        )
    }

    isHigher() {
        if (this.total) {
            return this.total >= this.score;
        }
        return false;
    }

    getDescription() {
        if (this.isHigher()) {
            return this.renderHigh();
        }
        return this.renderLower();
    }

    render() {

        return (
            <>
                <div className="content ancora-container">
                    <Row>
                        <Col md={12}>
                            <CardShowContent
                                isHigher={this.isHigher()}
                                topScore={this.topScore}
                                description={this.getDescription()}
                                total={this.total}
                                helper={window.gettext('Representam um traço de personalidade estável referente as avaliações fundamentais sobre si mesmo, suas próprias habilidades e seu próprio controle.')}
                                title={window.gettext('Auto-avaliação - Nuclear / Central')}
                            />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(SelfEvaluation);
