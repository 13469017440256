import React from "react";
import moment from 'moment';
import {Link} from 'react-router-dom';

// reactstrap components
import {
  Progress,
  Table,
  Row,
  Col, Button,
} from "reactstrap";

import apiRequest from "lib/Api";
import defaultAvatar from "assets/img/default-avatar.png";

class OrganizationUsage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
  }

  get organizationId() {
    return this.props.organizationId
  }
  componentDidMount() {
    let id = this.props.organizationId || 'mine';
    apiRequest(`/organizations/${id}/usage`, {method: "GET"})
      .then(response => {
        this.setState({users: response.data})
      });
  }
  describeSituation(situation) {
    if (!situation.last && !situation.next) {
      return window.gettext('Não agendado ainda');
    }
    else if (situation.last && !situation.next) {
      return this.describeAppointment(situation.last);
    }
    else if (!situation.last && situation.next) {
      return this.describeAppointment(situation.next);
    }
    else {
      const {last, next} = situation;
      if (last.situation === 'canceled') {
        if (next.situation === 'pending' || next.situation === 'approved') {
          return window.gettext('Re-agendou para %1',  this.formatDate(next.datetime_start));
        }
        else {
          return this.describeAppointment(next);
        }
      }
      else {
        return this.describeAppointment(next);
      }
      // TODO if multiple appointments are allowed per user, we might
      // have a second column, named 'First appointment: Yes/No'
    }
  }

  describeAppointment(appointment) {
    switch(appointment.situation) {
      case 'pending':
      case 'approved':
          return <>
            <Link to={`/admin/appointments/${appointment.id}`} style={{textDecoration: 'underline', textColor: '#1E2B4E'}}>
              { window.gettext('Próximo: %1', this.formatDate(appointment.datetime_start)) }
            </Link>
          </>
        //window.gettext('Próximo: %1', this.formatDate(appointment.datetime_start));
      case 'started':
          return window.gettext('Iniciou em %1', this.formatDate(appointment.datetime_start));
      case 'done':
          return window.gettext('Último: %1', this.formatDate(appointment.datetime_start));
      case 'canceled':
          return window.gettext('Cancelou o horário %1', this.formatDate(appointment.datetime_start));
    }
  }

  formatDate(date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  render() {
    return (
      <div className='user-overview-table'>
        <Table responsive>
          <thead>
            <tr>
              <th>
                {window.gettext('Usuário')}
                </th>
              <th>
                {window.gettext('Atividade')}
              </th>
              <th>
                {window.gettext('Progresso')}
              </th>
              <th>
                {window.gettext('Agendamento')}
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.users.map(user => (
              <tr key={'dashboard-pu-' + user.id}>
                <td>
                  <Row>
                    <Col className="user-avatar" sm="auto">
                      <img
                        src={user.user.pictureUrl || defaultAvatar}
                      />
                    </Col>
                    <Col className="user-name" sm="auto">
                      {user.user.name}<br/>
                      <Link to={`/admin/organizations/${this.organizationId}/person/${user.id}`}> Ver Detalhes</Link>
                    </Col>
                  </Row>
                </td>
                <td>
                  {this.renderServices(user.services)}
                </td>
                <td>
                  {this.renderProgress(user.services)}
                </td>
                <td style={{maxWidth: '150px'}}>
                  {user.appointments
                    ? this.describeSituation(user.appointments)
                    : window.gettext('Sem agendamento') }
                </td>
              </tr>
            ))}

          </tbody>
        </Table>
      </div>
    );
  }

  renderServices(services) {
    return (
      <React.Fragment>
        {services.map(service =>
          <p className="program-name">{service.name}</p>
        )}
      </React.Fragment>
    )
  }

  renderProgress(services) {
    return (
      <React.Fragment>
        {services.map(service =>
          <div className="program-progress">
            <Progress
              max={service.quota}
              value={service.usage}
              barClassName="progress-bar-info"
            />
            <span>
              ({service.usage + '/' + service.quota})
            </span>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default OrganizationUsage;
