import React from "react";

// reactstrap components
import {Card, CardBody, CardHeader, CardTitle, Col, Row, Table,} from "reactstrap";

import apiRequest from "lib/Api";
import defaultAvatar from "../../assets/img/default-avatar.png";
import moment from "moment-timezone";

class UserUsage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                usage: {
                    detail: [],
                    cancelled: 0,
                    waiting: 0,
                    done: 0,
                    noshow: 0,
                    total: 0,
                },
                user: {
                    name : '',
                    organization: '',

                }

            }
        };
    }

    get userId() {
        return this.props.match.params.user_id;
    }

    get organizationId() {
        return this.props.match.params.organization_id || '';
    }

    async componentDidMount() {
        await this.requestData();
    }

    async requestData() {
       let response =  await apiRequest(`/users/${this.userId}/usage?organization=${this.organizationId}`,
           {method: "GET"})
       this.setState({data: response.data.data})

    }

    formatDate(date) {
        if (date !== null) {
            return moment(date).format('DD/MM/YYYY HH:mm');
        }
        return  '-'
    }
    render() {
        let data = this.state.data
        let company = ''
        if (data.user.organization) {
            company = data.user.organization.name;
        }

        return (
            <>
            <div className="content">
                <Row>
                    <Col lg="12" md="12" sm="12">
                        <Card >
                            <CardBody>
                                <Row>
                                    <Col lg="2" md="2" sm="2" className="user-avatar">
                                        <img style={{width:'100px'}}
                                            src={data.user.pictureUrl || defaultAvatar}
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <b>Nome:</b>
                                        <br/>
                                            <h4>{data.user.name}</h4>
                                    </Col>
                                    <Col md={5}>
                                        <b>Empresa:</b>
                                        <br/>
                                        <h4>{company || ''}</h4>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="3" md="4" sm="4">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-calendar-60 text-info"/>
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">
                                                {window.gettext('Agendado')}</p>
                                            <CardTitle tag="p">{data.usage.waiting}</CardTitle>
                                            <p/>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3" md="4" sm="4">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-calendar-60 text-success"/>
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">
                                                {window.gettext('Realizados')}</p>
                                            <CardTitle tag="p">{data.usage.done}</CardTitle>
                                            <p/>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3" md="4" sm="4">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-calendar-60 text-danger"/>
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">
                                                {window.gettext('Cancelados')}</p>
                                            <CardTitle tag="p">{data.usage.cancelled}</CardTitle>
                                            <p/>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3" md="4" sm="4">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-calendar-60 text-warning"/>
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">
                                                {window.gettext('Cancelado com menos de 24 horas')}</p>
                                            <CardTitle tag="p">{data.usage.noshow}</CardTitle>
                                            <p/>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" md="12" sm="12">
                        <Card>
                            <CardHeader>
                                <CardTitle>Serviços Executados</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead>
                                    <tr>
                                        <th>
                                            {window.gettext('Ordem')}
                                        </th>
                                        <th>
                                            {window.gettext('Data')}
                                        </th>
                                        <th>
                                            {window.gettext('Serviço')}
                                        </th>
                                        <th>
                                            {window.gettext('Situação')}
                                        </th>
                                        <th>
                                            {window.gettext('Especialista')}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.usage.detail.map((item, seq) => (
                                        <tr key={'dashboard-pu-' + seq}>
                                            <td>
                                                {item.order}
                                            </td>
                                            <td>
                                                {this.formatDate(item.date)}
                                            </td>
                                            <td>
                                                {item.service_name}<br/>
                                                <small>{item.service_display_name}</small>
                                            </td>
                                            <td>
                                            {item.situation}
                                            </td>
                                            <td >
                                                {item.specialist_name}
                                            </td>
                                        </tr>
                                    ))}

                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            </>
        )
    }
}

export default UserUsage;
