import React from 'react'
import { getUserData, updateUserData } from '../../../lib/Auth'
import apiRequest from '../../../lib/Api'
import withNotify from '../../../lib/NotificationWrapper'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader, CardSubtitle,
  CardTitle,
  Col, Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import InputError from '../../components/InputError'
import { Redirect } from 'react-router-dom'

class ActivationSetup extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      token: null,
      user: { name: '' },
      new_password: '',
      new_password_confirmation: '',
      working: false,
      redirect: false,
      errors: {}

    }
  }

  async componentDidMount () {

    let token = this.props.match.params.activation_code || ''

    try {
      let response = await apiRequest(`/auth/users/activation/${token}`, { method: 'GET' })
      this.setState({ token, user: response.data.data })
    } catch (e) {
      this.setState({token: null})
    }

  }

  handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value

    this.setState({
      [name]: value
    })

    let newErrors = { ...this.state.errors, [name]: '' }
    this.setState({ errors: newErrors })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    let {new_password, new_password_confirmation } = this.state
    let data = {
      new_password,
      new_password_confirmation
    }

    this.setState({ working: true })

    let url = `/auth/users/activate/${this.state.token}`

    let request = apiRequest(url, { data, method: 'POST' })
    request.then(response => {
      this.props.notify({ type: 'success', message: window.gettext('Senha alterada com sucesso') })
      setTimeout(() => {
          this.setState({ redirect: true })
      }, 400)
    })
      .catch(err => {
        this.setState({ errors: err.data.errors })
        })
      .finally(
        response => this.setState({ working: false })
      )
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={'/auth/login'}/>
    }
    let hasError = InputError.convertToHasClass(this.state.errors)
    if (!this.state.token) {
      return <>
        <div className="content">
          <div className="text-center">
            "<h4>Código já utilizado ou inválido.</h4>
          </div>
        </div>
      </>
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Form action="" className="form" method="POST"
                    onSubmit={this.handleSubmit}
              >
              <Card>
                <CardHeader>
                  <CardTitle tag="h4"> Ola! {this.state.user.name}!</CardTitle>
                  <CardSubtitle>Bem vindo a SelfGuru. Para iniciar sua jornada, defina uma senha e em seguida
                    realize seu login</CardSubtitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Label sm="2">{window.gettext('Senha')}</Label>
                    <Col sm="10">
                      <FormGroup className={hasError.new_password}>
                        <Input
                          value={this.state.new_password}
                          name="new_password"
                          onChange={this.handleChange}
                          type="password"
                        />
                        <InputError name="new_password" errors={this.state.errors}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">{window.gettext('Confirme senha nova')}</Label>
                    <Col sm="10">
                      <FormGroup className={hasError.new_password_confirmation}>
                        <Input
                          value={this.state.new_password_confirmation}
                          name="new_password_confirmation"
                          onChange={this.handleChange}
                          type="password"
                        />
                        <InputError name="new-new_password_confirmation" errors={this.state.errors}/>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col md="2"/>
                    <Col md="10">
                      <Button className="btn-round" color="info" type="submit"
                              disabled={this.state.working}
                      >
                        {window.gettext('Salvar')}
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default withNotify(ActivationSetup)
