import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import OrganizationUsageWidget from "views/components/OrganizationUsage";

class OrganizationUsage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: {}
    }
  }

  get id() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    this.requestData();
  }

  requestData() {
    apiRequest("/organizations/" + this.id, {method: "GET"})
      .then(response => {
        let {data} = response.data;
        this.setState({organization: data});
      });
  }

  render() {
    let {organization} = this.state;
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    {window.gettext('Atividades cadastradas')}
                  </CardTitle>
                  <h5 className="card-category">{organization.name || window.gettext('carregando...')}</h5>
                </CardHeader>
                <CardBody>
                  <OrganizationUsageWidget organizationId={this.id} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default OrganizationUsage;
