const enviroment = {

    isNewUserInterface() {
        return process.env.REACT_APP_NEW_VI === 'true';
    },

    isLumiUserInterface() {
        let domain = (new URL(window.location));
        domain = domain.hostname;
        if (domain == process.env.REACT_APP_LUMI_DOMAIN) {
            return true;
        }
        return false;
    },

    getEadUrl() {
        return process.env.REACT_APP_EAD_URL;
    },
}
export default enviroment;
