import React from "react";
import {Link} from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormText
} from "reactstrap";

import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

import apiRequest from "../../lib/Api";
import {updateUserData} from "../../lib/Auth";
import withNotify from "../../lib/NotificationWrapper";
import {toCurrency} from "../../lib/Formatter";
import InputError from "../components/InputError";

import defaultAvatar from "assets/img/default-avatar.png";
import timezones from "../../variables/timezones";
import Select from "react-select";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.timeZones = timezones.map(timezone =>  ({label: timezone, value: timezone}))
    this.languages = [
      {label: window.gettext('Português'), value: 'pt_BR'},
      {label: window.gettext('Inglês'), value: 'en_US'},
    ]
    this.state = {
      user: {
        name: '',
        email: '',
        role: '',
        picture: '',
        bio: '',
        language: this.languages.find(option => option.value === 'pt_BR'),
        timezone: this.timeZones.find(option => option.value === 'America/Sao_Paulo'),

      },
      originalPicture: '',
      originalLanguage: '',
      purchases: [],
      working: false,
      errors: {},
      max_char : 620,
      chars_left : 620,
    };
  }

  componentDidMount() {
    this.fetchProfile()
      .then(user => {
        if (user.role === 'student') {
          this.fetchPurchases();
        }
      });
  }

  fetchProfile() {
    return apiRequest("/auth/me", {method: "POST"})
        .then(response => {
          this.setProfile(response.data);
          return response.data;
        });
  }

  handleWordCount = event => {

    let value = event.target.value;


    const charCount = value.length;
    const maxChar = this.state.max_char;
    const charLength = maxChar - charCount;
    if (charLength <= 0) {
      return;
    }
    this.setState({user: {...this.state.user, bio: value}});
    this.setState({chars_left: charLength});

  }
  setProfile(user) {
    let charLeft = this.state.max_char;
    if (user.bio) {
       charLeft = this.state.max_char - user.bio.length;
    }
    user.language = this.languages.find(option => option.value === user.language);
    user.timezone = this.timeZones.find(option => option.value === user.timezone);
    this.setState({
      chars_left: charLeft,
      user: {...user, picture: user.pictureUrl},
      originalPicture: user.pictureUrl,
      originalLanguage: user.language
    });

  }

  fetchPurchases() {
    return apiRequest("/purchases", {method: "GET"})
        .then(response => {
          this.setState({purchases: response.data.data});
        });
  }

  handleChange = (name, value) => {
    if (typeof name == 'object' && typeof name.target == 'object') {
      let event = name;
      event.preventDefault();
      let name = event.target.name;
      let value = event.target.value;
    }
    this.setState({
      user: {...this.state.user, [name]: value}
    });

    if (this.state.errors[name]) {
      this.setState({errors: {...this.state.errors, [name]: ''}});
    }
  }

  handleChangeImage = file => {
    this.setState({
      user: {...this.state.user, picture: file}
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {id: userId} = this.state.user;
    if (!userId) {
      return;
    }

    let {name, email, bio, picture, language, timezone} = this.state.user;
    let data = {name, email, bio};
    if (picture) {
      data.picture = picture;
    }
    data.language = language.value;
    data.timezone = timezone.value;
    let formData = new FormData()
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    // indicates api to not require all fields
    formData.append('update_profile', 1);

    this.setState({working: true});

    apiRequest("/users/" + userId, {data: formData, method: 'PUT'})
      .then(response => {
        let user = response.data.data;
        this.setProfile(user);
        updateUserData('pictureUrl', user.pictureUrl);
        updateUserData('language', data.language);
        this.props.notify({type: 'success', message: window.gettext('Informações armazenadas com sucesso')});
        window.location.reload();
      })
      .catch(err => {
        this.setState({errors: err.data.errors});
      })
      .finally(
        response => this.setState({working: false})
      );
  }

  renderPurchases() {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            {window.gettext('Compras')}
          </CardTitle>
        </CardHeader>
        <CardBody>

          {!this.state.purchases.length &&
            <p>
              {window.gettext('Nenhuma compra até o momento')}
            </p>
          }

          <ul className="list-unstyled team-members">

            {this.state.purchases.map(purchase => {
              return (
                <li key={purchase.id}>
                  {this.renderPurchase(purchase)}
                </li>
              );
            })}

          </ul>
        </CardBody>
      </Card>
    );
  }

  renderPurchase(item) {
    return (
      <Row>
        <Col md="7" xs="7">
          {item.product.name} <br />
          <span className="text-muted">
            <small>Desde {item.purchased_at}</small>
          </span>
        </Col>
        <Col className="text-right" md="5" xs="5">
          {toCurrency(item.price_paid)}
        </Col>
      </Row>
    );
  }

  render() {
    let avatar = this.state.user.pictureUrl || defaultAvatar;
    let hasError = InputError.convertToHasClass(this.state.errors);

    return (
      <>
        <div className="content">
          <Row>
            <Col md="4">
              <Card className="card-user">
                <div className="image">
                  <img
                    alt="..."
                    src={require("assets/img/bg-profile.jpg")}
                  />
                </div>
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar border-gray"
                        src={avatar}
                      />
                      <h5 className="title">{this.state.user.name}</h5>
                    </a>
                    <p className="description">{this.state.user.email}</p>
                  </div>
                </CardBody>
              </Card>

              {this.state.role === 'student' && this.renderPurchases()}

            </Col>

            <Col md="8">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card>
                  <CardHeader>
                    <h5 className="title">
                      {window.gettext('Editar informações')}
                      <small>
                        <Button tag={Link} to="/admin/users/me/edit" color="default" className="btn-link"
                          size="sm"
                        >
                          {window.gettext('Ver todos os campos')}
                        </Button>
                      </small>
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup className={hasError.name}>
                          <label>
                            {window.gettext('Nome')}
                          </label>
                          <Input
                            value={this.state.user.name}
                            name="name"
                            onChange={this.handleChange}
                            type="text"
                            placeholder={window.gettext("Seu nome completo")}
                          />
                          <InputError name="name" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className={hasError.email}>
                          <label>
                            {window.gettext('E-mail')}
                          </label>
                          <Input
                            value={this.state.user.email}
                            name="email"
                            placeholder={window.gettext("Seu e-mail")}
                            type="text"
                            readOnly={true}
                            disabled={true}
                          />
                          <InputError name="email" errors={this.state.errors} />
                          {/*<FormText color="default" tag="span">*/}
                          {/*  {window.gettext('Você poderá alterar a qualquer momento, se mudar de empresa ou de e-mail, mas fique atento que se alterar esse campo, automaticamente, altera seu login.')}*/}
                          {/*</FormText>*/}
                        </FormGroup>
                      </Col>
                    </Row>

                    {this.state.user.role === 'specialist' &&
                    <Row>
                      <Col md="12">
                        <FormGroup className={hasError.bio}>
                          <label>
                            {window.gettext('Bio')}
                          </label>
                          <Input type="textarea" name="bio" value={this.state.user.bio}
                            onChange={this.handleWordCount}
                                 maxLength={this.state.max_char}
                            placeholder={window.gettext("Digite um resumo breve sobre você")}
                            style={{height: '120px', 'max-height': '120px'}}
                          />
                          <InputError name="bio" errors={this.state.errors} />

                        </FormGroup>
                        {window.gettext('Caracteres restantes:')} {this.state.chars_left}/{this.state.max_char}
                      </Col>
                    </Row>
                    }
                    <Row>
                      <Label sm="2">
                        {window.gettext('Idioma da Aplicação')}
                      </Label>
                      <Col sm="4">
                        <FormGroup className={hasError.state}>
                          <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="language"
                              value={this.state.user.language}
                              onChange={value =>
                                  this.handleChange("language", value)
                              }
                              options={[
                                {
                                  value: "",
                                  label: window.gettext("Selecione um idioma"),
                                  isDisabled: true
                                },
                                ...this.languages
                              ]}
                              placeholder={window.gettext("Selecione um idioma")}
                          />
                          <InputError name="language" errors={this.state.errors}/>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        {window.gettext('Fuso Horário')}
                      </Label>
                      <Col sm="4">
                        <FormGroup className={hasError.state}>
                          <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="timezone"
                              value={this.state.user.timezone}
                              onChange={value =>
                                  this.handleChange("timezone", value)
                              }
                              options={[
                                {
                                  value: "",
                                  label: window.gettext("Selecione um fuso horário"),
                                  isDisabled: true
                                },
                                ...this.timeZones
                              ]}
                              placeholder={window.gettext("Selecione um fuso horário")}
                          />
                          <InputError name="language" errors={this.state.errors}/>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        {window.gettext('Imagem de perfil')}
                      </Label>
                      <Col md="10" sm="10">
                        <ImageUpload onChangeImage={this.handleChangeImage}
                          originalUrl={this.state.originalPicture}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button className="btn-round" color="info" type="submit"
                      disabled={this.state.working}
                    >
                      {window.gettext('Salvar')}
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withNotify(UserProfile);
