import React from "react";

// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardBody, CardDeck,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Row,
    Table
} from "reactstrap";
import {getUserData} from "../../../lib/Auth";
import withNotify from "../../../lib/NotificationWrapper";
import apiRequest from "../../../lib/Api";
import WorkPerformance from "./PerformanceGrou/WorkPerformance";
import SelfEfficacy from "./PerformanceGrou/SelfEfficacy";
import Hope from "./PerformanceGrou/Hope";
import Resilience from "./PerformanceGrou/Resilience";
import Optimism from "./PerformanceGrou/Optimism";
import OCBIndividual from "./PerformanceGrou/OCBIndividual";
import OCBOrganization from "./PerformanceGrou/OCBOrganization";
import SelfEvaluation from "./PerformanceGrou/SelfEvaluation";
import ConfrontationProactive from "./PerformanceGrou/ConfrontationProactive";
import JobCrafting from "./PerformanceGrou/JobCrafting";
import Conscientiousness from "./PerformanceGrou/Conscientiousness";
import {Redirect} from "react-router-dom";

class PerformanceGrouResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mustRequest: true,
            performance_id: null,
            results: [],
            weighted_average: 0,
            userLogged: getUserData()
        }
    }

    componentDidMount() {
        if (this.state.userLogged.role !== 'super_admin') {
            return <Redirect to={'/admin/dashboard'} />;
        }
        this.getResults();
    }

    async getResults () {
        if (!this.props.performanceId || this.state.mustRequest === false) {
            return;
        }
        return await apiRequest(`/performance-grou/${this.props.performanceId}/result`, {method: 'GET'})
            .then(
                response => {
                    this.setState({results: response.data.data, mustRequest:false})
                }
            );
    }

    render() {

        if (this.state.mustRequest && this.state.results.length === 0) {
            this.getResults()
        }
        return (
            <>
                <div className="content ancora-container">
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h3">
                                        {window.gettext('Resultado do Lumi')}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <WorkPerformance data={this.state.results.work_performance} />
                                    <SelfEfficacy data={this.state.results.self_efficacy} />
                                    <Hope data={this.state.results.hope} />
                                    <Resilience data={this.state.results.resilience} />
                                    <Optimism data={this.state.results.optimism} />
                                    <OCBIndividual data={this.state.results.ocb_individual} />
                                    <OCBOrganization data={this.state.results.ocb_organization} />
                                    <SelfEvaluation data={this.state.results.self_evaluation} />
                                    <ConfrontationProactive data={this.state.results.confrontation_proactive} />
                                    <JobCrafting data={this.state.results.job_crafting} />
                                    <Conscientiousness data={this.state.results.conscientiousness} />
                                </CardBody>
                            </Card>
                            {/*<Card>*/}
                            {/*    <CardHeader>*/}
                            {/*        <CardTitle tag="h3">*/}
                            {/*            {window.gettext('Média Ponderada') + ' ' +this.state.results.weighted_average}*/}
                            {/*        </CardTitle>*/}
                            {/*    </CardHeader>*/}
                            {/*</Card>*/}
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(PerformanceGrouResult);
