import React from "react";
import {Link} from 'react-router-dom';
import moment from 'moment';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
} from "reactstrap";

import Appointments from "models/Appointments";


class HomeSpecialist extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      nextAppointment: null
    };
  }
  
  componentDidMount() {
    Appointments.getNextAppointment().then(
      appointment => this.setState({nextAppointment: appointment})
    );
  }
  
  renderNextAppointmentCard() {
    return (
      <Card className="card-pricing">
        <CardHeader>
          <h6 className="card-category">Próxima conversa</h6>
        </CardHeader>
        <CardBody>
          <div className="card-icon icon-primary">
            <i className="nc-icon nc-calendar-60" />
          </div>                      
          <ul>
            <li>
              <b>
                {this.state.nextAppointment.user_name}
              </b>
            </li>
            <li>{moment(this.state.nextAppointment.datetime_start).format('LLL')}</li>
          </ul>
        </CardBody>
        <CardFooter>
          <Button
            className="btn-round"
            color="primary"
            tag={Link}
            to={`/admin/appointments/${this.state.nextAppointment.id}`}
          >
            Entrar
          </Button>
        </CardFooter>
      </Card>
    );
  }

  renderNoAppointmentCard() {
    return (
      <Card className="card-pricing">
        <CardHeader>
          <h6 className="card-category">Agendamentos</h6>
        </CardHeader>
        <CardBody>
          <div className="card-icon icon-primary">
            <i className="nc-icon nc-hat-3" />
          </div>
          <ul>
            <li>Não há agendamentos criados para os próximos dias.</li>
          </ul>
        </CardBody>
        <CardFooter>
          <Button
            className="btn-round"
            color="primary"
            tag={Link}
            to="/admin/schedule-standards"
          >
            Horários disponíveis
          </Button>
        </CardFooter>
      </Card>
    );
  }
  
  render() {
    let colParams = {size: 4, offset: 4};
    return (
      <>
        <div className="content dashboard-member-container">
          <Row>
            <Col md={{...colParams}}>
              {this.state.nextAppointment 
                ? this.renderNextAppointmentCard()
                : this.renderNoAppointmentCard()
              }
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default HomeSpecialist;
