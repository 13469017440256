import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table
} from "reactstrap";
import withNotify from "../../../lib/NotificationWrapper";
import {getUserData} from "../../../lib/Auth";
import apiRequest from "../../../lib/Api";
import TasksAnswer from "./TasksAnswer";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";

class SpecialistDiaryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: getUserData(),
      diaries: [],
      modal: false,
      taskSelected: null,
      forms: [],
      selectedProduct: {value:''},
      selectedUsers: [],
      userName: '',
      exportInterval: 30,
    }
  }

  componentDidMount() {
    this.getProducts();
    this.fetchDiaries();
  }

  fetchDiaries() {

    let data = {method: 'GET',
      data: {
        user_name: this.state.userName,
        product: this.state.selectedProduct.value || '',
        days: this.state.exportInterval,
        include: 'total_encounters'
      }}
    apiRequest('/program-diaries', data).then(
        success => {
          this.setState({diaries: success.data.data});
        }
    ).catch(error => {

    });
  }

  openModal(task) {
    this.setState({taskSelected: task});
  }

  onProductChange = (value) => {
    this.setState({selectedProduct: value})
  }

  getProducts = searchTerm => {

    let params = {
      search: searchTerm,
    };
    return apiRequest("/products", {data: params, method: 'GET'})
        .then(response => {
          return response.data.data.map(user => ({value: user.id, label: user.name}));
        });
  }

  renderSearchForm() {
    return (
        <>
          <Card>
            <CardBody>
              <Form className="form-horizontal">
                <Row>
                  <Label md="1">
                    {window.gettext('Usuário')}</Label>
                  <Col md="3">
                    <FormGroup>
                      <Input placeholder="Nome ou e-mail" type="text"
                             onChange={this.filterChange} value={this.state.userName}
                             onKeyPress={event => event.which === 13 && event.preventDefault()}
                      />
                    </FormGroup>
                  </Col>
                  <Label sm="1">
                    {window.gettext('Produto')}
                  </Label>
                  <Col sm="2">
                    <FormGroup>
                      <AsyncSelect
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="product_id"
                          value={this.state.selectedProduct}
                          onChange={this.onProductChange}
                          defaultOptions
                          loadOptions={this.getProducts}
                          placeholder={window.gettext("Selecione um produto")}
                      />
                    </FormGroup>
                  </Col>
                  <Label md="1">
                    {window.gettext('Dias')}
                  </Label>
                  <Col md="1 ">
                    <FormGroup>
                      <Input type="number" value={this.state.exportInterval}
                             onChange={this.changeInterval}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="1">
                    <Button color="info" type="button" style={{margin: '1px -15px'}}
                            onClick={this.executeFilter}
                    >
                      <i className="fa fa-search"/>
                    </Button>
                  </Col>

                  <Col md="1">
                    <Button color="default" type="button" style={{margin: 0}}
                            onClick={this.export}
                    >
                      {window.gettext('Exportar')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </>
    )
  }

  filterChange = (event) => {
    let value = event.target.value;
    this.setState({userName: value});
  }

  executeFilter = (event) => {
    event.preventDefault();
    this.fetchDiaries();
  }

  renderTaskRow(diary, index) {

    let meeting = '';
    if (diary.purchase_user && diary.purchase_user.purchase && diary.purchase_user.purchase.product) {
       meeting = diary.purchase_user.purchase.product.name;
    }

    let specialistName = diary.specialist ? diary.specialist.name : ' - ';

    return (
        <tr key={index}>
          <td>{diary.user.name}</td>
          <td>{specialistName}</td>
          <td>{meeting}</td>
          <td>{diary.total_encounters}</td>
          <td className='text-center'>
            <Button
                className="btn-icon"
                color="info"
                size="sm"
                type="button"
                disabled={diary.total_encounters === 0}
                onClick={() => this.print(diary)}

            >
              <i className="fa fa-print"/>
            </Button>
          </td>
        </tr>
    );
  }

  print = async (diary) => {
    let response = await apiRequest(`/program-diaries/${diary.uuid}/print`, {method: "GET"});
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_API_HOST}/temp/${response.data.data}`;
    link.target = '_blank';
    link.setAttribute('download', response.data.data);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }
  export = () => {
    let data = {
      user_name: this.state.userName,
      product: this.state.selectedProduct.value || '',
      days: this.state.exportInterval,
    };

    apiRequest("/tasks/export/cvs", {method: "GET", data}, {blob: true})
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'tasks.xlsx');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
  }

  render() {

    let tasks = this.state.diaries;
    return (
        <>
          <TasksAnswer
              task={this.state.taskSelected}
              disabledForm={true}
              onClose={() => {
                this.setState({taskSelected: null})
              }}
          />
          <div className="content">
            {this.renderSearchForm()}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{window.gettext('Diários do Programa')}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table responsive>
                      <thead className="text-primary">
                      <tr>
                        <th>{window.gettext('Usuário')}</th>
                        <th>{window.gettext('Especialista')}</th>
                        <th>{window.gettext('Programa')}</th>
                        <th>{window.gettext('Encontros')}</th>
                        <th>{window.gettext('Ações')}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {tasks.map((diary, index) => {
                        return this.renderTaskRow(diary, index)
                      })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    );
  }
}

export default withNotify(SpecialistDiaryList);
