import React from "react";
import {Link} from 'react-router-dom'

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col, Form,
    FormGroup, Input,
    Label,
    Row,
    Table,
    UncontrolledTooltip
} from 'reactstrap'

import apiRequest from "../../../lib/Api";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import withNotify from "../../../lib/NotificationWrapper";
import {formatCnpj, formatPhone} from "../../../lib/Formatter";

import PaginationCtrl from "components/Pagination/PaginationCtrl";
import {getUserData} from "../../../lib/Auth";

class OrganizationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: getUserData() || {},
            items: [],
            meta: {},
            search: ''
        };
    }

    componentDidMount() {
        this.requestData();
    }

    requestData = page => {
        if (!page) {
            page = 1;
        }
        let data = {page, search: this.state.search};
        apiRequest("/organizations", {data, method: "GET"})
            .then(response => {
                let {data, meta} = response.data;
                this.setState({items: data, meta});
            });
    }

    toggleConfirmation = (show) => {
        this.setState({showDeleteConfirmation: show});
    }

    maybeDelete = (id) => {
        this.setState({deleteId: id});
        this.toggleConfirmation(true);
    }

    delete = () => {
        const id = this.state.deleteId;
        apiRequest("/organizations/" + this.state.deleteId, {method: "DELETE"})
            .then(() => {
                this.props.notify({type: 'success', message: window.gettext('Empresa removida com sucesso')});

                let items = this.state.items.filter(item => item.id !== id);
                this.setState({items});
            });
    }

    filterChange = (field, value) => {

        this.setState({[field]: value});
    }
    executeFilter = (event) => {
        event.preventDefault();
        this.requestData();
    }

    renderActionButtons( id ){
        if (this.state.user.id === 360) {
            return (<></>)
        }
        return (
            <>
            <Button
                tag={Link}
                className="btn-icon btn-neutral"
                color="warning"
                id="tooltip264453216"
                size="sm"
                to={`/admin/users/list/organization/${id}`}
            >
                <i className="fa fa-users"/>
            </Button>

        <Button
            tag={Link}
            className="btn-icon btn-neutral"
            color="success"
            id="tooltip264453216"
            size="sm"
            to={`/admin/organizations/${id}/edit`}
        >
            <i className="fa fa-edit"/>
        </Button>

        <Button
            className="btn-icon btn-neutral"
            color="danger"
            id="tooltip476609793"
            size="sm"
            type="button"
            onClick={() => this.maybeDelete(id)}
        >
            <i className="fa fa-times"/>
        </Button>
            </>
        )


    }
    render() {
        let {items} = this.state;

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        {window.gettext('Lista')}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form className="form-horizontal">
                                        <Row>
                                            <Label md="1">
                                                {window.gettext('Buscar')}</Label>
                                            <Col md="5">
                                                <FormGroup>
                                                    <Input placeholder="Nome da empresa ou Cnpj" type="text"
                                                           onChange={(event) => {
                                                               this.filterChange('search', event.target.value)
                                                           }
                                                           } value={this.state.search}
                                                           onKeyPress={event => event.which === 13 && event.preventDefault()}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <Button color="info" type="button" style={{margin: '1px -15px'}}
                                                        onClick={this.executeFilter}
                                                >
                                                    <i className="fa fa-search"/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Table responsive>
                                        <thead className="text-primary">
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th>
                                                {window.gettext('Nome')}
                                            </th>
                                            <th>{window.gettext('CNPJ')}
                                            </th>
                                            <th>
                                                {window.gettext('Cidade')}
                                            </th>
                                            <th>
                                                {window.gettext('Telefone')}
                                            </th>
                                            <th className="text-right">
                                                {window.gettext('Ações')}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {items.map(item => {


                                            return <tr key={item.id}>
                                                <td className="text-center">{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{formatCnpj(item.cnpj)}</td>
                                                <td>{item.city + ' - ' + item.state}</td>
                                                <td>{formatPhone(item.phone)}</td>

                                                <td className="text-right">

                                                    <Button
                                                        tag={Link}
                                                        className="btn-icon btn-neutral"
                                                        color="info"
                                                        id="tooltip264453216"
                                                        size="sm"
                                                        to={`/admin/organizations/${item.id}/usage`}
                                                    >
                                                        <i className="fa fa-dashboard"/>
                                                    </Button>

                                                    {this.renderActionButtons(item.id)}

                                                </td>
                                            </tr>;

                                        })}

                                        </tbody>
                                    </Table>
                                    <PaginationCtrl responseMeta={this.state.meta}
                                                    requestPage={this.requestData}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>

                <ConfirmModal message={window.gettext("Tem certeza que deseja remover esta empresa?")}
                              onConfirm={() => this.delete()}
                              onToggle={() => this.toggleConfirmation(false)}
                              open={this.state.showDeleteConfirmation}
                />
            </>
        );
    }
}

export default withNotify(OrganizationList);
