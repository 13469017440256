import React from "react";
import apiRequest from "../../lib/Api";
// reactstrap components
import {Badge} from "reactstrap";
import {withRouter} from "react-router-dom";
import { NotificationService } from '../../services/NotificatioService';
// for testing
class NotificationIcon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user || {user: {name: ''}},
            userList: [],
            isOpen: false,
            messageCount: this.props.messageCount || 0,
            label: this.props.label || ''
        }
        this.timeOut = null;
    }

    getNotifications = () => {
        apiRequest("/me/notifications", {method: "GET", loader: false})
            .then((response) => {
                this.setState({messageCount: response.data.data.total});
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.timeOut !== null) {
            clearInterval(this.timeOut);
            this.timeOut = setInterval(() => {
                this.getNotifications();
            }, 15000)
        }
    }

    componentDidMount() {
        this.subscription = NotificationService.getSubject().subscribe(message => {
            if (message) {
                this.getNotifications();
            }
        });
        this.getNotifications();
        this.timeOut = setInterval(() => {
            this.getNotifications();
        }, 15000)
    }

    componentWillUnmount() {
        clearInterval(this.timeOut);
    }

    openTasks = () => {
        this.props.history.push('/admin/my-tasks');
    }


    renderMessageCount() {
        if (this.state.messageCount === 0) {
            return <></>
        }
        return <Badge color="danger" style={{position: 'relative', top: '-20px', left: '-10px'}} pill>
            {this.state.messageCount}</Badge>
    }

    render() {
        return (
            <>
                <span
                    style={{marginRight: '4px'}}
                    onClick={this.openTasks}
                > {this.state.label} {"  "}
                    <i className="fa fa-bell" style={{fontSize: '25pt'}}/>
                    {this.renderMessageCount()}
                </span>
            </>
        );

    }
}

export default withRouter(NotificationIcon)
