import React from "react";
import withNotify from "../../../lib/NotificationWrapper";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Row, Table, UncontrolledTooltip} from "reactstrap";
import apiRequest from "../../../lib/Api";
import {Link} from "react-router-dom";
import ConfirmModal from "../../../components/Modals/ConfirmModal";

class ListExtendedData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            working: false,
            errors: {},
            items: [],
            showDeleteConfirmation: false,
            deleteDataId: null
        }
    }

    componentDidMount() {
        this.requestData();
    }

    requestData = page => {
        let data = {
            page: page || 1
        };

        apiRequest("/extended-data", {method: "GET"})
            .then(response => {
                let {data} = response.data;
                this.setState({items: data});
            });
    }

    maybeDeleteData = (id) => {
        this.setState({deleteDataId: id});
        this.toggleConfirmation(true);
    }

    toggleConfirmation = (show) => {
        this.setState({showDeleteConfirmation: show});
    }

    deleteData = () => {
        const id = this.state.deleteDataId;
        apiRequest("/extended-data/" + this.state.deleteDataId, {method: "DELETE"})
            .then(() => {
                this.props.notify({
                    type: 'success',
                    message: window.gettext('Informações adicionais foram removidas com sucesso')
                });
                this.requestData();
            })
            .catch(() => {
                this.props.notify({
                    type: 'error',
                    message: window.gettext('Não foi possível remover os dados Adicionais')
                });
            });
    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        {window.gettext('Informações Adicionais Cadastradas')}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Table responsive>
                                        <thead className="text-primary">
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th>
                                                {window.gettext('Nome')}
                                            </th>
                                            <th>
                                                {window.gettext('Aonde')}
                                            </th>
                                            <th className="text-right">
                                                {window.gettext('Ações')}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.items.map(item => {

                                            return <tr key={item.id}>
                                                <td className="text-center">{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{item.place}</td>
                                                <td className="text-right">
                                                    <Button
                                                        tag={Link}
                                                        className="btn-icon btn-neutral"
                                                        color="success"
                                                        id="tooltip264453216"
                                                        size="sm"
                                                        to={`/admin/informacoes-adicionais/${item.id}/edit`}
                                                    >
                                                        <i className="fa fa-edit"/>
                                                    </Button>{" "}
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip264453216"
                                                    >
                                                        {window.gettext('Editar')}
                                                    </UncontrolledTooltip>

                                                    <Button
                                                        className="btn-icon btn-neutral"
                                                        color="danger"
                                                        id="tooltip476609793"
                                                        size="sm"
                                                        type="button"
                                                        onClick={() => this.maybeDeleteData(item.id)}
                                                    >
                                                        <i className="fa fa-times"/>
                                                    </Button>{" "}
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip476609793"
                                                    >
                                                        {window.gettext('Remover')}
                                                    </UncontrolledTooltip>
                                                </td>
                                            </tr>;

                                        })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <ConfirmModal message={window.gettext('Confirma a exclusão dos dados adicionais?')}
                              onConfirm={() => this.deleteData()}
                              onToggle={() => this.toggleConfirmation(false)}
                              open={this.state.showDeleteConfirmation}
                />
            </>
        )
    }
}

export default withNotify(ListExtendedData);
