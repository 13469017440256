import React from "react";
import {Redirect} from "react-router-dom";
import AsyncSelect from "react-select/lib/Async";
import apiRequest from "lib/Api";
import withNotify from "lib/NotificationWrapper";
// reactstrap components
import {
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup,
    Button, CardHeader, CardTitle, Label, Input
} from "reactstrap";
import InputError from "../../components/InputError";
import defaultAvatar from "../../../assets/img/default-avatar.png";
import {getUserData} from "../../../lib/Auth";

const DEFAULT_DURATION = 30;

class AppointmentCreateAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: '',
            specialist_id: '',
            service_id: '',
            redirect: '',
            specialists: [],
            confirmAlert: '',
            errors: {},
            purchaseServices: [],
            selectedPurchaseService: null,
            selectedSpecialist: null,
            loadingServices: false,
            serviceStatus: null,
            working: false,
            changeToRoute: '',
            userData: null
        };
    }

    componentDidMount() {
        const user = getUserData();
        this.setState({userData: user});
    }

    /**
     * Retorna os serviços habilitados para o usuário
     * @returns {Promise<* | {data: *, statusText: *, status: *}>}
     */
    loadEnabledServices() {
        let {user_id} = this.state;
        let data = {user_id: user_id.value};

        if (!user_id) {
            return;
        }

        this.setState({loadingServices: true});

        return apiRequest("/purchase-services", {data, method: "GET"}).then(response => {
            this.setState({
                purchaseServices: response.data.data,
                loadingServices: false
            });
        });
    }

    loadUsers = searchTerm => {
        let params = {
            searchRole: 'student',
            search: searchTerm,
            onlyAppointments: 1
        };
        return apiRequest("/users", {data: params, method: 'GET'})
            .then(response => {
                return response.data.data.map(user => ({value: user.id, label: user.name}));
            });
    }

    /**
     * Controle do evento change do usuário
     * @param value
     */
    onUserChange = value => {
        this.setState({user_id: value}, () => {
            this.loadEnabledServices();
        });
    }

    /**
     * Carrega os especialistas
     * @param purchaseService
     * @returns {Promise<* | {data: *, statusText: *, status: *}>}
     */
    loadSpecialists(purchaseService) {
        let params = {
            searchRole: 'specialist',
        }
        if (!purchaseService) {
            return;
        }
        this.setState({service_id: purchaseService})

        params.productServiceId = purchaseService.productService.id;
        return apiRequest("/users", {data: params, method: "GET"})
            .then(response => {
                let specialists = response.data.data;
                this.setState({specialists: specialists});
                if (!specialists.length) {
                    this.props.notify({
                        type: 'warning',
                        message: window.gettext('Não há especialistas disponíveis para este serviço.')
                    });
                }
            });
    }

    createRoute(specialist) {
        let route;
        if (this.layout === 'setup') {
            route = 'appointments/new-with';
        } else {
            route = 'appointments/with';
        }
        return `/admin/${route}/${specialist}/${this.state.service_id.id}/${this.state.user_id.value}`;
    }

    /**
     * Controle do evento Change do usuário
     * @param event
     */
    onServiceChange = event => {
        let purchaseServiceId = parseInt(event.target.value);
        let purchaseService = this.state.purchaseServices.find(ps => ps.id === purchaseServiceId);
        this.setState({selectedPurchaseService: purchaseServiceId});

        // if (this.isSpecialist()) {
        //     return;
        // }
        this.loadSpecialists(purchaseService);
    }

    isSpecialist() {
        if (this.state.userData && this.state.userData.role) {
            return this.state.userData.role === 'specialist';
        }
        return false;
    }

    /**
     * difine qual o profissinal, e carrega agenda do mesmo
     * @param specialist
     */
    choose(specialist) {

        this.setState({selectedSpecialist: specialist});
        const route = this.createRoute(specialist);
        this.setState({changeToRoute: route});

    }

    renderSpecialists() {

        if (this.isSpecialist()) {
            return <></>
        }
        return <Row className="no-gutters">
            <Label sm="2">
                {window.gettext('Especialistas')}
            </Label>
            {this.state.specialists.map((specialist, idx) =>
                <Col sm="2" key={specialist.id}>
                    <div
                        className={`specialist-card ${(idx % 2 === 0 && this.layout === 'setup') ? 'even' : 'odd'}`}>
                        <div className="specialist-card-image">
                            <img src={specialist.pictureUrl || defaultAvatar}
                                 style={{maxHeight: 150 + 'px'}}/>
                        </div>
                        <div className="specialist-card-info">
                            <h5 className="specialist-title">
                                {specialist.name}
                            </h5>
                            <div className="specialist-btn-container">
                                <Button className="btn-round btn-sm" color="info"
                                        type="button"
                                        onClick={() => this.choose(specialist.id)}
                                >
                                    {window.gettext('Escolher')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
            )}
        </Row>
    }

    renderButton() {
        if (!this.isSpecialist()) {
            return <></>;
        }
        return <Row>
            <Col className="text-right">
                <Button className="btn-md" color="info"
                        type="button"
                        disabled={this.state.selectedPurchaseService === null}
                        onClick={() => this.choose(this.state.userData.id)}
                >
                    {window.gettext('Avançar')}
                </Button>
            </Col>
        </Row>
    }

    mustDisable(purchaseService) {
        const available = (purchaseService.quota - purchaseService.usage)
        return this.isSpecialist() && available <= 0;
    }

    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);
        if (this.state.changeToRoute) {
            return <Redirect to={this.state.changeToRoute}/>;
        }

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Form action="" className="form" method="POST"
                                  onSubmit={this.handleSubmit}
                            >
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            {window.gettext('Agendamento de Horário')}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('Usuário')}
                                            </Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.user_id}>
                                                    <AsyncSelect
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="user_id"
                                                        value={this.state.user_id}
                                                        onChange={this.onUserChange}
                                                        defaultOptions
                                                        loadOptions={this.loadUsers}
                                                        placeholder={window.gettext('Selecione um usuário')}
                                                    />
                                                    <InputError name="user_id" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">
                                                {window.gettext('Serviços habilitados')}
                                            </Label>
                                            <Col className="checkbox-radios" sm="10">
                                                {this.state.loadingServices && (
                                                    <span>
                                                        {window.gettext('Carregando serviços...')}
                                                    </span>
                                                )}
                                                {!this.state.loadingServices && this.state.purchaseServices.map(purchaseService => (
                                                    <div className="form-check-radio"
                                                         key={'purchase-service-' + purchaseService.id}>
                                                        <Label check>
                                                            <Input type="radio"
                                                                   checked={this.state.selectedPurchaseService === purchaseService.id}
                                                                   value={purchaseService.id}
                                                                   onChange={this.onServiceChange}
                                                                   disabled={this.mustDisable(purchaseService)}
                                                                   name="purchase-service"
                                                            />
                                                            <span className="form-check-sign"/>
                                                            {purchaseService.name} ({window.gettext('consultas Disponíveis:')}&nbsp;
                                                            <b>{purchaseService.quota - purchaseService.usage}</b>)
                                                        </Label>
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                        {this.renderSpecialists()}
                                        {this.renderButton()}
                                    </CardBody>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(AppointmentCreateAdmin);
