import React from "react";
import {Redirect} from "react-router-dom";
import queryString from 'query-string';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import apiRequest from "lib/Api";
import {saveAuth, getRedirectUrl, clearRedirect} from "lib/Auth";
import withNotify from "lib/NotificationWrapper";
import InputError from "views/components/InputError";
import SocialLogin from "views/components/SocialLogin";


class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      email_confirmation: '',
      password: '',
      password_confirmation: '',
      seenTerms: false,
      checkedTerms: false,
      working: false,
      errors: {},
      loggedIn: !!localStorage.getItem('accessToken')
    }
  }

  componentDidMount() {
    document.body.classList.toggle("register-page");
  }

  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }

  handleChange = (e) => {
    let {name, value} = e.target;
    this.setState({[name]: value});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let {name, email, email_confirmation, password, password_confirmation} = this.state;
    let data = {name, email, email_confirmation, password, password_confirmation};

    this.setState({working: true});

    apiRequest("/auth/register", {data})
      .then(response => {
        saveAuth(response.data);
        this.setState({loggedIn: true});
      })
      .catch(err => {
        this.setState({errors: err.data.errors || {password: window.gettext('E-mail ou senha inválidos')}});
      })
      .finally(
        response => this.setState({working: false})
      );
  }

  getRedirectUrl() {
    let qs = this.props.location.search || '';
    if (qs) {
      let params = queryString.parse(qs);
      if (params.redirect === 1) {
        return getRedirectUrl();
      }
    }
    return '/admin/dashboard';
  }

  terms = e => {
    e.preventDefault();
    window.open("https://selfguru.com.br/termos-e-condicoes/", "blank");
    this.setState({seenTerms: true});
  }

  checkTerms = e => {
    // eslint-disable-next-line
    let {checked} = e.target;
    if (!this.state.seenTerms) {
      this.props.notify({type: 'warning', message: window.gettext('Você ainda não leu os termos e condições da nossa plataforma.')});
    }
    else {
      this.setState({checkedTerms: true});
    }
  }

  render() {
    if (this.state.loggedIn) {
      let redirectTo = this.getRedirectUrl();
      clearRedirect();
      return <Redirect to={redirectTo} />;
    }

    let hasError = InputError.convertToHasClass(this.state.errors);

    return (
      <div className="register-page">
        <Container>
          <Row>
            <Col className="ml-auto" lg="5" md="5">
              <div className="info-area info-horizontal mt-5">
                <div className="icon icon-primary">
                  <i className="nc-icon nc-tv-2" />
                </div>
                <div className="description">
                  <h5 className="info-title">
                    {window.gettext('Para estudantes')}</h5>
                  <p className="description">
                    {window.gettext('Prepare-se para entrar no mercado de trabalho.')}
                  </p>
                </div>
              </div>
              <div className="info-area info-horizontal">
                <div className="icon icon-primary">
                  <i className="nc-icon nc-html5" />
                </div>
                <div className="description">
                  <h5 className="info-title">
                    {window.gettext('Para profissionais')}</h5>
                  <p className="description">
                    {window.gettext('Invista na sua carreira.')}
                  </p>
                </div>
              </div>
              <div className="info-area info-horizontal">
                <div className="icon icon-info">
                  <i className="nc-icon nc-atom" />
                </div>
                <div className="description">
                  <h5 className="info-title">
                    {window.gettext('Para empresas')}
                  </h5>
                  <p className="description">
                    {window.gettext('Conheça e desenvolva seus talentos.')}
                  </p>
                </div>
              </div>
            </Col>
            <Col className="mr-auto" lg="4" md="6">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card className="card-signup text-center">
                  <CardHeader>
                    <CardTitle tag="h4">
                      {window.gettext('Cadastre-se')}
                    </CardTitle>
                    <div className="social">
                      <SocialLogin />
                      <p className="card-description">
                        {window.gettext('ou preencha abaixo')}
                      </p>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <FormGroup className={hasError.name}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Nome" type="text" name="name"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputError name="name" errors={this.state.errors} />
                    </FormGroup>
                    <FormGroup className={hasError.email}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="E-mail" type="email" name="email"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputError name="email" errors={this.state.errors} />
                    </FormGroup>
                    <FormGroup className={hasError.email_confirmation}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder={window.gettext("Confirme o e-mail")} type="email" name="email_confirmation"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputError name="email_confirmation" errors={this.state.errors} />
                    </FormGroup>
                    <FormGroup className={hasError.password}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder={window.gettext("Senha")} type="password" name="password"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputError name="password" errors={this.state.errors} />
                    </FormGroup>
                    <FormGroup className={hasError.password_confirmation}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder={window.gettext("Confirme a senha")} type="password"
                          name="password_confirmation"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputError name="password_confirmation" errors={this.state.errors} />
                    </FormGroup>
                    <FormGroup check className="text-left">
                      <Label check>
                        <Input type="checkbox" onChange={this.checkTerms}
                          checked={this.state.checkedTerms}
                        />
                        <span className="form-check-sign" />
                        {window.gettext('Li e concordo com os')} {" "}
                        <b><a onClick={this.terms}>
                          {window.gettext('termos e condições')}
                        </a></b>
                        .
                      </Label>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Button
                      className="btn-round"
                      color="info"
                      disabled={this.state.working || !this.state.checkedTerms}
                    >
                      {window.gettext('Iniciar')}
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/pattern.png")})`
          }}
        />
      </div>
    );
  }
}

export default withNotify(Register);
