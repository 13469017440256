import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import AsyncSelect from "react-select/lib/Async";

// reactstrap components
import {Button, ButtonGroup, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Row, Table} from "reactstrap";

import {getUserData} from "lib/Auth";
import apiRequest from "lib/Api";
import withNotify from "lib/NotificationWrapper";

class Ancora extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: [],
            // answers: [...Array(41).keys()].map(n => Math.ceil(Math.random() * 6)),  // RANDOM
            step: 1,
            nextStepModal: '',
            changed: false,
            userData: getUserData(),
            selectedUser: '',
            ancoraId: 0
        }
    }

    componentDidMount() {
        this.loadData();
    }

    get role() {
        return this.state.userData.role;
    }

    loadData() {
        let params = {};
        if (this.state.selectedUser) {
            params.user_id = this.state.selectedUser.value;
        }

        return apiRequest("/ancoras", {method: 'GET', data: params})
            .then(response => {
                let [ancora] = response.data.data;
                if (ancora) {
                    let {answers} = ancora;
                    // add some stuff at the beginning, so that real questions start at index 1
                    answers.unshift(null);
                    this.setState({answers: ancora.answers, step: 4, ancoraId: ancora.id});
                } else {
                    this.reset();
                }
            });
    }

    loadUsers = searchTerm => {
        let params = {
            searchRole: 'student',
            search: searchTerm,
            onlyAppointments: 1
        };
        return apiRequest("/users", {data: params, method: 'GET'})
            .then(response => {
                return response.data.data.map(user => ({value: user.id, label: user.name}));
            });
    }

    changeUser = value => {
        this.setState({selectedUser: value}, () => this.loadData());
    }

    saveData() {
        let {answers, ancoraId} = this.state;
        answers.shift();

        let data = {answers};
        let request;
        if (ancoraId) {
            request = apiRequest("/ancoras/" + ancoraId, {method: 'PUT', data});
        } else {
            request = apiRequest("/ancoras", {method: 'POST', data});
        }

        return request.then(response => {
            this.props.notify(
                {
                    type: 'success',
                    message: window.gettext('Os dados desta avaliação foram salvos com sucesso.')
                }
            );
            this.setState({changed: false});
        });
    }

    reset = () => {
        this.setState({
            step: 1,
            answers: [],
            ancoraId: 0
        }, () => this.afterChange(1));
    }

    nextStep = () => {
        let {step} = this.state;
        if (step === 4) {
            return this.saveData();
        }
        if (this.beforeChange(step) === false) {
            return;
        }
        step++;
        this.renderModal(step)
            .then(() => {
                this.setState({step}, () => this.afterChange(step));
            });
    }

    answerQuestion = (event, questionId, option) => {
        let answers = [...this.state.answers];
        answers[questionId] = option;
        event.target.blur();
        this.setState({answers, changed: true});
    }

    beforeChange(currentStep) {
        let message = '';
        let {answers} = this.state;

        if (currentStep === 1) {
            let empty = QUESTIONS.filter((q, idx) => idx && !answers[idx]);
            if (empty.length) {
                message = empty.length === 1
                    ? window.gettext('Falta 1 questão para ser respondida')
                    : window.gettext('Faltam %1 questões para serem respondidas', empty.length);
            }
        } else if (currentStep === 3) {
            const required = 3;
            let changed = QUESTIONS.filter((q, idx) => idx && answers[idx] > 6);
            if (changed.length !== required) {
                let left = required - changed.length;
                if (left === 1) {
                    message = window.gettext('Falta 1 questão para ser definida uma nota entre 7 e 10');
                } else if (left < 0) {
                    message = window.gettext('Somente %1 questões podem ficar com nota maior do que 6', required);
                } else if (left > 1) {
                    message = window.gettext('Faltam %1 questões para ser definida uma nota entre 7 e 10', left);
                }
            }
        }

        if (message) {
            this.props.notify({type: 'warning', message});
            return false;
        }

        return true;
    }

    afterChange(currentStep) {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                {this.state.nextStepModal}
                <div className="content ancora-container">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h3">
                                      {window.gettext('Âncora de carreira')}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>

                                    {(this.role === 'super_admin' || this.role === 'specialist')
                                    && this.renderSelect()
                                    }

                                    {this.state.selectedUser && !!this.state.ancoraId && this.renderExercise()}

                                    {this.state.selectedUser && !this.state.ancoraId &&
                                    <p>
                                      {window.gettext('Nenhum resultado foi encontrado para este usuário')}
                                    </p>
                                    }

                                    {this.role === 'student' && this.renderExercise()}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    renderExercise() {
        let {step} = this.state;
        let stepConfig = STEPS[step - 1];

        return (
            <React.Fragment>
                <Row>
                    <Col md="12">
                        {this.renderStepIntro(step)}
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        {step === 4 ? this.renderResults() : this.renderQuestions()}
                    </Col>
                </Row>

                {this.role === 'student' &&
                <Row>
                    <Col md="12" className="text-center">
                        {stepConfig.button &&
                        <Button className="btn" color="info" type="button"
                                onClick={this.nextStep}
                                disabled={step === 4 && !this.state.changed}
                        >
                            {stepConfig.button}
                        </Button>
                        }
                        {step !== 1 &&
                        <Button className="btn" color="default" type="button"
                                onClick={this.reset}
                        >
                          {window.gettext('Refazer')}
                        </Button>
                        }
                    </Col>
                </Row>
                }
            </React.Fragment>
        );
    }

    renderQuestions() {
        return (
            <Table>
                <tbody>
                {QUESTIONS.map((question, qId) => {
                    if (qId === 0) {
                        return '';
                    }
                    return (
                        <tr key={"question-" + qId}>
                            <td>
                                {this.renderButtonGroup(qId, this.state.step == 3 ? 10 : 6)}
                            </td>
                            <td>
                                {qId}.
                            </td>
                            <td>
                                {question}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>
        );
    }

    renderButtonGroup(questionId, scoreEnd) {
        let options = [];
        for (let i = 1; i <= 6; i++) {
            options.push(i);
        }
        if (scoreEnd == 10) {
            options.push('+4');
        }

        return (
            <ButtonGroup data-toggle="buttons" className="btn-group-choose-score">
                {options.map(option => {
                    let className = 'btn-round';
                    let score = this.state.answers[questionId];
                    let visibleScore = score <= 6 ? score : (score - 4);
                    let value;

                    if (option === '+4') {
                        if (score > 6) {
                            className += ' active';
                        }
                        // allow to unselect
                        value = score > 6 ? visibleScore : score + 4;
                    } else {
                        if (option == visibleScore) {
                            className += ' active';
                        }
                        value = option;
                    }

                    return (
                        <Button key={`option-${questionId}-${option}`}
                                className={className}
                                color="info"
                                outline
                                type="button"
                                onClick={(e) => this.answerQuestion(e, questionId, value)}
                                disabled={this.state.step == 3 && (option !== '+4' || score < 3)}
                        >
                            {option}
                        </Button>
                    );
                })}
            </ButtonGroup>
        );
    }

    renderResults() {
        let results = {};
        let {answers} = this.state;
        for (let [group, qIds] of Object.entries(CALC)) {
            let sum = qIds.reduce((a, b) => a + (answers[b] || 0), 0);
            results[group] = sum;
        }

        let tables = [
            ['TF', 'GG', 'AI', 'SE'],
            ['CE', 'SD', 'DP', 'EV']
        ];

        let backgroundColor = '#efefef';

        return (
            <React.Fragment>
                {tables.map((groups, idx) =>
                    <Table key={'table-result-' + idx}>
                        <colgroup>
                            <col style={{width: '6%'}}></col>
                            <col style={{width: '12%', backgroundColor}}></col>
                            <col style={{width: '12%'}}></col>
                            <col style={{width: '12%', backgroundColor}}></col>
                            <col style={{width: '12%'}}></col>
                            <col style={{width: '12%', backgroundColor}}></col>
                            <col style={{width: '12%'}}></col>
                            <col style={{width: '12%', backgroundColor}}></col>
                            <col style={{width: '12%'}}></col>
                        </colgroup>
                        <thead>
                        <tr>
                            <td></td>
                            {groups.map(g =>
                                <React.Fragment key={'th-' + g}>
                                    <td className="text-center"><strong>{window.gettext(g)}</strong></td>
                                    <td className="text-center"><strong>
                                      {window.gettext('Resultado')}
                                    </strong></td>
                                </React.Fragment>
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {[0, 1, 2, 3, 4].map(line =>
                            <tr key={'tr-' + line}>
                                <td></td>
                                {groups.map(g =>
                                    <React.Fragment key={'td-' + line + '-' + g}>
                                        <td className="text-center">{CALC[g][line]}</td>
                                        <td className="text-center">{answers[CALC[g][line]] || 0}</td>
                                    </React.Fragment>
                                )}
                            </tr>
                        )}

                        <tr>
                            <td><strong>Total</strong></td>
                            {groups.map(g =>
                                <React.Fragment key={'td-sum-' + g}>
                                    <td></td>
                                    <td className="text-center">{results[g]}</td>
                                </React.Fragment>
                            )}
                        </tr>

                        <tr>
                            <td><strong>&divide;5</strong></td>
                            {groups.map(g =>
                                <React.Fragment key={'td-sum-divided-' + g}>
                                    <td></td>
                                    <td className="text-center">{(results[g] / 5).toFixed(1)}</td>
                                </React.Fragment>
                            )}
                        </tr>

                        </tbody>
                    </Table>
                )}

                {Object.entries(RESULTS).map(([key, info]) =>
                    <div key={'info-' + key}>
                        <p><strong>{info.code} - {info.title}</strong></p>
                        <p>{info.desc}</p>
                    </div>
                )}

            </React.Fragment>
        )
    }

    renderStepIntro(step) {
        return (
            <h5>
                <strong>{step}º {window.gettext('passo')}:</strong>&nbsp;
                {STEPS[step - 1].intro}
            </h5>
        );
    }

    renderModal(nextStep) {
        return new Promise((resolve, reject) => {
            let confirm = () => {
                this.setState({nextStepModal: ''});
                resolve();
            };
            this.setState({
                nextStepModal: (
                    <ReactBSAlert
                        warning
                        style={{display: "block", marginTop: "-300px", width: "800px", marginLeft: "-400px"}}
                        title={window.gettext("Próximo passo")}
                        onConfirm={confirm}
                        confirmBtnBsStyle="info"
                        confirmBtnText={window.gettext("OK")}
                    >
                        {this.renderStepIntro(nextStep)}
                    </ReactBSAlert>
                )
            });
        });
    }

    renderSelect() {
        return (
            <Row>
                <Col className="ml-auto mr-auto mb-3" md="8">
                    <Row>
                        <Form className="form-calendar-header">
                            <FormGroup>
                                <AsyncSelect
                                    className="react-select primary m-3"
                                    classNamePrefix="react-select"
                                    name="specialist_id"
                                    value={this.state.selectedUser}
                                    onChange={this.changeUser}
                                    defaultOptions
                                    loadOptions={this.loadUsers}
                                    placeholder={window.gettext("Selecione um usuário para ver seus resultados")}
                                />
                            </FormGroup>
                        </Form>
                    </Row>
                </Col>
            </Row>
        );
    }
}

const QUESTIONS = [
    "",
  window.gettext("Sonho em ser tão bom no que faço, de tal forma que meus conhecimentos especializados sejam constantemente procurados."),
    window.gettext("Sinto-me mais realizado em meu trabalho quando sou capaz de integrar e gerenciar o esforço dos outros."),
    window.gettext("Sonho em ter uma carreira que me dê a liberdade de fazer o trabalho à minha maneira de no tempo por mim programado."),
    window.gettext("Segurança e estabilidade são mais importantes para mim do que liberdade e autonomia."),
    window.gettext("Estou sempre procurando ideias que me permitam iniciar meu próprio negócio."),
    window.gettext("Sinto-me bem em minha carreira apenas quando tenho a sensação de ter feito uma contribuição real para o bem da sociedade."),
    window.gettext("Sonho com uma carreira na qual eu possa solucionar problemas ou vencer com situações extremamente desafiadoras."),
    window.gettext("Preferiria deixar meu emprego do que ser colocado em um trabalho que comprometa minha capacidade de me dedicar aos assuntos pessoais e familiares."),
    window.gettext("Sinto-me sucedido em minha carreira apenas quando posso desenvolver minhas habilidades técnicas ou funcionais a um nível de competência muito alto."),
    window.gettext("Sonho em dirigir uma organização complexa e tomar decisões que afetem muitas pessoas."),
    window.gettext("Sinto-me mais realizado em meu trabalho quanto tenho total liberdade de definir minhas próprias tarefas, horários e procedimentos."),
    window.gettext("Preferiria deixar meu emprego do que aceitar uma tarefa que possa colocar em risco minha segurança na organização."),
    window.gettext("Montar meu próprio negócio é mais importante para mim do que atingir uma alta posição gerencial como empregado."),
    window.gettext("Sinto-me mais realizado em minha carreira quando posso utilizar meus talentos a serviço dos outros."),
    window.gettext("Sinto-me realizado em minha carreira apenas quando enfrento e supero desafios extremamente difíceis."),
    window.gettext("Sonho com uma carreira que me permita integrar minhas necessidades pessoais, familiares e de trabalho."),
    window.gettext("Tornar-me um gerente técnico em minha área de especialização é mais atraente para mim do que tornar-me um gerente geral."),
    window.gettext("Sentir-me-ei bem sucedido em minha carreira apenas quando me tornar um gerente geral em alguma organização."),
    window.gettext("Sentir-me-ei bem sucedido em minha carreira apenas quando alcançar total autonomia e liberdade."),
    window.gettext("Procuro trabalhos em organizações que me dêem senso de segurança e estabilidade."),
    window.gettext("Sinto-me realizado em minha carreira quando tenho a oportunidade de construir alguma coisa que seja resultado unicamente de minhas próprias ideias e esforços."),
    window.gettext("Utilizar minhas habilidades para tornar o mundo um lugar melhor para se viver e trabalhar, é mais importante para mim do que alcançar uma posição gerencial de alto nível."),
    window.gettext("Sinto-me mais realizado em minha carreira quando solucionei problemas insolúveis ou venci o que aparentemente era impossível de ser vencido."),
    window.gettext("Sinto-me bem sucedido na vida apenas quando fui capaz de equilibrar minhas necessidades pessoais, familiares e de carreira."),
    window.gettext("Preferiria deixar meu emprego do que aceitar uma tarefa de rodízio que me afaste da minha área de experiência."),
    window.gettext("Tornar-me um gerente geral é mais atraente para mim do que tornar-me um gerente técnico em minha área de especialização."),
    window.gettext("Para mim, poder fazer um trabalho à minha própria maneira, sem regras e restrições, é mais importante do que segurança."),
    window.gettext("Sinto-me mais realizado em meu trabalho quando percebo que tenho total segurança financeira e estabilidade no trabalho."),
    window.gettext("Sinto-me bem sucedido em meu trabalho apenas quando posso criar ou construir alguma coisa que seja inteiramente de minha autoria."),
    window.gettext("Sonho em ter uma carreira que faça uma real contribuição à humanidade e à sociedade."),
    window.gettext("Procuro oportunidades de trabalho que desafiem fortemente minhas habilidades para solucionar problemas."),
    window.gettext("Equilibrar as exigências da minha vida pessoal e profissional é mais importante do que alcançar alta posição gerencial."),
    window.gettext("Sinto-me plenamente realizado em meu trabalho quando sou capaz de empregar minhas habilidades e talentos especiais."),
    window.gettext("Preferiria deixar minha organização do que aceitar um emprego que me afastasse da trajetória de gerência geral."),
    window.gettext("Preferiria deixar minha organização do que aceitar um emprego que reduza minha autonomia e liberdade."),
    window.gettext("Sonho em ter uma carreira que me dê senso de segurança e estabilidade."),
    window.gettext("Sonho em iniciar e montar meu próprio negócio."),
    window.gettext("Preferiria deixar minha organização do que aceitar uma tarefa que prejudique minha capacidade de servir aos outros."),
    window.gettext("Trabalhar em problemas praticamente insolúveis para mim é mais importante do que alcançar uma posição gerencial de alto nível."),
    window.gettext("Sempre procurei oportunidades de trabalho que minimizassem interferências com assuntos pessoais e familiares.")
];

const STEPS = [
    {
        intro: window.gettext("Classifique o quanto cada um dos 40 itens abaixo é verdadeiro para você utilizando a escala apresentada de 1 a 6. É preciso responder a todos os itens para seguir adiante:"),
        button: window.gettext("Continuar")
    },
    {
        intro: window.gettext("Revise todas as suas respostas, garantindo que você pontuou corretamente o que é mais verdadeiro para você."),
        button: window.gettext("Continuar")
    },
    {
        intro:  window.gettext("Selecione 3 itens que você tenha colocado as maiores pontuações e que você considere ser os itens mais verdadeiros para você e adicione 4 pontos a cada um deles."),
        button:  window.gettext("Calcular")
    },
    {
        intro:  window.gettext("Veja nos resultados, as duas âncoras com maior pontuação e as duas com menor pontuação – elas representam, respectivamente, os valores e competências de maior e menor importância para você. Preste atenção nisso quando avaliar seu ambiente de trabalho e novas oportunidades."),
        button:  window.gettext("Salvar")
    }
];

const CALC = {
    TF: [1, 9, 17, 25, 33],
    GG: [2, 10, 18, 26, 34],
    AI: [3, 11, 19, 27, 35],
    SE: [4, 12, 20, 28, 36],
    CE: [5, 13, 21, 29, 37],
    SD: [6, 14, 22, 30, 38],
    DP: [7, 15, 23, 31, 39],
    EV: [8, 16, 24, 32, 40]
};

const RESULTS = {
    TF: {
        code: window.gettext("TF"),
        title: window.gettext("Competência Técnica/Funcional"),
        desc: window.gettext("Se sua âncora de carreira é a competência em alguma área técnica ou funcional, você não abriria mão da oportunidade de aplicar suas habilidades nessa área e de continuar desenvolvendo essas habilidades a um nível cada vez mais alto. Você obtém seu senso de identidade com o exercício dessas habilidades e sente-se totalmente realizado quando seu trabalho lhe permite ser desafiado nessas áreas. Você pode estar disposto a gerenciar outras pessoas em sua área técnica ou funcional, mas não se interessa pelo gerenciamento em si e evitaria a gerência geral, porque precisaria desistir de sua própria área de especialidade. Seus pontos do inventário nesta área estão na primeira coluna da folha de pontos, sob TF.")
    },
    GG: {
        code: window.gettext("GG"),
        title: window.gettext("Competência para Gerência Geral"),
        desc: window.gettext("Se sua ancora de carreira é a competência para a gerência geral, você não abriria mão da oportunidade de subir a um nível alto o suficiente que lhe permita integrar os esforços de outras pessoas em suas funções e ser responsável pelo resultado de determinada unidade da organização. Você quer total responsabilidade pelos resultados e identifica seu próprio trabalho com o sucesso da organização para qual trabalha. Se você está em uma área técnica ou funcional atualmente, aceita a situação como uma experiência de aprendizado necessária; entretanto, ambiciona alcançar um cargo com funções generalistas o quanto antes. Ter um alto cargo gerencial técnico não interessa. Seus pontos do inventário nesta área estão na segunda coluna da folha de pontos sob GG.")
    },
    AI: {
        code: window.gettext("AI"),
        title: window.gettext("Autonomia/Independência"),
        desc: window.gettext("Se sua âncora de carreira é a autonomia/independência, você não renunciaria à oportunidade de definir seu próprio trabalho, à sua própria maneira. Se você está numa organização, quer permanecer em funções que lhe permitam flexibilidade com relação a quando e como trabalhar. Se você não tolera regras e restrições organizacionais de qualquer espécie, busca ocupações nas quais tenha a liberdade que procura, tais como ensino ou consultoria. Para manter sua autonomia, você recusa oportunidades de promoção ou avanço. Talvez você até procure Ter seu próprio negócio para alcançar a sensação de autonomia; entretanto este motivo não é o mesmo que a criatividade empreendedora descrita mais adiante. Seus pontos no inventário dessa dimensão estão na terceira coluna da folha de pontos, sob as letras AI.")
    },
    SE: {
        code: window.gettext("SE"),
        title: window.gettext("Segurança/Estabilidade"),
        desc: window.gettext("Se sua âncora de carreira é a segurança/estabilidade, você não abriria mão da sua segurança ou estabilidade no trabalho ou organização. Sua principal preocupação é alcançar a sensação de ser bem sucedido, para ficar tranqüilo. A âncora está demonstrada na preocupação pela segurança financeira (tais como aposentadoria e planos de pensão) ou segurança no emprego. Essa estabilidade pode significar trocar sua lealdade e disposição de fazer qualquer coisa que seu empregador lhe peça por uma promessa de garantia de emprego. Você se preocupa menos com o conteúdo do seu trabalho e o posto que pode alcançar, embora possa chegar a um alto nível, se seus talentos assim o permitirem. No que se refere a autonomia, todo mundo tem certas necessidades de segurança e estabilidade, especialmente em épocas que os encargos financeiros são grandes ou quando se está para enfrentar a aposentadoria. Entretanto, as pessoas ancoradas dessa maneira estão sempre preocupadas com essas questões e constroem toda sua auto-imagem em torno do gerenciamento da segurança e estabilidade. Seus pontos no inventário dessa dimensão estão na Quarta coluna da folha de pontos sob as letras SE.")
    },
    CE: {
        code: window.gettext("CE"),
        title: window.gettext("Criatividade Empreendedora"),
        desc: window.gettext("Se sua âncora de carreira é a criatividade empreendedora, você não renunciaria à oportunidade de criar sua própria organização ou empreendimento, desenvolvidas com sua própria capacidade e disposição de assumir riscos e superar obstáculos. Você quer provar ao mundo que pode criar u empreendimento que seja o resultado do seu próprio esforço. Talvez você trabalhe par outros em alguma organização, enquanto aprende e avalia oportunidades futuras, mas seguirá seu próprio caminho assim que sentir que tem condições para isso. Você quer que seu empreendimento seja financeiramente bem sucedido, como prova de sua capacidade. Seus pontos no inventário estão na quinta coluna da folha de pontos, sob as letras CE.")
    },
    SD: {
        code: window.gettext("SD"),
        title: window.gettext("Serviço/Dedicação a uma Causa"),
        desc: window.gettext("Se sua âncora de carreira é serviço/dedicação a uma causa, você não renunciaria à oportunidade de procurar um trabalho onde pudesse realizar alguma coisa útil, como por exemplo tornar o mundo um lugar melhor para se viver, solucionar problemas ambientais, melhorar a harmonia entre as pessoas, ajudar aos outros, melhorar a segurança das pessoas, curar doenças através de novos produtos, etc. Você busca essas oportunidades, mesmo que isto signifique mudar de organização e não aceita transferências ou promoções que o desviem do trabalho que preencha esses valores. Seus pontos no inventário dessa dimensão estão na sexta coluna da folha de pontos sob as letras SD.")
    },
    DP: {
        code: window.gettext("DP"),
        title: window.gettext("Desafio Puro"),
        desc: window.gettext("Se sua âncora de carreira é desafio puro, você não abriria mão da oportunidade de trabalhar na solução de problemas aparentemente insolúveis, para vencer oponentes duros ou superar obstáculos difíceis. Para você, a única razão significativa para buscar um trabalho ou carreira é que este lhe permita vencer o impossível. Algumas pessoas encontram esse desafio puro em alguns trabalhos intelectuais, como por exemplo o engenheiro interessado apenas em desenhos extremamente difíceis; outras encontram seu desafio em situações complexas, tais como um consultor estrategista, interessado apenas em clientes à beira da falência e que já esgotaram todos os recursos; algumas o encontram na competição interpessoal, como o atleta profissional ou o vendedor que define cada venda como uma vitória ou derrota. A novidade, variedade e dificuldade tornam-se um fim em si e se alguma coisa é fácil, imediatamente torna-se monótona. Seus pontos no inventário nessa dimensão estão na sétima coluna da folha de pontos sob as letras DP.")
    },
    EV: {
        code: window.gettext("EV"),
        title: window.gettext("Estilo de Vida"),
        desc: window.gettext("Se sua âncora de carreira é o estilo de vida, você não abriria mão de uma situação que lhe permita equilibrar e integrar suas necessidades pessoais, familiares e as exigências de sua carreira. Você que fazer todos os principais segmentos de sua vida trabalhar em conjunto para um todo integrado e, portanto, precisa de uma situação de carreira que lhe dê suficiente flexibilidade para alcançar tal integração. Talvez você precise sacrificar alguns aspectos da sua carreira (por exemplo, uma mudança geográfica que fosse uma promoção, mas que desestruturaria toda sua situação de vida), e você define o sucesso em termos mais amplos do que simplesmente sucesso na carreira. Você sente que sua identidade está mais vinculada ao modo de viver sua vida como um todo, onde você se estabelece, como lida com sua situação familiar e como você se desenvolve, do que com qualquer trabalho ou organização. Seus pontos no inventário dessa dimensão estão na oitava coluna da folha de pontos sob as letras EV.")
    }
}


export default withNotify(Ancora);
