import React from "react";
// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment-timezone";
// react component used to create sweet alerts
import {Link} from 'react-router-dom';

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import {getUserData} from "../../../lib/Auth";
import {calendarMessages} from "../../../lib/Locale";

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col
} from "reactstrap";

const localizer = BigCalendar.momentLocalizer(moment);
const DEFAULT_VIEW = 'agenda';


class AppointmentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      userData: getUserData(),
      view: DEFAULT_VIEW,
      confirmAlert: ''
    };
    moment.tz.setDefault(getUserData().timezone);
  }

  componentDidMount() {
    this.requestData();
  }

  requestData() {
    return apiRequest('/appointments', {method: 'GET'})
      .then(response => {
        let events = [];

        for (let appointment of response.data.data) {
          let newEvent = {
            id: appointment.id,
            start: moment(appointment.datetime_start).toDate(),
            end: moment(appointment.datetime_end).toDate(),
            title: this.getEventTitle(appointment),
            shortTitle: this.getShortEventTitle(appointment),
            situation: appointment.situation
          };
          events.push(newEvent);
        }

        this.setState({events});
      });
  }

  getEventTitle(event) {
    const user = this.state.userData;
    let title = event.product_title || window.gettext('Agendamento');
    if (user.role === 'specialist') {
       return window.gettext('%1 com %2', title, event.user_name);
    }
    if (user.id !== event.user_id) {
      return window.gettext('%1 de %2 com %3', title, event.user_name, event.specialist_name);
    }
    return window.gettext('%1 com %2', title, event.specialist_name);
  }

  getShortEventTitle(event) {
    if (this.state.userData.role === 'specialist') {
      return event.user_name;
    }
    return event.specialist_name;
  }

  onView = view => {
    this.setState({view});
  }

  render() {
    let formats = {
      dayHeaderFormat: 'dddd'
    };

    return (
      <>
        <div className="content appointment-calendar-page">
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Card className="card-calendar">
                <CardBody>
                  <BigCalendar
                    selectable
                    className={`appointment-list-calendar ${this.state.view}-view`}
                    localizer={localizer}
                    culture={this.state.userData.language.replace("_", "-")}
                    events={this.state.events}
                    defaultView={DEFAULT_VIEW}
                    timeslots={1}
                    formats={formats}
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    messages={calendarMessages}
                    views={['week', 'day', 'agenda']}
                    onView={this.onView}
                    titleAccessor={event => {
                      return (
                        <React.Fragment>
                          <span className={event.situation === 'canceled' ? 'cancelled' : ''} >
                            {this.state.view !== 'week' ? event.title : event.shortTitle}
                          </span>
                          &nbsp;&nbsp;
                          <Link to={`/admin/appointments/${event.id}`}>
                            <b>
                              {window.gettext('Ver mais')}
                            </b>
                          </Link>
                        </React.Fragment>
                      );
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </>
    );
  }
}

export default withNotify(AppointmentList);
