import React from "react";

// reactstrap components
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Row,
} from "reactstrap";

class NoAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        {this.props.title || ''}
                                    </CardTitle>
                                    <p>{this.props.description || ''}</p>
                                </CardHeader>
                                <CardBody>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default NoAccess;
