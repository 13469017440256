import React from "react";

// reactstrap components
import {Badge, Button, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import CardShowContent from "./CardShowContent";

class WorkPerformance extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
        this.score = 14;
        this.topScore = 16;
        this.modal = false;
    }

    get total() {
        if (!this.props.data || !this.props.data.total_cut_point_applied) {
            return 0;
        }
        return this.props.data.total_cut_point_applied || 0;
    }

    renderHigh() {
        return (
            <>
                {window.gettext("Altos escores nesta dimensão indicam que você consegue ser pontual em suas entregas no trabalho e gerência adequadamente o tempo e as prioridades para que sejam executadas. Além disso, você percebe que consegue dar conta de tarefas adicionais (prescritas ou não) e busca uma constante capacitação no trabalho, em termos de aquisição de novas habilidades, competências e conhecimento para uma melhor execução de suas tarefas.")}
            </>
        );
    }

    renderLower() {
        return (
            <>
                {window.gettext("Baixos escores na autopercepção de desempenho no trabalho significa que você precisa se esforçar em atingir níveis mais altos de pontualidade, (completar as tarefas no prazo estabelecido) e melhorar sua capacidade de gerenciamento do tempo na execução de suas tarefas. Após atingir estas metas, dedique-se à conclusão de suas tarefas extras e procure adquirir novas habilidades e competências que irão lhe auxiliar na gestão e execução do seu trabalho e suas tarefas.")}
            </>
        )
    }

    isHigher() {
        if (this.total) {
            return this.total >= this.score;
        }
        return false;
    }

    getDescription() {
        if (this.isHigher()) {
            return this.renderHigh();
        }
        return this.renderLower();
    }

    showModal() {
        console.log('abriu a modal do work')
        this.modal = true;
        // this.setState({modal: true});
    }

    closeModal() {
        this.modal = false;
        // this.setState({modal: false});
    }

    toggle() {
        this.modal = !this.modal;
    }

    render() {

        return (
            <>


                <div className="content ancora-container">
                    <Row>
                        <Col md={12}>
                            <CardShowContent
                                isHigher={this.isHigher()}
                                topScore={this.topScore}
                                description={this.getDescription()}
                                total={this.total}
                                helper={window.gettext('O desempenho no trabalho está relacionado ao ato de realizar tarefas de forma efetiva de acordo com critérios de qualidade, quantidade e outros. O desempenho no trabalho é uma percepção do trabalhador sobre suas metas em tarefas ou funções relacionadas ao trabalho e à organização.')}
                                title={window.gettext('Desempenho no Trabalho')}
                            />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(WorkPerformance);
