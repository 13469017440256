import React from "react";

import {Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import apiRequest from "../../../lib/Api";
import AppointmentCard from "./AppointmentCard";
import {getUserData} from "../../../lib/Auth";

class AppointmentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: getUserData(),
            horizontalTabs: 'future',
            appointmentsPast: [],
            appointmentsFuture: [],
        };
    }

    async componentDidMount() {
        const response = await apiRequest('/appointments/list', {method: 'GET'})
        this.setState({
            appointmentsFuture: response.data.appointments.future,
            appointmentsPast: response.data.appointments.past
        })
    }

    renderWithoutAppointment(type) {

        if (type === 'future' && this.state.appointmentsFuture.length === 0) {
            return <h6 className="text-center">{window.gettext('Nenhum encontro futuro encontrado.')}</h6>

        }

        if (type === 'past' && this.state.appointmentsPast.length === 0) {
            return <h6 className="text-center">{window.gettext('Nenhum encontro passado encontrado.')}</h6>
        }


    }

    render() {
        return <>
            <div style={{marginTop: '100px', backgroundColor: '#FFFFFF', padding: "40px" }}>
                <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                        <Nav id="tabs" role="tablist" tabs>
                            <NavItem>
                                <NavLink
                                    aria-expanded={this.state.horizontalTabs === "future"}
                                    data-toggle="tab"
                                    href="#"
                                    role="tab"
                                    className={
                                        `appointment-list ${this.state.horizontalTabs === "future" ? 'active' : ''}`
                                    }
                                    onClick={() =>
                                        this.setState({horizontalTabs: "future"})
                                    }
                                >
                                    Encontros Futuros
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    aria-expanded={
                                        this.state.horizontalTabs === "past"
                                    }
                                    data-toggle="tab"
                                    href="#"
                                    role="tab"
                                    className={
                                        `appointment-list ${this.state.horizontalTabs === "past" ? 'active' : ''}`
                                    }
                                    onClick={() =>
                                        this.setState({horizontalTabs: "past"})
                                    }
                                >
                                    Encontros Passados
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
                <TabContent
                    className="text-left"
                    id="my-tab-content"
                    activeTab={this.state.horizontalTabs}
                >
                    <TabPane tabId="future" role="tabpanel">
                        <Row>
                            {this.renderWithoutAppointment('future')}
                            {this.state.appointmentsFuture.map(appointment => {
                                return <AppointmentCard appointment={appointment}/>
                            })}
                        </Row>
                    </TabPane>
                    <TabPane tabId="past" role="tabpanel">
                        <Row>
                            {this.renderWithoutAppointment('past')}

                            {this.state.appointmentsPast.map(appointment => {
                                return <AppointmentCard appointment={appointment} isPast={true}/>
                            })}

                        </Row>
                    </TabPane>
                </TabContent>
            </div>
        </>
    }
}

export default AppointmentList;
