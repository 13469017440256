import React from "react";
import {Link} from 'react-router-dom'

// reactstrap components
import {Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, Row, Table, UncontrolledTooltip} from "reactstrap";

import apiRequest from "../../../lib/Api";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import withNotify from "../../../lib/NotificationWrapper";

const PeriodicityOptions = {
    yearly: 'Anual',
    monthly: 'Mensal',
    single: 'Único'
};

class ExceptionList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() {
        this.requestData();
    }

    requestData() {
        apiRequest("/schedule-exceptions", {method: "GET"})
            .then(response => {
                this.setState({items: response.data.data});
            });
    }

    toggleConfirmation = (show) => {
        this.setState({showDeleteConfirmation: show});
    }

    maybeDelete = (id) => {
        this.setState({deleteId: id});
        this.toggleConfirmation(true);
    }

    delete = () => {
        const id = this.state.deleteId;
        apiRequest("/schedule-exceptions/" + this.state.deleteId, {method: "DELETE"})
            .then(() => {
                this.props.notify({type: 'success', message: window.gettext('Exceção removida com sucesso')});

                let items = this.state.items.filter(item => item.id !== id);
                this.setState({items});
            });
    }

    getTypeDisplay(type) {
        return PeriodicityOptions[type] || '';
    }

    getBadgeTypeException(item) {

        let label = 'Remover'
        let color = 'danger'
        if (item.type_exception === 'add') {
            label = 'Adicionar'
            color = 'success'
            if (item.is_used) {
                label = 'Adicionar - Usado'
            }
        }

        return <>
            <Badge color={color} pill>
                { label }
            </Badge>
        </>
    }

    render() {
        let {items} = this.state;

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        {window.gettext('Lista')}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Table responsive>
                                        <thead className="text-primary">
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th>Data</th>
                                            <th>Hora início</th>
                                            <th>Hora fim</th>
                                            <th>Tipo</th>
                                            <th className="text-right">
                                                {window.gettext('Ações')}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {items.map(item => {

                                            return <tr key={item.id}>
                                                <td className="text-center">{item.id}</td>
                                                <td>{item.date}</td>
                                                <td>{item.time_start}</td>
                                                <td>{item.time_end}</td>
                                                <td>{this.getBadgeTypeException(item)}</td>
                                                <td className="text-right">
                                                    <Button
                                                        tag={Link}
                                                        className="btn-icon btn-neutral"
                                                        color="success"
                                                        id="tooltip264453216"
                                                        size="sm"
                                                        to={`/admin/schedule-exceptions/${item.id}/edit`}
                                                    >
                                                        <i className="fa fa-edit"/>
                                                    </Button>{" "}
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip264453216"
                                                    >
                                                        {window.gettext('Editar')}
                                                    </UncontrolledTooltip>

                                                    <Button
                                                        className="btn-icon btn-neutral"
                                                        color="danger"
                                                        id="tooltip476609793"
                                                        size="sm"
                                                        type="button"
                                                        onClick={() => this.maybeDelete(item.id)}
                                                    >
                                                        <i className="fa fa-times"/>
                                                    </Button>{" "}
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip476609793"
                                                    >
                                                        {window.gettext('Remover')}
                                                    </UncontrolledTooltip>
                                                </td>
                                            </tr>;

                                        })}

                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>

                <ConfirmModal message={window.gettext("Tem certeza que deseja remover esta exceção?")}
                              onConfirm={() => this.delete()}
                              onToggle={() => this.toggleConfirmation(false)}
                              open={this.state.showDeleteConfirmation}
                />
            </>
        );
    }
}

export default withNotify(ExceptionList);
