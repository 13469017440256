// import React from "react";
import AppointmentCalendar from "views/pages/appointments/AppointmentCalendar";
import OrganizationList from "views/pages/organizations/OrganizationList";

import {getAuthToken} from "./lib/Auth";
import enviroment from "./services/enviroment";
import OrganizationForm from "./views/pages/organizations/OrganizationForm";
import ChooseSpecialist from "./views/pages/appointments/ChooseSpecialist";
import AppointmentCreateAdmin from "./views/pages/appointments/AppointmentCreateAdmin";
import StandardsCalendar from "./views/pages/standards/StandardsCalendar";
import ExceptionForm from "./views/pages/exceptions/ExceptionForm";
import ExceptionList from "./views/pages/exceptions/ExceptionList";
const userToken = getAuthToken();
const userTokenEncoded = window.btoa(userToken)
const urlEad = enviroment.getEadUrl() || 'http://localhost:8081/'

const routesComercial = [
  {
    collapse: true,
    name: window.gettext("Empresas"),
    icon: "nc-icon nc-badge",
    state: "organizationsCollapse",
    id: 'companies',
    is_comercial: true,
    views: [
      {
        path: "/organization/list",
        name: window.gettext("Listar"),
        mini: "LE",
        component: OrganizationList,
        layout: "/admin"
      },
    ]
  },
  {
    collapse: true,
    name: window.gettext("Agendamento"),
    icon: "nc-icon nc-calendar-60",
    state: "scheduleCollapse",
    id: 'appointments',
    is_comercial: true,
    views: [
      {
        path: "/appointments/calendar",
        name: window.gettext("Horários agendados"),
        mini: "HA",
        component: AppointmentCalendar,
        layout: "/admin"
      },
    ]
  },
];

export default routesComercial;
