import React from "react";
import InputMask from 'react-input-mask';
import Select from "react-select";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import InputError from "../../components/InputError";
import statesDef from "../../../variables/states";

class OrganizationForm extends React.Component {
  constructor(props) {
    super(props);
    this.allCompanies = [];
    this.state = {
      name: '',
      cnpj: '',
      address: '',
      city: '',
      state: '',
      cep: '',
      phone: '',
      site: '',
      errors: [],
      parent_id: null,
      working: false,
      companiesDef: []
    }
    this.statesDef = statesDef.map(state =>
      ({label: state.name, value: state.id})
    );

  }

  async componentDidMount() {
    await this.fetchCompanies();
    if (this.id) {
      await this.fetchItem();
    }
  }

  handleCep = (name, value) => {
    if (typeof name == 'object' && typeof name.target == 'object') {
      let event = name;
      event.preventDefault();
      name = event.target.name;
      value = event.target.value;
    }
    this.setState({cep: value});
    this.setCep(value)
  }

  setCep(cep) {
    cep = cep.replaceAll('-', '');
    cep = cep.replaceAll('_', '');

    if (cep.length < 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then(response => response.json())
        .then(data => {

          const userState = data.state = this.statesDef.find(option => option.value === data.uf);
          this.setState({
            city: data.localidade,
            address: data.logradouro,
            state: userState,
            neighborhood: data.bairro,
          })
        })
        .catch(err => {
        })
  }
  async fetchItem() {
    return await apiRequest("/organizations/" + this.id, {method: "GET"})
        .then(response => {
          let model = response.data.data;
          model.state = this.statesDef.find(option => option.value === model.state);
          model.parent_id = this.allCompanies.find(option => option.value === model.parent_id);
          return this.setState(model);
        });
  }

  async fetchCompanies() {

    await apiRequest("/organizations", {method: "GET"})
        .then(response => {
          let model = response.data.data;
          let companies = [];
          model.forEach(company => {
            if (company.has_user_member === false) {
              companies.push({
                value: company.id,
                label: company.name,
                isDisabled: Number(company.id) === Number(this.id)
              });
            }
          })
          this.allCompanies = companies;
          this.setState({companiesDef: companies});
        });
  }

  get id() {
    if (this.props.match.params) {
      return this.props.match.params.id;
    }
    return null;
  }

  get formTitle() {
    if (this.id) {
      return window.gettext('Alteração de empresas');
    }
    return window.gettext('Cadastro de empresas');
  }

  clearForm() {
    this.setState({
      name: '',
      cnpj: '',
      address: '',
      city: '',
      state: '',
      cep: '',
      phone: '',
      site: '',
      parent_id: null,
      owner_id: null,
    });
  }

  handleChange = (name, value) => {
    if (typeof name === 'object' && typeof name.target === 'object') {
      let event = name;
      event.preventDefault();
      name = event.target.name;
      value = event.target.value;
    }

    this.setState({
      [name]: value
    });

    if (this.state.errors[name]) {
      this.setState({errors: {...this.state.errors, [name]: ''}});
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let {name, cnpj, address, city, state, cep, phone, site} = this.state;
    cnpj = removeMask(cnpj);
    cep = removeMask(cep);
    phone = removeMask(phone);
    state = state ? state.value : null;
    let data = {name, cnpj, address, city, state, cep, phone, site};

    data.parent_id = this.state.parent_id ? this.state.parent_id.value : null;

    this.setState({working: true});

    let request;
    if (this.id) {
      request = apiRequest("/organizations/" + this.id, {data, method: 'PUT'})
    }
    else {
      data.role = this.role;
      request = apiRequest("/organizations", {data, method: 'POST'});
    }

    request.then(response => {
        this.clearForm();
        this.props.notify({type: 'success', message: window.gettext('Empresa armazenada com sucesso')});
      })
      .catch(err => {
        this.setState({errors: err.data.errors});
      })
      .finally(response => {
        this.setState({working: false});
        this.fetchCompanies();
      });
  }

  render() {
    let hasError = InputError.convertToHasClass(this.state.errors);

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{this.formTitle}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Label sm="2">
                        {window.gettext('Nome')}
                      </Label>
                      <Col sm="10">
                        <FormGroup className={hasError.name}>
                          <Input type="text" name="name" value={this.state.name}
                            onChange={this.handleChange}
                          />
                          <InputError name="name" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        {window.gettext('CNPJ')}
                      </Label>
                      <Col sm="10">
                        <FormGroup className={hasError.cnpj}>
                          <InputMask mask="99.999.999/9999-99" value={this.state.cnpj}
                            onChange={this.handleChange} name="cnpj"
                          >
                            {(inputProps) => <Input type="text" {...inputProps} />}
                          </InputMask>
                          <InputError name="cnpj" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        {window.gettext('CEP')}
                      </Label>
                      <Col sm="10">
                        <FormGroup className={hasError.cep}>
                          <InputMask mask="99999-999" value={this.state.cep}
                                     onChange={this.handleCep} name="cep"
                          >
                            {(inputProps) => <Input type="text" {...inputProps} />}
                          </InputMask>
                          <InputError name="cep" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        {window.gettext('Endereço')}
                      </Label>
                      <Col sm="10">
                        <FormGroup className={hasError.address}>
                          <Input type="text" name="address" value={this.state.address}
                            onChange={this.handleChange}
                          />
                          <InputError name="address" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        {window.gettext('Cidade')}
                      </Label>
                      <Col sm="10">
                        <FormGroup className={hasError.city}>
                          <Input type="text" name="city" value={this.state.city}
                            onChange={this.handleChange}
                          />
                          <InputError name="city" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        {window.gettext('Estado')}
                      </Label>
                      <Col sm="4">
                        <FormGroup className={hasError.state}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="state"
                            value={this.state.state}
                            onChange={value =>
                              this.handleChange("state", value)
                            }
                            options={[
                              {
                                value: "",
                                label: window.gettext("Selecione um estado"),
                                isDisabled: true
                              },
                              ...this.statesDef
                            ]}
                            placeholder={window.gettext("Selecione um estado")}
                          />
                          <InputError name="state" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">
                        {window.gettext('Telefone')}
                      </Label>
                      <Col sm="10">
                        <FormGroup className={hasError.phone}>
                          <InputMask mask="(99) 9999tt999?"
                            formatChars={{"9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]"}}
                            maskChar={null}
                            value={this.state.phone}
                            onChange={this.handleChange} name="phone"
                          >
                            {(inputProps) => <Input type="text" {...inputProps} />}
                          </InputMask>
                          <InputError name="phone" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        {window.gettext('Site')}
                      </Label>
                      <Col sm="10">
                        <FormGroup className={hasError.site}>
                          <Input type="text" name="site" value={this.state.site}
                            onChange={this.handleChange}
                          />
                          <InputError name="site" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        {window.gettext('Pertence a?')}
                      </Label>
                      <Col sm="10">
                        <FormGroup className={hasError.parent_id}>
                          <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="parent_id"
                              value={this.state.parent_id}
                              onChange={value =>
                                  this.handleChange("parent_id", value)
                              }
                              options={[
                                {
                                  value: "",
                                  label: window.gettext("Nenhuma empresa"),
                                  isDisabled: false
                                },
                                ...this.state.companiesDef
                              ]}
                              placeholder={window.gettext("Selecione um empresa")}
                          />
                          <InputError name="parent_id" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md="2" />
                      <Col md="10">
                        <Button className="btn-round" color="info" type="submit"
                          disabled={this.state.working}
                        >
                          {window.gettext('Salvar')}
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

function removeMask(value) {
  return value.replace(/[\.\-\/]+/g, '');
}

export default withNotify(OrganizationForm);
