import React from "react";

// reactstrap components
import {Button, Modal, ModalBody, ModalFooter, ModalHeader,} from "reactstrap";
import {getUserData} from "../../../lib/Auth";
import apiRequest from "../../../lib/Api";
import moment from "moment";
import momentFormat from "../../../lib/Locale";
import ExtendedDataFields from "../../../components/ExtendedData/ExtendedDataFields";
import withNotify from "../../../lib/NotificationWrapper";

class SpecialistDiaryAnswer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            specialist: getUserData(),
            appointment: null,
            openModal: false,
            extended_data: null,
            program: null,
            answers: null,
            user: {
                name: '',
                organization: '',
                begin_date: '',
                end_date: '',
            }

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.appointment === null && this.props.appointment !== null) {
            this.setState({openModal: true, appointment: this.props.appointment, program: this.props.program})
        }
    }


    toggle() {
        this.setState({openModal: !this.state.openModal});
    }

    async save() {
        if (this.state.answers === null || this.state.answers.values === null || this.state.answers.values === undefined) {
            this.props.notify({type: 'error', message: window.gettext('Informe todas as respostas para as perguntas.')});
            return;
        }
        let form = this.state.appointment.extended_data;
        let fields = [];
        let hasError = false;
        form.extended_data.sections[0].fields.map(field => {
            if (this.state.answers.values[field.property].value === '') {
                hasError = true
            }
            fields.push({...field, 'answer': this.state.answers.values[field.property]});
        });
        if (hasError) {
            this.props.notify({type: 'error', message: window.gettext('Existe respostas não preenchidas!')});
            return;
        }
        form.extended_data.sections[0].fields = fields;


        let method = 'POST';
        let url = `/programs/${this.state.program}/services`;
        if (this.state.appointment.diary_id !== null) {
            method = 'PUT';
            url = `/programs/${this.state.program}/services/${this.state.appointment.diary_id}`;
        }

        await apiRequest(
            url,
            {method: method, data: {form: form.extended_data, appointment: this.state.appointment.id}}
        ).then(success => {
            this.props.notify({type: 'success', message: window.gettext('Dados do encontro salvo com sucesso.')});
            this.setState({task: null, modal: false});
            this.props.onSave();
        }).catch(error => {
            this.props.notify({
                type: 'danger',
                message: window.gettext('Não foi possível salvar as suas respostas. Verifique se todas as perguntas foram respondidas.')
            });
        });
    }

    setExtendedData = (data) => {
        this.setState({answers: data});
    }

    cancel() {
        this.setState({appointment: null, openModal: false});
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    get answers() {

        if (!this.state.appointment) {
            return ;
        }
        let answers = {values: {}};
        let sections = this.state.appointment.data.sections || []
        if (sections.length === 0) {
            return ;
        }
        sections[0].fields.map(field => {

            answers.values[field.property] = {
                field: field.uuid,
                label: field.name,
                type: field.type,
                values: field.answer.value
            };
        });
        return answers;
    }
    renderSaveButton() {
        if (this.props.disabledForm) {
            return <></>;
        }

        return (
            <>
                <Button
                    color='primary'
                    onClick={() => {
                        return this.save();
                    }}>
                    {window.gettext('Salvar')}
                </Button>{'  '}
            </>
        );
    }

    render() {

        if (!this.props.appointment) {
            return <></>;
        }

        return (
            <>
                <Modal
                    size='lg'
                    isOpen={this.state.openModal}
                    toggle={this.toggle}
                >
                    <ModalHeader>
                        {this.props.appointment.service_title}<br/>
                        {moment(this.props.appointment.start_date).format(`${momentFormat.DATE_PTBR} ${momentFormat.HOUR}`)}
                    </ModalHeader>
                    <ModalBody>
                        <ExtendedDataFields oneColumn={true}
                                            extended_data_id={this.props.appointment.extended_data_id}
                                            fillFields={this.setExtendedData}
                                            data={this.answers}
                                            isDisabled={this.props.disabledForm || false}/>

                    </ModalBody>
                    <ModalFooter>
                        {this.renderSaveButton()}
                        <Button onClick={() => {
                            this.cancel();
                        }}>
                            {window.gettext('Fechar')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default withNotify(SpecialistDiaryAnswer);
