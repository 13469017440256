import jwtDecode from 'jwt-decode';

import apiRequest from './Api';

// TODO redux
function saveAuth(data) {

  const accessToken = data.access_token;
  localStorage.setItem('accessToken', accessToken);

  let decoded = jwtDecode(accessToken);
  let {id, name, role, adminOrganization, organization, pictureUrl, language, timezone, default_role, actual_role} = decoded;
  let {reload} = data;
  localStorage.setItem('userData', JSON.stringify({id, name, role, adminOrganization, organization, pictureUrl, language, timezone, reload, default_role, actual_role}));
}

function getAuthToken() {
  return localStorage.getItem('accessToken');
}

function getUserData(field) {
  let data = JSON.parse(localStorage.getItem('userData') || 'null');
  if (!data) {
    return null;
  }
  if (data.timezone === undefined || data.timezone == null) {
    data.timezone = 'America/Sao_Paulo';
  }
  if (data.language === undefined || data.language == null) {
    data.language = 'pt_BR';
  }
  if (!field) {
    return data;
  }

  return data[field] || null;
}

function updateUserData(field, value) {
  let data = getUserData();
  if (!data) {
    return;
  }
  data[field] = value;
  localStorage.setItem('userData', JSON.stringify(data));
}

function getUserFriendlyRole() {
  let data = getUserData();
  if (!data) {
    return '';
  }
  let {role, adminOrganization, organization} = data;
  if (role === 'super_admin') {
    return 'Super admin';
  }
  else if (role === 'specialist') {
    return window.gettext('Especialista');
  }
  else if (adminOrganization) {
    return window.gettext('Admin empresa');
  }
  else if (organization) {
    return window.gettext('Usuário empresa');
  }
  else {
    return window.gettext('Usuário');
  }
}

function isAdminCompany() {
  let data = getUserData();
  if (!data) {
    return '';
  }
  let {adminOrganization} = data;
  if (adminOrganization) {
    return true;
  }
  return false;
}

function logout() {

  let promise = new Promise((resolve, reject) => {
    localStorage.clear();
    setTimeout(() => {
      resolve();
    }, 500);
  });
  return promise;
}

function redirectLogin(forgetOrigin = false) {
  let current = window.location.pathname;
  if (forgetOrigin) {
    window.location.href = '/auth/login';
  }
  else {
    window.localStorage.setItem('redirectUrl', current);
    window.location.href = '/auth/login?redirect=1';
  }
}

function redirectToLoginIfNeeded(forgetOrigin= false) {
  if (!getAuthToken() && window.location.href.match(/admin/) && false) {
    redirectLogin(forgetOrigin);
  }
}

function clearRedirect() {
  window.localStorage.removeItem('redirectUrl');
}

function getRedirectUrl() {
  return window.localStorage.getItem('redirectUrl');
}

function shouldCompleteProfile() {
  let user = getUserData();
  if (!user) {
    return Promise.resolve(false);
  }

  return apiRequest("/users/" + user.id, {method: 'GET'})
      .then(response => {
        let user = response.data.data;
        let should = false;
        if (user.role == 'super_admin' || user.role == 'specialist' ) {
          should = false;
        } else if (user.organization && user.organization.user_role == 'admin') {
          should = false;
        } else if(user.role === 'student' && user.is_foreigner)
          should = false;
        else {
          should = !user.cpf
        }
        return Promise.resolve(should);
      });
}

function checkToken() {
  return apiRequest("/auth/me", {method: 'POST'});
}

function checkUserProfile() {
  let user = getUserData();

  if (user && user.has_onboarding === false) {
    window.location.href = `/setup/users/${user.id}/change-password`;
    throw new Error("Stop execution...redirecting");
  }

  return shouldCompleteProfile()
    .then(yes => {
      if (yes) {
        window.location.href = `/setup/users/${user.id}/edit`;
        throw new Error("Stop execution...redirecting");
      }
    });
}

export {
  saveAuth,
  getAuthToken,
  logout,
  redirectLogin,
  getUserData,
  getUserFriendlyRole,
  redirectToLoginIfNeeded,
  getRedirectUrl,
  clearRedirect,
  shouldCompleteProfile,
  checkToken,
  checkUserProfile,
  updateUserData,
  isAdminCompany
};
