import React from "react";
import moment from 'moment';

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table
} from "reactstrap";

import PaginationCtrl from "components/Pagination/PaginationCtrl";

import apiRequest from "../../../lib/Api";

const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';

const eventTranslations = {
    create: window.gettext('Criado'),
    approved: window.gettext('Aprovado'),
    started: window.gettext('Iniciado'),
    canceled: window.gettext('Cancelado'),
    done: window.gettext('Finalizado'),
    missed: window.gettext('Não Compareceu'),
    noshow: window.gettext('Cancelado em menos de 24 horas'),
    product_finished: window.gettext('Finalizada'),
    all_appointments_finished: window.gettext('Finalizada')
};

const typeTranslations = {
    appointment: window.gettext('Agendamento'),
    purchase_service: window.gettext('Atividade')
};

class SystemLogList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            meta: {},
            exportInterval: 30
        };
    }

    componentDidMount() {
        this.requestData();
    }

    requestData = page => {
        if (!page) {
            page = 1;
        }
        apiRequest("/system-logs", {method: "GET", data: {page}})
            .then(response => {
                let {data, meta} = response.data;
                this.setState({
                    items: data,
                    meta: meta
                });
            });
    }

    transformEntry(item) {
        let newItem = {
            created_at: moment(item.created_at).format(DATETIME_FORMAT),
            user_name: item.user_name,
            event: typeTranslations[item.object_type] + ' ' + eventTranslations[item.event] || item.event
        };

        let object;
        if (!item.object) {
            object = window.gettext('(removido)');
        } else if (item.object_type === 'appointment') {
            let itemDate = moment(item.object.datetime_start).format(DATETIME_FORMAT);
            let {object: {specialist_name, user_name}} = item;

            object = `${itemDate} - ${specialist_name} - ${user_name}`;
        } else if (item.object_type === 'purchase_service') {
            let product = item.object.service ? item.object.service.name : window.gettext('Produto desconhecido');

            object = `${product} - ${item.object.user.name}`;
        }
        newItem.object = object;
        newItem.company_name = this.getCompanyName(item);

        return newItem;
    }

    changeInterval = event => {
        let exportInterval = event.target.value;
        this.setState({exportInterval});
    }

    export = () => {
        let data = {
            interval: this.state.exportInterval
        };
        apiRequest("/system-logs/export", {method: "GET", data}, {blob: true})
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'registro.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
    }

    getCompanyName = (item) => {

        console.log('item -> ', item)
        if (item.object && item.object.organization && item.object.organization.name) {
            console.log('entrou ', item.object.organization.name)
            return item.object.organization.name
        }
        console.log("nao entrou")
        return ''
    }

    render() {
        let {items} = this.state;

        items = items.map(item => this.transformEntry(item));

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">
                                      {window.gettext('Registro de ações')}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form className="form-horizontal float-right mb-2">
                                        <Row>
                                            <Col md="2"/>
                                            <Label md="3">
                                              {window.gettext('Intervalo em dias')}
                                            </Label>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Input type="number" value={this.state.exportInterval}
                                                           onChange={this.changeInterval}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <Button color="default" type="button" style={{margin: 0}}
                                                        onClick={this.export}
                                                >
                                                  {window.gettext('Exportar Excel')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Table responsive>
                                        <thead className="text-primary">
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th>
                                              {window.gettext('Data')}</th>
                                            <th>
                                              {window.gettext('Autor')}</th>
                                            <th>
                                              {window.gettext('Evento')}
                                            </th>
                                            <th>
                                                {window.gettext('Empresa')}
                                            </th>
                                            <th>
                                              {window.gettext('Descrição')}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {items.map(item => {

                                            return <tr key={item.id}>
                                                <td className="text-center">{item.id}</td>
                                                <td>{item.created_at}</td>
                                                <td>{item.user_name}</td>
                                                <td>{item.event}</td>
                                                <td>{item.company_name}</td>
                                                <td>{item.object}</td>
                                            </tr>;

                                        })}

                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <PaginationCtrl responseMeta={this.state.meta}
                                    requestPage={this.requestData}
                    />
                </div>
            </>
        );
    }
}

export default SystemLogList;
