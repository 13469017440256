import apiRequest from "../lib/Api";

class Appointments {
  async getNextAppointment() {
    return apiRequest("/appointments/next", {method: "GET"})
      .then(response => {
        let appointment = response.data.data;
        if (appointment && appointment.datetime_start === '(deletado)') {
          return null;
        }
        return appointment;
      });
    }
}

let singleton = new Appointments;
export default singleton;
