import React from "react";

// reactstrap components
import {
  Button,
  Modal,
} from "reactstrap";

class ConfirmModal extends React.Component
{
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.open
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({isOpen: this.props.open});
    }
  }

  toggle = () => {
    this.setState({isOpen: !this.state.isOpen},
      () => this.props.onToggle && this.props.onToggle(this.state.isOpen)
    );
  }

  cancel = () => {
    this.toggle();
  }

  confirm = () => {
    this.toggle();
    this.props.onConfirm();
  }

  render() {
    return (
      <Modal
        className="modal-sm"
        isOpen={this.state.isOpen}
        toggle={this.toggle}
      >
        <div className="modal-header justify-content-center">
          <div className="modal-profile ml-auto mr-auto">
            <i className="nc-icon nc-bulb-63" />
          </div>
        </div>
        <div className="modal-body">
          <p>{this.props.message}</p>
        </div>
        <div className="modal-footer" style={{flexWrap: "initial"}}>
          <div className="left-side" style={{float: 'left'}}>
            <Button color="link" type="button"
              onClick={this.cancel}
            >
              {window.gettext('Cancelar')}
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={this.confirm}
            >
              {window.gettext('Confirmar')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

ConfirmModal.defaultProps = {
  message: '',
  open: false
}

export default ConfirmModal;
