import React from "react";
import {Link} from 'react-router-dom'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Form
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import withNotify from "../../../lib/NotificationWrapper";
import {toCurrency} from "../../../lib/Formatter";

import PaginationCtrl from "components/Pagination/PaginationCtrl";

class PurchaseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      admin_name: '',
      company: ''
    };
  }

  componentDidMount() {
    this.requestData();
  }

  requestData = page => {
    if (!page) {
      page = 1;
    }
    let data = {page};
    if (this.state.admin_name) {
      data.admin_name = this.state.admin_name || ''
    }
    if (this.state.company) {
      data.company = this.state.company  || ''
    }
    apiRequest("/purchases", {data, method: "GET"})
      .then(response => {
        let {data, meta} = response.data;
        this.setState({items: data, meta});
      });
  }

  toggleConfirmation = (show) =>  {
    this.setState({showDeleteConfirmation: show});
  }

  maybeDelete = (id) => {
    this.setState({deleteId: id});
    this.toggleConfirmation(true);
  }

  delete = () => {
    const id = this.state.deleteId;
    apiRequest("/purchases/" + this.state.deleteId, {method: "DELETE"})
      .then(() => {
        this.props.notify({type: 'success', message: window.gettext('Compra removida com sucesso')});

        let items = this.state.items.filter(item => item.id !== id);
        this.setState({items});
      });
  }

  filterChange = (field, value) => {

    this.setState({[field]: value});
  }
  executeFilter = (event) => {
    event.preventDefault();
    this.requestData();
  }

  render() {
    let {items} = this.state;

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    {window.gettext('Lista')}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontal">
                    <Row>
                      <Label md="1">
                        {window.gettext('Buscar')}</Label>
                      <Col md="5">
                        <FormGroup>
                          <Input placeholder="Nome do administrador ou e-mail" type="text"
                                 onChange={(event) => {
                                   this.filterChange('admin_name', event.target.value)
                                 }
                                 } value={this.state.admin_name}
                                 onKeyPress={event => event.which === 13 && event.preventDefault()}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="5">
                        <FormGroup>
                          <Input placeholder="nome da empresa " type="text"
                                 onChange={(event) => {
                                   this.filterChange('company', event.target.value)
                                 }} value={this.state.company}
                                 onKeyPress={event => event.which === 13 && event.preventDefault()}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="1">
                        <Button color="info" type="button" style={{margin: '1px -15px'}}
                                onClick={this.executeFilter}
                        >
                          <i className="fa fa-search"/>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">#</th>
                        <th>
                          {window.gettext('Usuário')}
                        </th>
                        <th>
                          {window.gettext('Produto')}
                        </th>
                        <th>
                          {window.gettext('Quantidade')}
                        </th>
                        <th>
                          {window.gettext('Valor cobrado')}
                        </th>
                        <th>
                          {window.gettext('Data da compra')}
                        </th>
                        <th className="text-right">
                          {window.gettext('Ações')}</th>
                      </tr>
                    </thead>
                    <tbody>

                      {items.map(item => {

                        return <tr key={item.id}>
                          <td className="text-center">{item.id}</td>
                          <td>{item.user.name}</td>
                          <td>{item.product.name}</td>
                          <td>{item.quantity}</td>
                          <td>{toCurrency(item.price_paid)}</td>
                          <td>{item.purchased_at}</td>

                          <td className="text-right">
                            <Button
                              tag={Link}
                              className="btn-icon btn-neutral"
                              color="success"
                              size="sm"
                              to={`/admin/purchases/${item.id}/edit`}
                            >
                              <i className="fa fa-edit" />
                            </Button>{" "}
                            <Button
                              className="btn-icon btn-neutral"
                              color="danger"
                              size="sm"
                              type="button"
                              onClick={() => this.maybeDelete(item.id)}
                            >
                              <i className="fa fa-times" />
                            </Button>{" "}

                          </td>
                        </tr>;

                      })}

                    </tbody>
                  </Table>
                  <PaginationCtrl responseMeta={this.state.meta}
                    requestPage={this.requestData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <ConfirmModal message={window.gettext('Tem certeza que deseja remover esta compra?"')}
          onConfirm={() => this.delete()}
          onToggle={() => this.toggleConfirmation(false)}
          open={this.state.showDeleteConfirmation}
        />
      </>
    );
  }
}

export default withNotify(PurchaseList);
