import React from "react";
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import moment from "moment-timezone";
// reactstrap components
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Collapse, Progress, Row} from "reactstrap";


import Services from "models/Services";
import Appointments from "models/Appointments";
import {checkUserProfile, getUserData} from "lib/Auth";
import FormatText from 'components/Text/FormatText.jsx';
import withNotify from "lib/NotificationWrapper";
import enviroment from "../../../services/enviroment";

class NewHomeMember extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            purchaseServices: [],
            nextAppointment: null,
            working: false,
            products: [],
            openedCollapses: []
        };
        this.user = getUserData();
        moment.lang(this.user.language);
    }

    get serviceFilter() {
        let qs = this.props.location.search || '';
        if (qs) {
            let params = queryString.parse(qs);
            return params.service || null;
        }
        return null;
    }

    componentDidMount() {
        checkUserProfile().finally(async () => {
            await this.fetchServices();


            const products = []
            this.state.purchaseServices.map(async purchaseService => {

                let theProduct = products.find(p => p.id === purchaseService.purchase.product.id)
                if (theProduct === undefined) {
                    theProduct = {
                        id: purchaseService.purchase.product.id,
                        name: purchaseService.purchase.product.name,
                        items: [],
                    }
                }
                theProduct.items.push(purchaseService)
                products.push(theProduct)
            })

            const uniqueArray = products.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === value.id && t.name === value.name
                    ))
            );
            this.setState({products: uniqueArray})
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search != prevProps.location.search) {
            this.fetchServices();
        }
    }

    async fetchServices() {
        let purchaseServices = await Services.getPurchasedServices(true);
        if (this.serviceFilter) {
            purchaseServices = purchaseServices.filter(ps => ps.service.name === this.serviceFilter);
        }

        let hasSpecialistCalls = purchaseServices.find(ps => ps.service.name === Services.consts.SPECIALIST_CALL);
        if (hasSpecialistCalls) {
            let nextAppointment = await Appointments.getNextAppointment();
            this.setState({purchaseServices, nextAppointment});
        } else {
            this.setState({purchaseServices});
        }
    }

    renderImageCard(service, product) {

        if (enviroment.isNewUserInterface() || enviroment.isLumiUserInterface()) {
            return <></>
        }
        return <>
            <Col md="auto">
                <img className="card-image" src={service.image || product.image}/>
            </Col>
        </>
    }

    renderActionCard(purchaseService, productId) {
        let {purchase, productService} = purchaseService;
        let {product} = purchase;
        let {service} = productService;

        if (productId !== product.id) {
            return null;
        }


        return (
            <Card className="card-pricing card-service" key={'ps-' + purchaseService.id}>
                <CardHeader>
                    <Row noGutters>
                        {this.renderImageCard(service, product)}
                        <Col>
                            <h6 className="card-title text-selfguru">{purchaseService.name}</h6>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {productService.description &&
                        <div className="card-prod2">
                            <FormatText content={productService.description}/>
                        </div>
                    }
                    {!productService.description &&
                        <div className="card-prod2">
                            <FormatText content={product.description1}/>
                            <FormatText content={product.description2}/>
                        </div>
                    }
                    {this.renderTasks(purchaseService.tasks)}
                </CardBody>
                <CardFooter className="text-center">
                    {this.renderAction(purchaseService)}
                </CardFooter>
            </Card>
        );
    }

    renderTasks(tasks) {
        if (tasks && tasks.total === 0) {
            return <></>
        }
        let textoTarefas = window.gettext('Há %1 Tarefas Pendentes', tasks.open);
        if (tasks.finished) {
            textoTarefas = window.gettext('Sem tarefas pendentes');
        }
        return (
            <React.Fragment>
                <h6>{textoTarefas}</h6>
                <div className="program-progress">
                    <Progress
                        max={tasks.total}
                        value={tasks.done}
                        barClassName="progress-bar-info"
                    />
                    <span>
                     ({tasks.done + '/' + tasks.total})
                    </span>
                </div>
            </React.Fragment>
        )
    }

    renderAction(purchaseService) {

        if (purchaseService.service.name === 'online_course') {
            const linkAction = Services.getAction(purchaseService);
            return (
                <a
                    className="btn-round btn btn-primary" href={linkAction.link} target="blank"
                >
                    {window.gettext('Começar')}
                </a>
            )
        }

        let buttonDisabled = !purchaseService.tasks.finished;
        if (purchaseService.usage > 0) {
            if (purchaseService.usage === purchaseService.quota) {
                return (
                    <div className="card-icon icon-primary card-icon-status">
                        <i className="nc-icon nc-check-2"/>
                    </div>
                )
            } else if (purchaseService.service.name === Services.consts.SPECIALIST_CALL) {
                return this.renderActionAppointment(purchaseService, buttonDisabled);
            } else {
                return ''; // TODO revisit this
            }
        } else {
            let action = Services.getAction(purchaseService);
            if (this.state.working && this.state.working === purchaseService.id) {
                return (
                    <Button className="btn-round" color="secondary" disabled={true}>
                        {window.gettext('Processando')}
                    </Button>
                )
            } else if (purchaseService.notified) {
                return (
                    <Button className="btn-round btn-warning" disabled={true}>
                        {window.gettext('Aguardando resposta')}
                    </Button>
                )
            } else if (action.link) {
                return (
                    <a
                        className="btn-round btn btn-primary" href={action.link} target="blank"
                    >
                        {window.gettext('Começar')}
                    </a>
                )
            } else if (action.route) {
                return (
                    <Button
                        disabled={buttonDisabled}
                        className="btn-round" color="primary" tag={Link} to={action.route}
                    >
                        {window.gettext('Começar')}
                    </Button>
                )
            } else if (action.onClick) {
                return (
                    <Button
                        className="btn-round" color="primary"
                        disabled={buttonDisabled}
                        onClick={() => this.start(purchaseService, action.onClick)}
                    >
                        {window.gettext('Começar')}
                    </Button>
                )
            }
        }
    }

    renderActionAppointment(purchaseService, buttonDisabled) {
        let {nextAppointment} = this.state;

        if (nextAppointment) {
            return (
                <React.Fragment>
                    <p>
                        {window.gettext('Próxima conversa:')} <br/>
                        {moment(nextAppointment.datetime_start).format('LLL')}
                    </p>
                    <Button
                        disabled={buttonDisabled}
                        className="btn-round" color="primary" tag={Link}
                        to={`/admin/appointments/${nextAppointment.id}`}
                    >
                        {window.gettext('Ir para agendamento')} {buttonDisabled}
                    </Button>
                </React.Fragment>
            );
        } else {
            let action = Services.getAction(purchaseService);
            return (
                <Button
                    disabled={buttonDisabled}
                    className="btn-round" color="primary" tag={Link} to={action.route}
                >
                    {window.gettext('Novo agendamento')}
                </Button>
            );
        }
    }

    renderPurchaseCard() {
        return (
            <Card className="card-pricing">
                <CardHeader>
                    <h6 className="card-category">
                        {window.gettext('Por onde começar?')}
                    </h6>
                </CardHeader>
                <CardBody>
                    <div className="card-icon icon-primary">
                        <i className="nc-icon nc-hat-3"/>
                    </div>
                    <ul>
                        <li>{window.gettext('Você ainda não tem serviços disponíveis.')}</li>
                        <li>{window.gettext('Compre seu primeiro produto para começar!')}</li>
                    </ul>
                </CardBody>
            </Card>
        );
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };

    render() {

        const products = this.state.products || []
        return <>
            <div className={"content"}>
                {products.map((product) => (
                    <Card key={product.id}>
                        <CardHeader className="p-3" onClick={() => this.collapsesToggle(product.id)}>
                            <span style={{textDecoration: "underline", cursor: "pointer"}}>{ product.name }</span> {" "}
                            <i className="nc-icon nc-minimal-down" />
                        </CardHeader>
                        <Collapse
                            role="tabpanel"
                            isOpen={this.state.openedCollapses.includes(
                                product.id
                            )}
                        >
                            <CardBody>
                                {this.renderCards(product.id)}
                            </CardBody>
                        </Collapse>
                    </Card>
                ))}
            </div>
        </>
    }


    renderCards(productId) {

        let {purchaseServices} = this.state;
        let cards = [];
        if (purchaseServices.length > 0) {
            cards = purchaseServices.map(ps => this.renderActionCard(ps, productId));
        } else {
            cards.push(this.renderPurchaseCard());
        }

        let colParams;
        if (cards.length === 1) {
            colParams = {size: 4, offset: 4};
        } else {
            colParams = {size: 4};
        }

        return (
            <>
                <div className="content dashboard-member-container">
                    <Row>
                        {cards && cards.map((card, idx) => (
                            <Col md={{...colParams}} key={'col-card-' + idx}>
                                {card}
                            </Col>
                        ))}
                    </Row>
                </div>
            </>
        );
    }

    start = (purchaseService, clickHandler) => {
        this.setState({working: purchaseService.id});

        clickHandler().then(data => {
            this.setState({working: false});
            this.props.notify({
                type: 'success',
                message: window.gettext('Uma mensagem foi enviada para a nossa equipe! Em breve você receberá uma orientação para prosseguir.')
            });

            // replace purchase service
            let {purchaseServices} = this.state;
            let idx = purchaseServices.findIndex(ps => ps.id === data.data.id);
            if (idx !== -1) {
                purchaseServices = [...purchaseServices];
                purchaseServices[idx] = data.data;
                this.setState({purchaseServices});
            }
        });
    }
}

export default withNotify(NewHomeMember);
