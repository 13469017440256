import React, {useState} from "react";

// reactstrap components
import {
    Badge,
    Button,
    CardBody,
    CardHeader,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Progress,
    Row
} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";

class CardShowContent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        }
    }

    get title() {
        if (!this.props.title) {
            return '';
        }
        return this.props.title;
    }

    get isHigher() {
        if (!this.props.isHigher) {
            return false;
        }
        return this.props.isHigher;
    }

    get total() {
        if (!this.props.total) {
            return 0;
        }
        return this.props.total;
    }

    get topScore() {
        if (!this.props.topScore) {
            return 0
        }
        return this.props.topScore
    }

    get styleBarProgress() {
        return this.isHigher ? 'success' : 'warning'
    }

    get description() {
        if (!this.props.description) {
            return '';
        }
        return this.props.description;
    }

    renderBadge() {

        let cssClassName = 'warning';
        let title = window.gettext('Abaixo da Média');
        if (this.isHigher) {
            cssClassName = 'success';
            title = window.gettext('Acima da Média');
        }

        return (
            <Badge color={cssClassName} pill>
                {/*{title} / {this.total}*/}
                {title}
            </Badge>
        );
    }

    getHelpDescription() {
        if (!this.props.helper) {
            return '';
        }
        return this.props.helper;
    }

    toggle = () => {
        this.setState({modal: !this.state.modal});
    }

    render() {

        let maxValue = 100;
        let curValue = 100;
        if ( ! this.isHigher ) {
            curValue = 33;
        }

        return (
            <>
                <Modal isOpen={this.state.modal} size={'lg'}>
                    <ModalHeader toggle={this.toggle} className={"performance-grou modal-header"}>
                        {this.title}
                    </ModalHeader>
                    <ModalBody>
                        {this.getHelpDescription()}
                    </ModalBody>
                    <ModalFooter>
                        <Button color={'info'} onClick={this.toggle}>
                            {window.gettext('Fechar')}
                        </Button>
                    </ModalFooter>
                </Modal>
                <div className="content ancora-container">
                    <Row>
                        <Col md={12}>
                            <CardHeader tag="h5">
                                <Row>
                                    <Col md={6}>
                                        {this.title}
                                        <i className="nc-icon nc-alert-circle-i performance-grou icon-result" onClick={this.toggle}/>
                                    </Col>
                                    <Col md={6} className="text-right">
                                        {/*<Progress max={this.topScore} value={this.total} color={this.styleBarProgress} />*/}
                                        <Progress max={maxValue} value={curValue} color={this.styleBarProgress} />
                                        {this.renderBadge()}
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.description}
                            </CardBody>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(CardShowContent);
