import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import enviroment from "./services/enviroment";

import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

import AuthLayout from "layouts/Auth/Auth.jsx";
import AdminLayout from "layouts/Admin/Admin.jsx";
import SetupLayout from "layouts/Setup/Setup.jsx";
import ChatLayout from "layouts/Chat/Chat.jsx";
import i18n from 'i18n/i18n';
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";

import variablesLumi from "assets/scss/lumi/_variables-lumi.scss";
import variablesSelfGuruGrou from "assets/scss/_variables-selfguru-grou.scss";


import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import {ClearCacheProvider, useClearCacheCtx} from "react-clear-cache";
import {Modal, ModalBody, ModalHeader} from "reactstrap";

import { clarity } from 'react-microsoft-clarity';

window.translator = i18n;
window.gettext = i18n.gettext.bind(i18n);
const bugsnagClient = bugsnag({
  apiKey: 'ebca4a02588e73ffa5b5e5173fe35713',
  releaseStage: process.env.NODE_ENV,
  notifyReleaseStages: ['production']
});

bugsnagClient.use(bugsnagReact, React);

const hist = createBrowserHistory();
const App: React.FC = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

    if (process.env.NODE_ENV === 'production') {
        clarity.init('lukzxcm3ot');
    }



  return (
      <div >
        {!isLatestVersion && (
            <Modal isOpen={true}>
              <ModalHeader style={{position: 'sticky'}}>Nova versão disponível!</ModalHeader>
              <ModalBody style={{textAlign: 'center'}}>
                <h3>
                    <i className="fa fa-sync"/>&nbsp;
                  <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        emptyCacheStorage();
                      }}
                  >
                    Atualizar agora
                  </a>
                </h3>
              </ModalBody>
            </Modal>

        )}
      </div>
  );
};

let domain = (new URL(window.location));
domain = domain.hostname;


const root = document.documentElement;
if (enviroment.isNewUserInterface() /* e domain !== lumidomain */) {
    for (const variable in variablesSelfGuruGrou) {
        root.style.setProperty(variable, getComputedStyle(root).getPropertyValue(variable+"-grou"));
    }
}



if ( enviroment.isLumiUserInterface()) {
  for (const variable in variablesLumi) {
      root.style.setProperty(variable, getComputedStyle(root).getPropertyValue(variable+"-lumi"));
  }
}

const ErrorBoundary = bugsnagClient.getPlugin('react');
ReactDOM.render(
  <ErrorBoundary>
    <ClearCacheProvider duration={10000}>
      <App/>
    <Router history={hist}>
      <Switch>
        <Route path="/auth" render={props => <AuthLayout {...props} />} />
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Route path="/setup" render={props => <SetupLayout {...props} />} />
        <Route path="/chat" render={props => <ChatLayout {...props} />} />
        <Route exact path="/linkedin" component={LinkedInPopUp} key="linkedin"/>
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </Router>
    </ClearCacheProvider>
  </ErrorBoundary>,
  document.getElementById("root")  
);

// bugsnagClient.notify(new Error('Test error'));
