import React from "react";
import classnames from "classnames";
import {Button, Collapse, Container, Nav, Navbar, NavItem} from "reactstrap";
import {getUserData} from "../../lib/Auth";
import NotificationIcon from "../../views/components/NotificationIcon";

class AdminNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseOpen: false,
            color: "navbar-transparent",
            userData: getUserData()
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor);
    }

    componentDidUpdate(e) {
        if (
            window.outerWidth < 993 &&
            e.history.location.pathname !== e.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }
    }

    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    updateColor = () => {
        if (window.innerWidth < 993 && this.state.collapseOpen) {
            this.setState({
                color: "bg-white"
            });
        } else {
            this.setState({
                color: "navbar-transparent"
            });
        }
    };
    // this function opens and closes the sidebar on small devices
    toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
    };
    // this function opens and closes the collapse on small devices
    // it also adds navbar-transparent class to the navbar when closed
    // ad bg-white when opened
    toggleCollapse = () => {
        let newState = {
            collapseOpen: !this.state.collapseOpen
        };
        if (!this.state.collapseOpen) {
            newState["color"] = "bg-white";
        } else {
            newState["color"] = "navbar-transparent";
        }
        this.setState(newState);
    };

    render() {
        let links = [
            {lang: 'pt_BR', url: 'https://selfguru.com.br/faq/'},
            {lang: 'en_US', url: 'https://selfguru.com.br/faq-eng/'},
        ]
        let language = 'pt_BR';
        if (this.state.userData) {
            language = this.state.userData.language;
        }
        let link = links.find(link => link.lang === language);
        if (!link) {
            link = links[0];
        }
        return (
            <>
                <Navbar
                    className={classnames("navbar-absolute fixed-top", this.state.color)}
                    expand="lg"
                >
                    <Container fluid>
                        <div className="navbar-wrapper">
                            <div className="navbar-minimize">
                                <Button
                                    className="btn-icon btn-round"
                                    color="default"
                                    id="minimizeSidebar"
                                    onClick={this.props.handleMiniClick}
                                >
                                    <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"/>
                                    <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"/>
                                </Button>
                            </div>
                            <div
                                className={classnames("navbar-toggle", {
                                    toggled: this.state.sidebarOpen
                                })}
                            >
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    onClick={this.toggleSidebar}
                                >
                                    <span className="navbar-toggler-bar bar1"/>
                                    <span className="navbar-toggler-bar bar2"/>
                                    <span className="navbar-toggler-bar bar3"/>
                                </button>
                            </div>
                        </div>
                        <button
                            aria-controls="navigation-index"
                            aria-expanded={this.state.collapseOpen}
                            aria-label="Toggle navigation"
                            className="navbar-toggler"
                            // data-target="#navigation"
                            data-toggle="collapse"
                            type="button"
                            onClick={this.toggleCollapse}
                        >
                            <span className="navbar-toggler-bar navbar-kebab"/>
                            <span className="navbar-toggler-bar navbar-kebab"/>
                            <span className="navbar-toggler-bar navbar-kebab"/>
                        </button>

                        <Collapse
                            className="justify-content-end"
                            navbar
                            isOpen={this.state.collapseOpen}
                        >

                            <Nav navbar>
                                <NavItem style={{padding: '11px 22px'}}>
                                    <a
                                        to="/admin/my-tasks">
                                        <NotificationIcon/>
                                    </a>
                                </NavItem>
                                <NavItem>
                                    <a
                                        className={'btn'}
                                        href={link.url}
                                        target={"_blank"}
                                    >
                                        <i className="fa fa-question-circle" style={{fontSize: "18px"}}/>
                                        {window.gettext('PRECISA DE AJUDA?')}
                                        <p>
                                            <span className="d-lg-none d-md-block"/>
                                        </p>
                                    </a>
                                </NavItem>
                            </Nav>
                        </Collapse>

                    </Container>
                </Navbar>
            </>
        );
    }
}

export default AdminNavbar;
