import React from "react";
import {Link} from 'react-router-dom'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  Label,
  FormGroup,
  Input
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import withNotify from "../../../lib/NotificationWrapper";

import PaginationCtrl from "components/Pagination/PaginationCtrl";

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      meta: {},
      filter: '',
      exportInterval: 30
    };
  }

  get scope() {
    if (this.props.match.path.match(/users/)) {
      return 'users';
    }
    else if (this.props.match.path.match(/specialists/)) {
      return 'specialists';
    }
    throw new Error("Invalid scope");
  }

  get role() {
    if (this.scope === 'users') {
      return 'student';
    }
    if (this.scope === 'specialists') {
      return 'specialist';
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    // TODO use params instead
    if (this.props.match.path !== prevProps.match.path) {
      this.requestData();
    }
  }

  componentDidMount() {
    this.requestData();
  }

  requestData = page => {
    let data = {
      page: page || 1
    };

    if (this.props.match.params.organizationId) {
      data.organization_id = this.props.match.params.organizationId;
    }
    else {
      data.searchRole = this.role;
    }

    if (this.state.filter) {
      data.search = this.state.filter;
    }

    apiRequest("/users", {method: "GET", data})
      .then(response => {
        let {data, meta} = response.data;
        this.setState({users: data, meta});
      });
  }

  toggleConfirmation = (show) =>  {
    this.setState({showDeleteConfirmation: show});
  }

  filterChange = (event) => {
    let value = event.target.value;
    this.setState({filter: value});
  }

  executeFilter = (event) => {
    event.preventDefault();
    this.requestData();
  }

  maybeDeleteUser = (id) => {
    this.setState({deleteUserId: id});
    this.toggleConfirmation(true);
  }

  deleteUser = () => {
    const id = this.state.deleteUserId;
    apiRequest("/users/" + this.state.deleteUserId, {method: "DELETE"})
      .then(() => {
        this.props.notify({type: 'success', message: window.gettext('Usuário removido com sucesso')});

        let users = this.state.users.filter(user => user.id !== id);
        this.setState({users});
      })
      .catch((response) => {
        let message = window.gettext('Não foi possível remover o usuário');
        if (response.data && response.data.message) {
          message = response.data.message;
        }
         this.props.notify({type: 'error', message: message});
      });
  }

  changeInterval = event => {
    let exportInterval = event.target.value;
    this.setState({exportInterval});
  }

  export = () => {
    let data = {
      interval: this.state.exportInterval
    };
    apiRequest("/users/export", {method: "GET", data}, {blob: true})
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'users.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
  }

  render() {
    let {users} = this.state;

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    {window.gettext('Lista')}
                    </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontal">
                    <Row>
                      <Label md="1">
                        {window.gettext('Buscar')}</Label>
                      <Col md="3">
                        <FormGroup>
                          <Input placeholder="Nome ou e-mail" type="text"
                            onChange={this.filterChange} value={this.state.filter}
                            onKeyPress={event => event.which === 13 && event.preventDefault()}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="1">
                        <Button color="info" type="button" style={{margin: '1px -15px'}}
                          onClick={this.executeFilter}
                        >
                          <i className="fa fa-search"/>
                        </Button>
                      </Col>

                      <Label md="2">
                        {window.gettext('Intervalo em dias')}
                      </Label>
                      <Col md="2">
                        <FormGroup>
                          <Input type="number" value={this.state.exportInterval}
                            onChange={this.changeInterval}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <Button color="default" type="button" style={{margin: 0}}
                          onClick={this.export}
                        >
                          {window.gettext('Exportar Excel')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">#</th>
                        <th>
                          {window.gettext('Nome')}
                        </th>
                        <th>{window.gettext('E-mail')}</th>
                        <th>{window.gettext('Empresa/Papel')}</th>
                        <th className="text-right">{window.gettext('Ações')}</th>
                      </tr>
                    </thead>
                    <tbody>

                      {users.map(user => {

                        return <tr key={user.id}>
                          <td className="text-center">{user.id}</td>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{
                            user.organization ? `${user.organization.name} (${user.organization.user_role})` : ''
                          }</td>

                          <td className="text-right">
                            <Button
                              tag={Link}
                              className="btn-icon btn-neutral"
                              color="success"
                              id="tooltip264453216"
                              size="sm"
                              to={`/admin/${this.scope}/${user.id}/edit`}
                            >
                              <i className="fa fa-edit" />
                            </Button>{" "}
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip264453216"
                            >
                              {window.gettext('Editar')}
                            </UncontrolledTooltip>

                            <Button
                              className="btn-icon btn-neutral"
                              color="danger"
                              id="tooltip476609793"
                              size="sm"
                              type="button"
                              onClick={() => this.maybeDeleteUser(user.id)}
                            >
                              <i className="fa fa-times" />
                            </Button>{" "}
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip476609793"
                            >
                              {window.gettext('Remover')}
                            </UncontrolledTooltip>
                          </td>
                        </tr>;

                      })}

                    </tbody>
                  </Table>
                  <PaginationCtrl responseMeta={this.state.meta}
                    requestPage={this.requestData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <ConfirmModal message={window.gettext("Tem certeza que deseja remover este usuário?")}
          onConfirm={() => this.deleteUser()}
          onToggle={() => this.toggleConfirmation(false)}
          open={this.state.showDeleteConfirmation}
        />
      </>
    );
  }
}

export default withNotify(UserList);
