// import React from "react";
import Home from "views/pages/dashboard/Home.jsx";
import Login from "views/pages/Login.jsx";
import Register from "views/pages/Register.jsx";
import UserProfile from "views/pages/UserProfile.jsx";
import UserList from "views/pages/users/UserList";
import UserForm from "views/pages/users/UserForm";
import ImportUsers from "views/pages/users/ImportUsers";
import ProductList from "views/pages/products/ProductList";
import ProductForm from "views/pages/products/ProductForm";
import ProductPurchase from "views/pages/products/ProductPurchase";
import PurchaseForm from "views/pages/purchases/PurchaseForm";
import PurchaseList from "views/pages/purchases/PurchaseList";
import ExceptionForm from "views/pages/exceptions/ExceptionForm";
import ExceptionList from "views/pages/exceptions/ExceptionList";
import StandardsCalendar from "views/pages/standards/StandardsCalendar";
import AppointmentCreate from "views/pages/appointments/AppointmentCreate";
import AppointmentCreateAdmin from "views/pages/appointments/AppointmentCreateAdmin";
import AppointmentCalendar from "views/pages/appointments/AppointmentCalendar";
import AppointmentShow from "views/pages/appointments/AppointmentShow";
import AppointmentForm from "views/pages/appointments/AppointmentForm";
import OrganizationForm from "views/pages/organizations/OrganizationForm";
import OrganizationList from "views/pages/organizations/OrganizationList";
import OrganizationUsage from "views/pages/organizations/OrganizationUsage";
import SystemLogList from "views/pages/admin/SystemLogList";
import Chat from "views/pages/Chat";
import FileManager from "views/pages/FileManager";
import ForgotPassword from "views/pages/ForgotPassword";
import ResetPassword from "views/pages/ResetPassword";
import EmailTemplateForm from "views/pages/admin/EmailTemplateForm";
import ChooseSpecialist from "views/pages/appointments/ChooseSpecialist";
import ProductCheckout from "views/pages/products/ProductCheckout";
import SpecialistServices from "views/pages/users/SpecialistServices";
import UserServices from "views/pages/users/UserServices";
import PurchaseUsers from "views/pages/purchases/PurchaseUsers";
import UploadCv from "views/pages/users/UploadCv";
import Ancora from "views/pages/services/Ancora";
import PerformanceGrou from "views/pages/services/PerformanceGrou";
import ChangePasswordForm from "views/pages/users/ChangePassword";
import UsersBlock from "views/pages/users/UsersBlock";
import ExtendedData from "./views/pages/admin/ExtendedData";
import ListExtendedData from "./views/pages/admin/ListExtendedData";
import i18n from 'i18n/i18n';
import ChangeCompany from "./views/pages/users/ChangeCompany";
import MyTasks from "./views/pages/users/MyTasks";
import SpecialistDiary from "./views/pages/users/SpecialistDiary";
import UsersTasks from "./views/pages/users/UsersTasks";
import SpecialistDiaryList from "./views/pages/users/SpecialistDiaryList";
import UserUsage from "./views/components/UserUsage";
import ChangePassword from 'views/pages/users/ChangePassword'
import ActivationSetup from './views/pages/users/ActivationSetup'
import ExportacaoAtendimentosEspecialistas from 'views/pages/admin/ExportacaoAtendimentosEspecialistas.jsx'

import {getAuthToken} from "./lib/Auth";
import enviroment from "./services/enviroment";
import AppointmentList from "./views/components/Appointment/AppointmentList";
const userToken = getAuthToken();
const userTokenEncoded = window.btoa(userToken)
const urlEad = enviroment.getEadUrl() || 'http://localhost:8081/'

const routes = [
  {
    path: "/choose-specialist/:purchaseServiceId",
    component: ChooseSpecialist,
    layout: "/setup",
    redirect: true
  },
  {
    path: "/choose-specialist/:purchaseServiceId",
    component: ChooseSpecialist,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/dashboard",
    name: window.gettext("Dashboard"),
    icon: "nc-icon nc-bank",
    component: Home,
    layout: "/admin"
  },
  {
    path: "/appointments/list",
    name: window.gettext("Encontros"),
    icon: 'nc-icon nc-calendar-60',
    component: AppointmentList,
    layout: "/admin",
    permissions: ["student", "specialist", "super_admin"],
  },
  {
    path: "/my-tasks",
    mini: "MT",
    name: window.gettext("Minhas Tarefas"),
    icon: "nc-icon nc-bullet-list-67",
    component: MyTasks,
    layout: "/admin",
    permissions: ["student", "specialist"]
  },
  {
    path: "/specialist-diary",
    mini: "DE",
    name: window.gettext("Diário da Especialista"),
    icon: "nc-icon nc-paper",
    component: SpecialistDiary,
    layout: "/admin",
    permissions: ["specialist"]
  },
  {
    collapse: true,
    name: window.gettext("Usuários"),
    icon: "nc-icon nc-single-02",
    state: "usersCollapse",
    permissions: ["super_admin", "admin", "specialist"],
    views: [
      {
        path: "/users/new",
        name: window.gettext("Cadastrar Novo"),
        mini: "NE",
        component: UserForm,
        layout: "/admin",
        permissions: ["super_admin", "admin"]
      },
      {
        path: "/users/list/organization/:organizationId",
        name: window.gettext("Listar"),
        mini: "LE",
        component: UserList,
        layout: "/admin",
        redirect: true,
        permissions: ["super_admin", "admin"]
      },
      {
        path: "/users/list",
        name: window.gettext("Listar"),
        mini: "LE",
        component: UserList,
        layout: "/admin",
        permissions: ["super_admin", "admin"]
      },
      {
        path: "/users/services",
        name: window.gettext("Associar Atividades"),
        mini: "AA",
        component: PurchaseUsers,
        layout: "/admin",
        permissions: ["super_admin", "admin"]
      },
      {
        path: "/users/block",
        name: window.gettext("Usuários Bloqueados"),
        mini: "UB",
        component: UsersBlock,
        layout: "/admin",
        permissions: ["super_admin"]
      },
      {
        path: "/users/status",
        name: window.gettext("Status no Programa"),
        mini: "SP",
        component: UserServices,
        layout: "/admin",
        permissions: ['super_admin', 'specialist']
      },
      {
        path: "/users/import",
        name: window.gettext("Importar usuários"),
        mini: "IU",
        component: ImportUsers,
        layout: "/admin",
        permissions: ['super_admin']
      },
      {
        path: "/users/tasks",
        name: window.gettext("Tarefas Executadas"),
        mini: "TE",
        component: UsersTasks,
        layout: "/admin",
        permissions: ['super_admin', "admin", 'specialist']
      }
    ]
  },
  {
    collapse: true,
    name: window.gettext("Especialistas"),
    icon: "nc-icon nc-hat-3",
    state: "specialistsCollapse",
    permissions: "super_admin",
    views: [
      {
        path: "/specialists/new",
        name: window.gettext("Cadastrar Novo"),
        mini: "NS",
        component: UserForm,
        layout: "/admin"
      },
      {
        path: "/specialists/list",
        name: window.gettext("Listar"),
        mini: "LS",
        component: UserList,
        layout: "/admin"
      },
      {
        path: "/specialists/services",
        name: window.gettext("Associar Serviços"),
        mini: "AS",
        component: SpecialistServices,
        layout: "/admin"
      },
      {
        path: "/diary-specialist-list",
        mini: "DE",
        name: window.gettext("Diários das Especialistas"),
        component: SpecialistDiaryList,
        layout: "/admin",
        permissions: ["super_admin"]
      },
    ]
  },
  {
    collapse: true,
    name: window.gettext("Empresas"),
    icon: "nc-icon nc-badge",
    state: "organizationsCollapse",
    permissions: "super_admin",
    views: [
      {
        path: "/organizations/new",
        name: window.gettext("Cadastrar Novo"),
        mini: "NE",
        component: OrganizationForm,
        layout: "/admin"
      },
      {
        path: "/organization/list",
        name: window.gettext("Listar"),
        mini: "LE",
        component: OrganizationList,
        layout: "/admin"
      },
    ]
  },
  {
    collapse: true,
    name: window.gettext("Produtos"),
    icon: "nc-icon nc-shop",
    state: "productsCollapse",
    permissions: "super_admin",
    views: [
      {
        path: "/products/new",
        name: window.gettext("Cadastrar Novo"),
        mini: "NP",
        component: ProductForm,
        layout: "/admin"
      },
      {
        path: "/products/list",
        name: window.gettext("Listar"),
        mini: "LP",
        component: ProductList,
        layout: "/admin"
      },
    ]
  },
  {
    collapse: true,
    name: window.gettext("Compras"),
    icon: "nc-icon nc-cart-simple",
    state: "purchasesCollapse",
    permissions: "super_admin",
    views: [
      {
        path: "/purchases/new",
        name: window.gettext("Cadastrar Novo"),
        mini: "NC",
        component: PurchaseForm,
        layout: "/admin"
      },
      {
        path: "/purchases/list",
        name: window.gettext("Listar"),
        mini: "LC",
        component: PurchaseList,
        layout: "/admin"
      },
    ]
  },
  {
    collapse: true,
    name: window.gettext("Agendamento"),
    icon: "nc-icon nc-calendar-60",
    state: "scheduleCollapse",
    views: [
      {
        path: "/choose-specialist",
        name: window.gettext("Agendar horário"),
        mini: "AH",
        component: ChooseSpecialist,
        layout: "/admin",
        permissions: "student"
      },
      {
        path: "/choose-user-product-specialist",
        name: window.gettext("Agendar horário"),
        mini: "AH",
        component: AppointmentCreateAdmin,
        layout: "/admin",
        permissions: ["super_admin", "specialist"]
      },
      {
        path: "/appointments/calendar",
        name: window.gettext("Horários agendados"),
        mini: "HA",
        component: AppointmentCalendar,
        layout: "/admin"
      },
      {
        path: "/schedule-standards",
        name: window.gettext("Horários disponíveis"),
        mini: "HD",
        component: StandardsCalendar,
        layout: "/admin",
        permissions: "specialist",
      },
      {
        path: "/schedule-exceptions/new",
        name: window.gettext("Bloqueio de Agenda"),
        mini: "BA",
        component: ExceptionForm,
        layout: "/admin",
        permissions: "specialist",
      },
      {
        path: "/schedule-exceptions/list",
        name: window.gettext("Listar Bloqueios"),
        mini: "LX",
        component: ExceptionList,
        layout: "/admin",
        permissions: "specialist",
      }
    ]
  },
  {
    collapse: true,
    name: window.gettext("Resultados"),
    icon: "nc-icon nc-user-run",
    state: "resultsCollapse",
    permissions: ["student", "specialist", "super_admin"],
    views: [
      {
        path: "/ancora",
        name: window.gettext("Âncora"),
        mini: "RA",
        component: Ancora,
        layout: "/admin",
        permissions: ["student", "specialist", "super_admin"]
      },
      {
        path: "/performancegrou",
        name: window.gettext("Lumi"),
        mini: "LU",
        component: PerformanceGrou,
        layout: "/admin",
        permissions: ["student", "specialist", "super_admin"]
      }
    ]
  },
  {
    collapse: true,
    name: window.gettext("Administrativo"),
    icon: "nc-icon nc-settings",
    state: "administrativeCollapse",
    permissions: "super_admin",
    views: [
      {
        path: "/system-log",
        name: window.gettext("Registro de ações"),
        mini: "RA",
        component: SystemLogList,
        layout: "/admin"
      },
      {
        path: "/exportar-atendimento-especialista",
        name: window.gettext("Exportação atendimentos"),
        mini: "EA",
        component: ExportacaoAtendimentosEspecialistas,
        layout: "/admin"
      },

      {
        path: "/email-templates",
        name: window.gettext("Templates de e-mail"),
        mini: "TE",
        component: EmailTemplateForm,
        layout: "/admin"
      },
      {
        path: "/informacoes-adicionais/new",
        name: window.gettext("Informações Adicionais"),
        mini: "IA",
        component: ExtendedData,
        layout: "/admin"
      },
      {
        path: "/informacoes-adicionais/list/",
        name: window.gettext("listar Informações Adicionais"),
        mini: "LI",
        component: ListExtendedData,
        layout: "/admin"
      },
      {
        path: "/informacoes-adicionais/:id/edit",
        name: window.gettext("listar Informações Adicionais"),
        mini: "LI",
        component: ExtendedData,
        layout: "/admin",
        redirect: true,
      }
    ]
  },
  {
    path: "/files",
    name: window.gettext("Biblioteca"),
    icon: "nc-icon nc-paper",
    component: FileManager,
    layout: "/admin"
  },
  {
    absolute: true,
    name: window.gettext("Cursos"),
    icon: "nc-icon nc-book-bookmark",
    url: `${urlEad}login?t=${userTokenEncoded}`,
    permissions: ["super_admin"],
    component: Home,
  },
  {
    absolute: true,
    url: "https://www.selfguru.com.br",
    name: window.gettext("Acessar Site"),
    icon: "nc-icon nc-globe",
    component: Home,
    layout: "/admin"
  },
  {
    path: "/:scope(users|specialists)/:id/edit",
    component: UserForm,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/users/:id/edit",
    component: ChangePassword,
    layout: "/setup",
    redirect: true
  },
  {
    path: "/product/checkout/:id",
    component: ProductCheckout,
    layout: "/setup",
    redirect: true
  },
  {
    path: "/product/:code",
    component: ProductPurchase,
    layout: "/setup",
    redirect: true
  },
  {
    path: "/products/:id/edit",
    component: ProductForm,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/purchases/:id/edit",
    component: PurchaseForm,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/organizations/:id/edit",
    component: OrganizationForm,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/schedule-exceptions/:id/edit",
    component: ExceptionForm,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/appointments/new-with/:specialistId/:purchaseServiceId",
    component: AppointmentCreate,
    layout: "/setup",
    redirect: true
  },
  {
    path: "/appointments/with/:specialistId/:purchaseServiceId/:userId?",
    component: AppointmentCreate,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/appointments/reschedule/:previousAppointmentId",
    component: AppointmentCreate,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/appointments/:id/chat",
    component: Chat,
    layout: "/chat",
    redirect: true
  },
  {
    path: "/appointments/:id/edit",
    component: AppointmentForm,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/appointments/:id",
    component: AppointmentShow,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
    redirect: true
  },
  {
    path: "/register",
    name: "Signup",
    component: Register,
    layout: "/auth",
    redirect: true
  },
  {
    path: "/forgot-password",
    name: window.gettext("Esqueci a senha"),
    component: ForgotPassword,
    layout: "/auth",
    redirect: true
  },
  {
    path: "/reset-password/:token",
    name: window.gettext("Resetar a senha"),
    component: ResetPassword,
    layout: "/auth",
    redirect: true
  },
  {
    path: "/user-profile",
    name: window.gettext("Meu perfil"),
    component: UserProfile,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/change-password",
    name: window.gettext("Troca de senha"),
    component: ChangePasswordForm,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/change-company",
    name: window.gettext("Alterar Empresa"),
    component: ChangeCompany,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/upload-cv",
    name: "Upload CV",
    component: UploadCv,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/organizations/:id/usage",
    name: window.gettext("Uso da organização"),
    component: OrganizationUsage,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/organizations/:organization_id/person/:user_id",
    name: window.gettext("Detalhe do usuário"),
    component: UserUsage,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/ancora",
    name: window.gettext("Âncora de carreira"),
    component: Ancora,
    layout: "/admin",
    redirect: true
  },

  {
    path: "/performacegrou",
    name: window.gettext("Lumi"),
    component: Ancora,
    layout: "/admin",
    redirect: true
  },

  {
    path: "/activation/:activation_code",
    component: ActivationSetup,
    layout: "/setup",
    redirect: false
  },
];

export default routes;
