import Services from "./Services";
import Appointments from "./Appointments";

import {getUserData} from "../lib/Auth";

/**
 * Wizard helper
 *
 * Flow is: choose product -> checkout -> user data -> specialist -> dashboard
 */
class Wizard {
  beforeCheckout() {
    let user = getUserData();
    if (user.role == 'student' && !user.organization) {
      return true;
    }
    return '/admin/dashboard';
  }

  async afterCheckout() {
    return '/admin/dashboard';
  }

  async afterUserProfile() {
    return '/admin/dashboard';
  }
}

let singleton = new Wizard;
export default singleton;
