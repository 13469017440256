import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    Row,
    TabContent,
    Table,
    TabPane
} from "reactstrap";

import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import Services from "models/Services";
import Switch from "react-bootstrap-switch";

import apiRequest from "../../../lib/Api";
import InputError from "../../components/InputError";
import {NavLink} from "react-router-dom";
import Select from "react-select";
import uuid from "react-uuid";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import AsyncSelect from "react-select/lib/Async";
import withNotify from "../../../lib/NotificationWrapper";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import TranslationPanel from "../../components/TranslationPanel";

const PARAM_NAMES = {
    mailto: 'E-mail para',
    link: 'Link externo'
};

class ProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            name: '',
            price: '',
            description1: '',
            description2: '',
            description3: '',
            payment: '',
            next_step: '',
            installments: '',
            active: true,
            is_b2c: false,
            image: '',
            originalImage: '',
            working: false,
            services: [],
            productServices: [],
            enabledServices: [],
            currentForm: null,
            currentTrigger: null,
            currentFormTo: null,
            currentEventUuid: null,
            currentFormRequired: false,
            loadingServices: false,
            isModalOpen: false,
            errors: {},
            forms: [],
            serviceList: [],
            specialists: [],
            formsList: [],
            showDeleteSpecialistConfirmation: false,
            currentSpecialist: null,
            currentRemoveSpecialist: null,
            currentEventToDelete: null,
            showDeleteConfirmationEvent: false,
            currentService: {
                value: '',
                label: ''
            },
            currentProductService: null,
            horizontalTabs: 'produtos',
            serviceHorizontalTabs: 'service',
            currentCourse: {
                value: '',
                label: ''
            },
            optionsCourses: [],
        }
        this.eventsTrigger = [
            {value: 'before', label: 'Antes do serviço / Agendamento'},
            {value: 'after', label: 'Depois do serviço / Agendamento'},
        ];

        this.formTo = [
            {value: 'user', label: 'Usuário'},
            {value: 'specialist', label: 'Especialista'},
        ];
    }


    async componentDidMount() {
        if (this.id) {
            this.fetchItem();
        }
        this.loadServices()
            .then(async () => {
                if (this.id) {
                    await this.loadProductServices(this.id);
                    await this.loadSpecialists(this.id)
                }
            });

        this.getForms()

        const optionsCourses = [];
         await apiRequest('/courses', {method: 'GET'}).then(
           response => {
               response.data.data.map(course => {
                   if (course.is_active) {
                       optionsCourses.push({
                           label: course.name,
                           value: course.id,
                       })
                   }
               })
           }
         );
        this.setState({optionsCourses: optionsCourses, currentCourse: optionsCourses[0]});
    }

    get rolesAvailable() {

        return [
            {
                value: 'specialist',
                label: 'Especialista',
                isDisabled: false
            },
            {
                value: 'admin',
                label: 'Administrador de Empresa',
                isDisabled: false
            },
        ];
    }

    fetchItem() {
        return apiRequest("/products/" + this.id, {method: "GET"})
            .then(response => {
                let {data} = response.data;
                data.originalImage = data.image;
                this.setState(data);
                return data;
            });
    }

    async getForms() {
        let response = await apiRequest("/forms", {method: "GET"})

        let {data} = response.data;
        let forms = [];
        let formsList = [];

        for (let form of data) {
            forms.push(
                {
                    value: form.id,
                    label: form.name
                }
            )
            formsList.push(form);
        }

        this.setState({'forms': forms, formsList: formsList});

    }

    get id() {
        if (this.props.match.params && this.props.match.params.id) {
            return this.props.match.params.id;
        }
        if (this.state.id !== null) {
            return this.state.id
        }
        return null;
    }

    get formTitle() {
        if (this.id) {
            return window.gettext('Alteração de produtos');
        }
        return window.gettext('Cadastro de produtos');
    }

    clearForm = () => {
        this.setState({
            name: '',
            price: '',
            description1: '',
            description2: '',
            description3: '',
            payment: '',
            next_step: '',
            installments: '',
            is_b2c: false,
            image: '',
            originalImage: '',
            specialists: [],
            enabledServices: [],
            productServices: [],
            currentService: null,
            currentProductService: null,
        });
    }

    async loadServices() {
        let response = await apiRequest("/services", {method: 'GET'})
        if (response) {
            this.setState({services: response.data.data})
            this.state.serviceList = response.data.data.map(service => {
                return {
                    label: service.displayName,
                    value: service.id,
                    ...service
                }
            })
        }
    }

    async loadSpecialists(productId) {
        return await apiRequest(`/products/${productId}/specialists`, {method: 'GET'})
            .then((response) => {
                let {data} = response.data;
                this.setState({specialists: data});
            });
    }

    loadProductServices(productId) {
        let data = {
            product_id: productId
        };
        return apiRequest("/product-services", {data, method: 'GET'})
            .then((response) => {
                let {data} = response.data;
                let serviceIds = data.map(ps => ps.service.id);
                this.setState({enabledServices: serviceIds, productServices: data});
            });
    }

    hasService(serviceId) {
        return this.state.enabledServices.indexOf(serviceId) >= 0;
    }

    findProductService(serviceId) {
        return this.state.productServices.find(productService => productService.id === serviceId);
    }

    handleChange = (e) => {
        let {name, value} = e.target;

        if (name === 'is_b2c') {
            value = !this.state.is_b2c;
        }

        this.setState({
            [name]: value
        });

        if (this.state.errors[name]) {
            this.setState({errors: {...this.state.errors, [name]: ''}});
        }
    }

    handleServiceChange = (field, service) => {

        if (!this.state.currentProductService && !this.findProductService(service.id)) {
            const uniqueId = uuid();
            let productService = {
                id: uniqueId,
                uuid: uniqueId,
                service: {...service},
                params: {},
                dependencies: [],
                specialists: [],
            };
            this.setState({currentProductService: productService});
        }
        this.setState({currentService: service})
    }

    handleServiceParamChange = (productService, e) => {
        let {name, value} = e.target;
        if (name === 'enable_guest') {
            value = e.target.checked;
        }
        if (name === 'quota' || name === 'name' || name === 'description') {
            productService[name] = value;
        } else {
            productService.params[name] = value;
        }

        this.setState({currentProductService: productService});
    }

    handleFormChange = (field, data) => {
        this.setState({currentForm: data});
    }

    handleTriggerChange = (data) => {
        this.setState({currentTrigger: data});
    }

    handleFormToChange = (data, state) => {
        this.setState({[state]: data});
    }

    handleSwitchRequired = (e) => {
        this.setState({
            currentFormRequired: !this.state.currentFormRequired,

        });
    }

    renderEventRow = (event, i) => {
         return (
             <tr key={event.uuid}>
                 <td>{i}</td>
                 <td>{event.form.name}</td>
                 <td>{event.trigger ===  'after' ? window.gettext("Após serviço") : window.gettext('Antes Serviço')}</td>
                 <td>{event.to ===  'user' ? window.gettext("Usuário") : window.gettext('Especialista')}</td>
                 <td>{event.required  ? window.gettext("Sim") : window.gettext('Não')}</td>
                 <td>{this.renderCanSeeEvent(event.settings.roles_can_see || [])}</td>
                 <td> <Button
                     className="btn-icon btn-neutral"
                     color="info"
                     size="sm"
                     type="button"
                     onClick={() => this.editEvent(event)}
                 >
                     <i className="fa fa-edit"/>
                 </Button>{" "}
                     <Button
                         className="btn-icon btn-neutral"
                         color="danger"
                         size="sm"
                         type="button"
                         onClick={() => this.maybeDeleteEvent(event)}
                     >
                         <i className="fa fa-times"/>
                     </Button>{" "}</td>
             </tr>
         )
    }
    renderCanSeeEvent(roles) {

        let rolesString = '';
        let rolesTranslated = roles.map(role => {
             return role ===  'specialist' ? 'Especialista': 'Admin'
        }) ;
        return rolesTranslated.join(", ")
    }

    saveProductService = () => {
        let currentProductService = this.state.currentProductService;

        if (this.state.currentService.name === Services.consts.ONLINE_COURSE) {
            if (!this.state.currentCourse || !this.state.currentCourse.value) {
                this.props.notify({type: 'error', message: window.gettext('Selecione um curso.')});
                return false;
            }
            currentProductService.course_id = this.state.currentCourse.value;
        }

        let savedService = this.findProductService(this.state.currentProductService.id)
        let productServices = [...this.state.productServices];
        if (!savedService) {
            productServices.push(currentProductService)
        }

        const event = new Event('submit');
        this.setState(
          {
            currentService: null,
            productServices: productServices,
            currentCourse: null ,
            currentProductService: null,
            isModalOpen: false
          },
          () => { this.handleSubmit(event) }
        );
        this.state.isModalOpen = false
    }

    editEvent = (event) => {
        const productService = this.state.currentProductService;
        let editEvent = productService.events.find(eventService => eventService.uuid === event.uuid);
        let roles = editEvent.settings.roles_can_see || [];
        let rolesSelected = roles.map(role => {
            return this.rolesAvailable.find(roleMapped => roleMapped.value === role)
        })

        this.setState({
            currentProductService: productService,
            currentFormTo: this.formTo.find(to => to.value === editEvent.to),
            currentForm: this.state.forms.find(to => to.value === editEvent.form.id),
            currentTrigger: this.eventsTrigger.find(to => to.value === editEvent.trigger),
            currentFormRequired: editEvent.required,
            currentEventUuid: editEvent.uuid,
            user_roles: rolesSelected
        })
    }
    addEvent = () => {
        const productService = this.state.currentProductService;
        let form = this.state.currentForm;
        let trigger = this.state.currentTrigger;
        let to = this.state.currentFormTo;
        let isRequired = this.state.currentFormRequired;
        let userRoles = this.state.user_roles.map(roles => roles.value);
        if (form === null) {
            this.props.notify({type: 'error', message: window.gettext('O formulário deve ser informado')});
            return;
        }
        if (trigger === null) {
            this.props.notify({type: 'error', message: window.gettext('informe o evento em que o formulário deve ser solicitado.')});
            return;
        }
        if (to === null) {
            this.props.notify({type: 'error', message: window.gettext('informe para quem o formulário deve ser solicitado.')});
            return;
        }
        let formData = this.state.formsList.find(el => el.id === form.value);
        let newEvent = {
            form: formData,
            uuid: uuid(),
            trigger: trigger.value,
            to: to.value,
            required: isRequired,
            settings: {'roles_can_see': userRoles}
        }
        if (productService.events === undefined) {
            productService.events = [];
        }
        let eventList = productService.events;
        if (this.state.currentEventUuid != null) {
            eventList =  eventList.filter(event => event.uuid !== this.state.currentEventUuid)
        }
        eventList.push(newEvent);
        productService.events = eventList;
        this.setState({
            currentProductService: productService,
            currentForm: null,
            currentTrigger: null,
            currentFormRequired: null,
            currentFormTo: null,
            currentEventUuid: null,
            user_roles: [],
        })
    }

    renderProductServices() {
        let productsServices = this.state.productServices;
        return (
            <>
                {productsServices.map((item, i) => {
                    return (
                        <>
                            <tr key={item.id}>
                                <td>{i + 1}</td>
                                <td>{item.name || item.service.displayName}</td>
                                <td>{item.service.displayName}</td>
                                <td>
                                    <Button
                                        disabled={i === 0}
                                        className="btn-icon btn-neutral"
                                        color="secondary"
                                        size="sm"
                                        type="button"
                                        onClick={() => this.reorderService(i, i - 1)}
                                    >
                                        <i className="fa fa-chevron-up"/>
                                    </Button>{" "}
                                    <Button
                                        disabled={i === productsServices.length - 1}
                                        className="btn-icon btn-neutral"
                                        color="secondary"
                                        size="sm"
                                        type="button"
                                        onClick={() => this.reorderService(i, i + 1)}
                                    >
                                        <i className="fa fa-chevron-down"/>
                                    </Button>{" "}
                                </td>
                                <td className="text-right">

                                    <Button
                                        className="btn-icon btn-neutral"
                                        color="success"
                                        size="sm"
                                        onClick={() => this.onEditProductService(item)}
                                    >
                                        <i className="fa fa-edit"/>
                                    </Button>{" "}

                                    <Button
                                        className="btn-icon btn-neutral"
                                        color="danger"
                                        size="sm"
                                        type="button"
                                        onClick={() => this.maybeDeleteService(item)}
                                    >
                                        <i className="fa fa-times"/>
                                    </Button>{" "}
                                </td>
                            </tr>
                        </>
                    )
                })
                }
                <ConfirmModal message={window.gettext("Tem certeza que deseja remover este serviço?")}
                              onConfirm={() => this.onDeleteProductService()}
                              onToggle={() => this.toggleConfirmation(false)}
                              open={this.state.showDeleteConfirmation}
                />

            </>
        )
    }

    onEditProductService = (item) => {
        let service = this.state.serviceList.find(service => service.id === item.service.id);
        let course = this.state.optionsCourses.find(optionCourse => optionCourse.value === item.course_id);
        this.setState({
            currentService: service,
            currentProductService: item,
            currentCourse: course,
            isModalOpen: true
        })

    }

    toggleConfirmation = (show) => {
        this.setState({showDeleteConfirmation: show});
    }

    toggleConfirmationEvent = (show) => {
        this.setState({showDeleteConfirmationEvent: show});
    }

    maybeDeleteService = (item) => {
        this.setState({currentProductService: item});
        this.toggleConfirmation(true);
    }

    maybeDeleteEvent = (item) => {
        this.setState({currentEventToDelete: item});
        this.toggleConfirmationEvent(true);
    }

    onDeleteProductService = () => {

        let item = this.state.currentProductService;
        let productServices = [...this.state.productServices];
        for (let i = 0; i < productServices.length; i++) {
            if (item.id === productServices[i].id) {
                productServices.splice(i, 1)
            }
        }
        this.setState({productServices: productServices, currentProductService: null})
    }

    onDeleteProductServiceEvent = () => {

        let item = this.state.currentEventToDelete;
        let currentService = this.state.currentProductService;
        const productServices = this.state.productServices;
        let events = currentService.events;

        const continueForms = [];
        events.map(event => {
            if (item.uuid && event.uuid) {
                if (item.uuid !== event.uuid) {
                    continueForms.push(event);
                }
            }
        });

        currentService.events = continueForms;
        this.setState({productServices: productServices, currentEventToDelete: null})
    }

    handleChangeImage = file => {
        this.setState({image: file});
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let fields = ['name', 'price', 'description1', 'description2', 'description3',
            'next_step', 'payment', 'installments', 'is_b2c', 'active'];

        let data = new FormData()
        for (const field of fields) {
            let value = this.state[field];
            if (field === 'is_b2c' || field === 'active') {
                value = value ? 1 : 0;
            } else if (!value) {
                continue;
            }
            data.append(field, value);
        }

        if (this.state.originalImage !== this.state.image) {
            data.append('image', this.state.image);
        }

        let productServices = this.state.productServices.map((ps, i) => {
            let {service: {id: service_id}, quota, name, description, params, course_id, dependencies, id, uuid, events} = ps;
            if (events) {
                events = events.map(event => {
                    return {
                        form: event.form.id,
                        trigger: event.trigger,
                        required: event.required,
                        show_to: event.to,
                        uuid: event.uuid,
                        settings: event.settings,
                    }
                })
            }
            return {service_id, name, description, quota, params, dependencies, id, uuid, events, course_id, order: i + 1};
        });
        data.append('productServices', JSON.stringify(productServices));
        data.append('specialists', JSON.stringify(this.state.specialists));
        this.setState({working: true});

        let request;
        if (this.id) {
            request = apiRequest("/products/" + this.id, {data, method: 'PUT'})
        } else {
            request = apiRequest("/products", {data, method: 'POST'});
        }

        request.then(response => {
            this.props.notify({type: 'success', message: window.gettext('Produto armazenado com sucesso')});

            this.setState({id: response.data.data.id})
        })
            .catch(err => {
                this.props.notify({type: 'error', message: window.gettext('Verifique os campos em vermelho.')});
                this.setState({errors: err.data.errors});
            })
            .finally(
                response => this.setState({working: false})
            );
    }

    openNewService = () => {
        this.setState({isModalOpen: true, currentService: null, currentProductService: null})
    }

    closeModal = () => {
        this.setState({isModalOpen: false, currentService: null, currentProductService: null})
    }

    renderFormEvents() {
        if (!this.state.currentProductService) {
            return (<></>)
        }
        let events = this.state.currentProductService.events || [];
        return (
            <>
                <Table responsive>
                    <thead className="text-primary">
                    <tr>
                        <th>
                            #
                        </th>
                        <th>
                            {window.gettext('Formulário')}
                        </th>
                        <th>
                            {window.gettext('Quando')}
                        </th>
                        <th>
                            {window.gettext('Quem Recebe')}
                        </th>
                        <th>
                            {window.gettext('Obrigátorio')}
                        </th>
                        <th>
                            {window.gettext('Quem visualiza')}
                        </th>
                        <th className="text-right">
                            {window.gettext('Ações')}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {events.map((event, i) => {
                          return this.renderEventRow(event, i + 1);
                        })}
                    </tbody>
                </Table>
            </>
        );
    }

    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);

        return (
            <>
                <div className="content product-form-container">
                    <Row>
                        <Col md="12">
                            <Form action="" className="form" method="POST"
                                  onSubmit={this.handleSubmit}
                            >
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{this.formTitle}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        { /*TABS */}
                                        <div className="nav-tabs-navigation">
                                            <div className="nav-tabs-wrapper">
                                                <Nav id="tabs" role="tablist" tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-expanded={this.state.horizontalTabs === "produtos"}
                                                            role="tab"
                                                            to='#'
                                                            className={
                                                                this.state.horizontalTabs === "produtos"
                                                                    ? "active nav-link"
                                                                    : "nav-link"
                                                            }
                                                            onClick={() =>
                                                                this.setState({horizontalTabs: "produtos"})
                                                            }
                                                        >
                                                            <b>{window.gettext('Produto')}</b>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-expanded={this.state.horizontalTabs === "servicos"}
                                                            role="tab"
                                                            to='#'
                                                            className={
                                                                this.state.horizontalTabs === "servicos"
                                                                    ? "active nav-link"
                                                                    : "nav-link"
                                                            }
                                                            onClick={() =>
                                                                this.setState({horizontalTabs: "servicos"})
                                                            }
                                                        >
                                                            <b>
                                                                {window.gettext('Serviços')}
                                                            </b>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-expanded={this.state.horizontalTabs === "dependencias"}
                                                            role="tab"
                                                            to='#'
                                                            className={
                                                                this.state.horizontalTabs === "dependencias"
                                                                    ? "active nav-link"
                                                                    : "nav-link"
                                                            }
                                                            onClick={() =>
                                                                this.setState({horizontalTabs: "dependencias"})
                                                            }
                                                        >
                                                            <b>
                                                                {window.gettext('Configurar pre-requisitos')}</b>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-expanded={this.state.horizontalTabs === "profissionais"}
                                                            role="tab"
                                                            to='#'
                                                            className={
                                                                this.state.horizontalTabs === "profissionais"
                                                                    ? "active nav-link"
                                                                    : "nav-link"
                                                            }
                                                            onClick={() =>
                                                                this.setState({horizontalTabs: "profissionais"})
                                                            }
                                                        >
                                                            <b>
                                                                {window.gettext('Especialistas')}</b>
                                                        </NavLink>
                                                    </NavItem>

                                                </Nav>
                                            </div>
                                        </div>
                                        <TabContent
                                            id="my-tab-content"
                                            activeTab={this.state.horizontalTabs}
                                        >
                                            { /*TAB PRODUTO*/}
                                            <TabPane tabId="produtos" role="tabpanel">
                                                <Row>
                                                    <Label sm="2">
                                                        {window.gettext('Nome')}
                                                    </Label>
                                                    <Col sm="10">
                                                        <FormGroup className={hasError.name}>
                                                            <Input type="text" name="name" value={this.state.name}
                                                                   onChange={this.handleChange}
                                                            />
                                                            <InputError name="name" errors={this.state.errors}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Label sm="2">
                                                        {window.gettext('Tipo de produto')}
                                                    </Label>
                                                    <Col sm="10">
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input type="checkbox" checked={this.state.is_b2c}
                                                                       onChange={this.handleChange} name='is_b2c'
                                                                />
                                                                <span className="form-check-sign"/>
                                                                {window.gettext('É um produto B2C')}
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Label sm="2">
                                                        {window.gettext('Descrição 1')}
                                                    </Label>
                                                    <Col sm="10">
                                                        <FormGroup className={hasError.description1}>
                                                            <Input type="textarea" name="description1"
                                                                   value={this.state.description1 || ''}
                                                                   onChange={this.handleChange}
                                                            />
                                                            <InputError name="description1" errors={this.state.errors}/>
                                                            <FormText color="default" tag="span">
                                                                {window.gettext('Texto abaixo do título do produto')}
                                                            </FormText>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Label sm="2">
                                                        {window.gettext('Descrição 2')}
                                                    </Label>
                                                    <Col sm="10">
                                                        <FormGroup className={hasError.description2}>
                                                            <Input type="textarea" name="description2"
                                                                   value={this.state.description2 || ''}
                                                                   onChange={this.handleChange}
                                                            />
                                                            <InputError name="description2" errors={this.state.errors}/>
                                                            <FormText color="default" tag="span">
                                                                {window.gettext('Bullet points - para cada nova linha, um novo bullet point será gerado.')}
                                                            </FormText>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Label sm="2">
                                                        {window.gettext('Descrição 3')}
                                                    </Label>
                                                    <Col sm="10">
                                                        <FormGroup className={hasError.description3}>
                                                            <Input type="textarea" name="description3"
                                                                   value={this.state.description3 || ''}
                                                                   onChange={this.handleChange}
                                                            />
                                                            <InputError name="description3" errors={this.state.errors}/>
                                                            <FormText color="default" tag="span">
                                                                {window.gettext('Texto de <em>Opinião de quem já comprou</em>')}
                                                            </FormText>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Label sm="2">
                                                        {window.gettext('Preço da parcela')}</Label>
                                                    <Col sm="10">
                                                        <FormGroup className={hasError.price}>
                                                            <Input type="number" name="price" step="0.01"
                                                                   value={this.state.price}
                                                                   onChange={this.handleChange}
                                                            />
                                                            <InputError name="price" errors={this.state.errors}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Label sm="2">
                                                        {window.gettext('Número de parcelas')}
                                                    </Label>
                                                    <Col sm="10">
                                                        <FormGroup className={hasError.installments}>
                                                            <Input type="number" name="installments" step="1"
                                                                   value={this.state.installments || ''}
                                                                   onChange={this.handleChange}
                                                            />
                                                            <InputError name="installments" errors={this.state.errors}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Label sm="2">
                                                        {window.gettext('Condições de pagamento')}
                                                    </Label>
                                                    <Col sm="10">
                                                        <FormGroup className={hasError.payment}>
                                                            <Input type="textarea" name="payment"
                                                                   value={this.state.payment || ''}
                                                                   onChange={this.handleChange}
                                                            />
                                                            <InputError name="payment" errors={this.state.errors}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Label sm="2">
                                                        {window.gettext('Próximo passo')}
                                                    </Label>
                                                    <Col sm="10">
                                                        <FormGroup className={hasError.next_step}>
                                                            <Input type="textarea" name="next_step"
                                                                   value={this.state.next_step || ''}
                                                                   onChange={this.handleChange}
                                                            />
                                                            <InputError name="next_step" errors={this.state.errors}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Label sm="2">
                                                        {window.gettext('Produto ativo')}
                                                        {this.state.active ? 'Sim' : 'Não'}
                                                    </Label>
                                                    <Col sm={10}>
                                                        <BootstrapSwitchButton
                                                            checked={this.state.active}
                                                            onlabel='Sim'
                                                            offlabel='Não'
                                                            size="sm"
                                                            style="border"
                                                            onstyle="outline-success" offstyle="outline-danger"
                                                            style='w-25'
                                                            onChange={(checked) => {

                                                                this.setState({active: checked})
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Label sm="2">
                                                        {window.gettext('Imagem')}
                                                    </Label>
                                                    <Col md="4" sm="4">
                                                        <ImageUpload onChangeImage={this.handleChangeImage}
                                                                     originalUrl={this.state.originalImage}
                                                        />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            { /*TAB SERVICOS */}
                                            <TabPane tabId="servicos" role="tabpanel">
                                                <Row>
                                                    <Col md={12} style={{textAlign: 'right'}}>
                                                        <Button className="btn-icon" color="secundary" type="button"
                                                                disabled={this.state.working}
                                                                onClick={this.openNewService}
                                                        >
                                                            <i className="fa fa-plus"/>
                                                        </Button>
                                                    </Col>
                                                    <Col sm={1}/>
                                                    <Col sm={11}>
                                                        <Table responsive>
                                                            <thead className="text-primary">
                                                            <tr>
                                                                <th>
                                                                    #
                                                                </th>
                                                                <th>
                                                                    {window.gettext('Serviço')}
                                                                </th>
                                                                <th>
                                                                    {window.gettext('Tipo')}
                                                                </th>
                                                                <th>
                                                                    {window.gettext('Ordem')}
                                                                </th>
                                                                <th className="text-right">
                                                                    {window.gettext('Ações')}
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.renderProductServices()}
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                                <Modal isOpen={this.state.isModalOpen} size="xl">
                                                    <ModalHeader style={{position: 'sticky'}}>
                                                        {window.gettext('Adicionar Serviços')}</ModalHeader>
                                                    <ModalBody>
                                                        <div className="nav-tabs-navigation">
                                                            <div className="nav-tabs-wrapper">
                                                                <Nav id="tabs" role="tablist" tabs>
                                                                    <NavItem>
                                                                        <NavLink
                                                                            aria-expanded={this.state.serviceHorizontalTabs === "service"}
                                                                            role="tab"
                                                                            to='#'
                                                                            className={
                                                                                this.state.serviceHorizontalTabs === "service"
                                                                                    ? "active nav-link"
                                                                                    : "nav-link"
                                                                            }
                                                                            onClick={() =>
                                                                                this.setState({serviceHorizontalTabs: "service"})
                                                                            }
                                                                        >
                                                                            <b>{window.gettext('Dados do Serviço')}</b>
                                                                        </NavLink>
                                                                    </NavItem>
                                                                    <NavItem>
                                                                        <NavLink
                                                                            aria-expanded={this.state.serviceHorizontalTabs === "events"}
                                                                            role="tab"
                                                                            to='#'
                                                                            className={
                                                                                this.state.serviceHorizontalTabs === "events"
                                                                                    ? "active nav-link"
                                                                                    : "nav-link"
                                                                            }
                                                                            onClick={() =>
                                                                                this.setState({serviceHorizontalTabs: "events"})
                                                                            }
                                                                        >
                                                                            <b>
                                                                                {window.gettext('Eventos')}
                                                                            </b>
                                                                        </NavLink>
                                                                    </NavItem>
                                                                </Nav>
                                                            </div>
                                                        </div>
                                                        <TabContent
                                                            id="my-tab-content"
                                                            activeTab={this.state.serviceHorizontalTabs}
                                                        >
                                                            <TabPane tabId="service" role="tabpanel">
                                                                <Row>
                                                                    <Label sm="3">
                                                                        {window.gettext('Serviço')}
                                                                    </Label>
                                                                    <Col sm="9">
                                                                        <FormGroup className={hasError.user_id}>
                                                                            <Select
                                                                                className="react-select primary"
                                                                                classNamePrefix="react-select"
                                                                                name="currentService"
                                                                                value={this.state.currentService}
                                                                                onChange={value =>
                                                                                    this.handleServiceChange("currentService", value)
                                                                                }
                                                                                options={this.state.serviceList}
                                                                                placeholder={window.gettext("Selecione um serviço")}
                                                                            />
                                                                            <InputError name="user_id"
                                                                                        errors={this.state.errors}/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                {this.state.currentService && this.renderServiceParams(this.state.currentService)}
                                                            </TabPane>
                                                            <TabPane tabId="events" role="tabpanel">
                                                                <Row>
                                                                    <Label sm="3">
                                                                        {window.gettext('Formulário')}
                                                                    </Label>
                                                                    <Col sm="9">
                                                                        <FormGroup className={hasError.user_id}>
                                                                            <Select
                                                                                className="react-select primary"
                                                                                classNamePrefix="react-select"
                                                                                name="currentService"
                                                                                value={this.state.currentForm}
                                                                                onChange={value =>
                                                                                    this.handleFormChange("currentForm", value)
                                                                                }
                                                                                options={this.state.forms}
                                                                                placeholder={window.gettext("Selecione um formulário")}
                                                                            />
                                                                            <InputError name="form_id"
                                                                                        errors={this.state.errors}/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Label sm="3">
                                                                        {window.gettext('Disparar')}
                                                                    </Label>
                                                                    <Col sm="9">
                                                                        <FormGroup className={hasError.user_id}>
                                                                            <Select
                                                                                className="react-select primary"
                                                                                classNamePrefix="react-select"
                                                                                name="currentTrigger"
                                                                                value={this.state.currentTrigger}
                                                                                onChange={this.handleTriggerChange}
                                                                                options={this.eventsTrigger}
                                                                                placeholder={window.gettext("Selecione um gatilho")}
                                                                            />
                                                                            <InputError name="trigger_id"
                                                                                        errors={this.state.errors}/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Label sm="3">
                                                                        {window.gettext('Enviar para')}
                                                                    </Label>
                                                                    <Col sm="9">
                                                                        <FormGroup className={hasError.user_id}>
                                                                            <Select
                                                                                className="react-select primary"
                                                                                classNamePrefix="react-select"
                                                                                name="currentTrigger"
                                                                                value={this.state.currentFormTo}
                                                                                onChange={(value) => this.handleFormToChange(value, 'currentFormTo')}
                                                                                options={this.formTo}
                                                                                placeholder={window.gettext("Mostrar para")}
                                                                            />
                                                                            <InputError name="trigger_id"
                                                                                        errors={this.state.errors}/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={3}>
                                                                        <Label>{window.gettext('Obrigatório')}</Label>
                                                                    </Col>
                                                                    <Col sm={9}>
                                                                        <Switch
                                                                            value={this.state.currentFormRequired}
                                                                            onChange={this.handleSwitchRequired}
                                                                            offColor="info"
                                                                            offText='Não'
                                                                            onColor="success"
                                                                            onText='Sim'
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={3}>
                                                                        <Label>{window.gettext('Quem pode visualizar as respostas?')}</Label>
                                                                    </Col>
                                                                    <Col sm={9}>
                                                                        <FormGroup className={hasError.user_roles}>
                                                                            <Select
                                                                                isMulti={true}
                                                                                className="react-select primary"
                                                                                classNamePrefix="react-select"
                                                                                name="user_roles"
                                                                                value={this.state.user_roles}
                                                                                onChange={(value) => this.handleFormToChange(value, 'user_roles')}
                                                                                options={this.rolesAvailable}
                                                                                placeholder={window.gettext("Mostrar para")}
                                                                            />
                                                                            <InputError name="user_roles"
                                                                                        errors={this.state.errors}/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col style={{textAlign: 'right'}}>
                                                                        <Button color={'primary'}
                                                                                onClick={this.addEvent}>
                                                                            <i className="fa fa-plus"/>
                                                                            {this.state.currentEventUuid === null ?
                                                                                window.gettext('Adicionar')
                                                                            :  window.gettext('Alterar')}
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                    {this.renderFormEvents()}
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                        </TabContent>

                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color={'primary'} onClick={this.saveProductService}>
                                                            {window.gettext('Salvar')}
                                                        </Button>
                                                        <Button color={'error'} onClick={this.closeModal}>
                                                            {window.gettext('Fechar')}
                                                        </Button>
                                                        &nbsp;
                                                    </ModalFooter>
                                                </Modal>
                                            </TabPane>
                                            { /*TAB DEPENDENCIAS */}
                                            <TabPane tabId="dependencias" role="tabpanel">
                                                <Row>
                                                    {this.renderDependencies()}
                                                </Row>
                                            </TabPane>
                                            { /*TAB especialistas */}
                                            <TabPane tabId="profissionais" role="tabpanel">
                                                {this.renderSpecialists()}
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                    <CardFooter>
                                        <Row>
                                            <Col md="2"/>
                                            <Col md="10">
                                                <Button color="primary" type="submit"
                                                        disabled={this.state.working}
                                                >
                                                    <i className="fa fa-save"/>
                                                    {window.gettext('Salvar')}
                                                </Button> {" "}
                                                <Button color="sec" type="button"
                                                        disabled={this.state.working}
                                                        onClick={this.clearForm}
                                                >

                                                    <i className="fa fa-file"/>
                                                    {window.gettext('Novo')}
                                                </Button>
                                                {' '}
                                                {this.id && <TranslationPanel type={'product'} code={this.id}/>}
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
                <ConfirmModal message={window.gettext("Tem certeza que deseja remover este evento?")}
                              onConfirm={() => this.onDeleteProductServiceEvent()}
                              onToggle={() => this.toggleConfirmationEvent(false)}
                              open={this.state.showDeleteConfirmationEvent}
                />
            </>
        );
    }

    /* metodos para a aba de Especialista */
    loadUsers = searchTerm => {
        let params = {
            searchRole: 'specialist',
            search: searchTerm
        };
        return apiRequest("/users", {data: params, method: 'GET'})
            .then(response => {
                return response.data.data.map(user => ({value: user.id, label: user.name}));
            });
    }

    renderSpecialists() {
        return (
            <>
                <Row>
                    <Label sm="2">
                        {window.gettext('Especialista')}
                    </Label>
                    <Col sm="8">
                        <FormGroup>
                            <AsyncSelect
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="specialist_id"
                                value={this.state.currentSpecialist}
                                onChange={value =>
                                    this.handleSpecialistChange(value)
                                }
                                defaultOptions
                                loadOptions={this.loadUsers}
                                placeholder={window.gettext("Selecione um usuário")}
                            />
                            <InputError name="user_id" errors={this.state.errors}/>
                        </FormGroup>
                    </Col>
                    <Col sm={2}>
                        <Button className="btn-icon" color="secundary" type="button"
                                disabled={this.state.working}
                                onClick={this.onAddNewSpecialist}
                        >
                            <i className="fa fa-plus"/>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={2}/>
                    <Col sm={10}>
                        <Table responsive>
                            <thead className="text-primary">
                            <tr>
                                <th>
                                    {window.gettext('Nome')}
                                </th>
                                <th className="text-right">
                                    {window.gettext('Ações')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderSpecialistList()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <ConfirmModal message={window.gettext("Tem certeza que deseja remover este Especialista?")}
                              onConfirm={() => this.onRemoveSpecialist()}
                              onToggle={() => this.toggleSpecialistConfirmation(false)}
                              open={this.state.showDeleteSpecialistConfirmation}
                />
            </>
        )
    }

    handleSpecialistChange = (e) => {
        this.setState({currentSpecialist: e})
    }

    onAddNewSpecialist = () => {
        const currentSpecialist = this.state.currentSpecialist;
        if (currentSpecialist === null) {
            return;
        }
        let hasSpecialist = this.state.specialists.find(specialist => specialist.id === currentSpecialist.value)
        if (hasSpecialist) {
            this.props.notify({type: 'error', message: window.gettext('Especialista já informado.')});
            return;
        }

        let specialist = {
            id: currentSpecialist.value,
            name: currentSpecialist.label,
        }
        this.state.specialists.push(specialist)
        this.setState({specialists: this.state.specialists, currentSpecialist: null})
    }

    maybeDeleteSpecialistService = (specialist) => {
        this.setState({currentRemoveSpecialist: specialist});
        this.toggleSpecialistConfirmation(true);
    }

    toggleSpecialistConfirmation = (show) => {
        this.setState({showDeleteSpecialistConfirmation: show});
    }

    onRemoveSpecialist = () => {
        let item = this.state.currentRemoveSpecialist;
        let specialists = [...this.state.specialists];
        for (let i = 0; i < specialists.length; i++) {
            if (item.id === specialists[i].id) {
                specialists.splice(i, 1)
            }
        }
        this.setState({specialists: specialists, currentRemoveSpecialist: null})
    }

    renderSpecialistList() {
        const specialists = this.state.specialists
        return (
            <>
                {specialists.map((item, i) => {
                    return (
                        <>
                            <tr key={item.id}>
                                <td>{item.name}</td>
                                <td>
                                    <Button
                                        className="btn-icon btn-neutral"
                                        color="danger"
                                        size="sm"
                                        type="button"
                                        onClick={() => this.maybeDeleteSpecialistService(item)}
                                    >
                                        <i className="fa fa-times"/>
                                    </Button>{" "}

                                </td>
                            </tr>
                        </>
                    )
                })
                }
            </>
        )
    }

    /* servicos */
    renderServiceParams(service) {
        if (!service) {
            return;
        }
        let productService = this.state.currentProductService
        if (!productService) {
            return
        }


        let {name, description, params} = productService;

        return (
            <div className="service-params-container">

                {service.name === Services.consts.SPECIALIST_CALL && this.renderSpecialistCallParams(productService)}

                {service.name !== Services.consts.SPECIALIST_CALL && this.renderOtherParams(productService)}

                { this.renderOnlineCourses(service) }
                <Row>
                    <Label sm="3">
                        {window.gettext('Título do serviço')}
                    </Label>
                    <Col sm="9">
                        <FormGroup>
                            <Input type="text" name="name" value={name || ''}
                                   onChange={e => this.handleServiceParamChange(productService, e)}
                            />
                            <FormText color="default" tag="span">
                                {window.gettext('Se este campo for vazio, será mostrado o título do produto.')}
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Label sm="3">
                        {window.gettext('Descrição do serviço')}
                    </Label>
                    <Col sm="9">
                        <FormGroup>
                            <Input type="textarea" name="description" value={description || ''}
                                   onChange={e => this.handleServiceParamChange(productService, e)}
                            />
                            <FormText color="default" tag="span">
                                {window.gettext('Se este campo for vazio, será mostrada a descrição do produto.')}
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }

    renderSpecialistCallParams(productService) {
        let {quota = '', params = {}} = productService || {};
        let {duration = '', enable_guest = false} = params || {};
        return (
            <React.Fragment>
                <Row>
                    <Label sm="3">
                        {window.gettext('Número de sessões')}
                    </Label>
                    <Col sm="4">
                        <FormGroup>
                            <Input type="number" name="quota" value={quota}
                                   onChange={e => this.handleServiceParamChange(productService, e)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Label sm="3">
                        {window.gettext('Duração da sessão')}
                    </Label>
                    <Col sm="4">
                        <FormGroup>
                            <Input type="number" name="duration" value={duration}
                                   onChange={e => this.handleServiceParamChange(productService, e)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}>
                        <Label>
                            {window.gettext('Convite:')}
                        </Label>
                    </Col>
                    <Col sm="9">
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" name="enable_guest" checked={enable_guest}
                                       onChange={e => this.handleServiceParamChange(productService, e)}
                                />
                                <span className="form-check-sign"/>
                                {window.gettext('Obrigar convite para convidados')}
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    reorderService = (from, to) => {

        let services = [...this.state.productServices];
        const currentService = services.splice(from, 1);
        services.splice(to, 0, currentService[0]);
        this.setState({productServices: services});

    }

    renderOtherParams(productService) {
        if (!productService.params) {
            return '';
        }

        return Object.entries(productService.params).map(([paramKey, paramValue]) => (
            <React.Fragment key={paramKey}>
                <Row key={paramKey}>
                    <Label sm="3">{PARAM_NAMES[paramKey] || paramKey}</Label>
                    <Col sm="4">
                        <FormGroup>
                            <Input type="text" name={paramKey} value={paramValue}
                                   onChange={e => this.handleServiceParamChange(productService, e)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </React.Fragment>
        ));
    }

    renderOnlineCourses(service) {
        if (service.name !== Services.consts.ONLINE_COURSE) {
            return <></>;
        }

        let number = Math.floor(Math.random());

        return <>
            <Row key={number}>
                <Label sm="3">
                    {window.gettext('Curso')}
                </Label>
                <Col sm="9">
                    <FormGroup>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="currentService"
                            value={this.state.currentCourse}
                            onChange={value => {
                                this.setState({currentCourse: value})
                            }}
                            options={this.state.optionsCourses}
                            placeholder={window.gettext("Selecione um curso")}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>;
    }

    renderDependenceCheckBox(productService, dependence) {

        if (productService.id === dependence.id) {
            return (
                <div style={{height: '100%', width: '100%', backgroundColor: 'grey'}}>&nbsp;</div>
            )
        }
        return (
            <FormGroup check>
                <Label check>
                    <input type='checkbox' id={productService.id + "_" + dependence.id} onChange={(e) => {
                        this.handleCheckoutDepencyClick(productService, dependence, e.target.checked)

                    }}
                           checked={productService.dependencies.some(dependency => dependency === dependence.uuid)}
                    />
                    <span className="form-check-sign"/>
                </Label>
            </FormGroup>
        )
    }

    handleCheckoutDepencyClick = (productService, dependency, checked) => {
        let productServices = this.state.productServices;

        if (!productService.dependencies) {
            productService.dependencies = [];
        }
        if (checked) {
            productService.dependencies.push(dependency.uuid)
        } else {
            for (let i = 0; i < productService.dependencies.length; i++) {

                if (productService.dependencies[i] === dependency.uuid) {
                    productService.dependencies.splice(i, 1);
                }
            }
        }
        this.setState({productServices: productServices});
    }

    renderDependencies() {

        let productServices = this.state.productServices;
        let cellDependenciesWidth = 70 / (productServices.length)
        return (
            <>
                <Col md={12}>
                    <Table bordered size="sm">
                        <thead>
                        <tr>
                            <th style={{
                                border: '1px solid #dee2e6',
                                textAlign: 'center',
                                width: (100 - (cellDependenciesWidth * productServices.length)) + "%"
                            }}
                            >
                                {window.gettext('Serviços')}
                            </th>
                            <th colSpan={productServices.length}
                                style={{
                                    border: '1px solid #dee2e6',
                                    textAlign: 'center',
                                    width: (cellDependenciesWidth * productServices.length) + "%"
                                }}>
                                {window.gettext('pre-requisitos')}
                            </th>
                        </tr>
                        <tr>
                            <th>&nbsp;</th>
                            {productServices.map(service => {
                                return (
                                    <>
                                        <th key={service.id} scope="row" style={{border: '1px solid #dee2e6'}}>
                                            {service.name || service.service.displayName}
                                        </th>
                                    </>
                                )
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {productServices.map(service => {
                            return (
                                <>
                                    <tr key={service.id}>
                                        <td>
                                            {service.name || service.service.displayName}
                                        </td>
                                        {productServices.map(serviceDependent => {
                                            return (
                                                <>
                                                    <td key={service.id + "." + serviceDependent.id} style={{
                                                        padding: '0px !important',
                                                        textAlign: 'center',
                                                        width: (cellDependenciesWidth) + "%"
                                                    }}>
                                                        {this.renderDependenceCheckBox(service, serviceDependent)}
                                                    </td>
                                                </>
                                            )
                                        })}
                                    </tr>
                                </>
                            )
                        })}
                        </tbody>
                    </Table>
                </Col>
            </>
        )
    }
}

export default withNotify(ProductForm);
